<template>
  <el-container class="screenHeight">
    <el-header class="level">
      <span class="level-left">
        <el-page-header
          class="level-item"
          :content="$t(`enum.template-progress.${activeTemplateProgress}`)"
          @back="$router.back()"
        />
      </span>
      <span class="level-right">
        <!--<el-button
          link
          class="level-item"
          @click="saveUpdated"
          v-loading.fullscreen.lock="fullscreenLoading"
          :disabled="!hasChanges"
        >
          <font-awesome-icon icon="save" />
        </el-button>-->
        <span class="level-item">
          {{ user?.firstname }}
          {{ user?.lastname }}
        </span>
      </span>
    </el-header>

    <el-container>
      <el-aside class="withFooterAndHeader" width="20rem" v-if="showTree">
        <el-scrollbar>
          <MeshTree
            v-model="meshTreeData"
            :addUrl="
              templateId
                ? `/template-upload?id=${templateId}`
                : `/template-upload`
            "
            @deleteItem="deleteItem"
          ></MeshTree>
        </el-scrollbar>
      </el-aside>
      <ToggleSidebar v-model="showTree" />
      <el-main v-loading="modelLoading">
        <MeshEditor
          ref="meshEditor"
          v-model="meshTreeData"
          :fitCameraToScene="true"
          :activateAlineToFloor="true"
          :canCurve="activeTemplateProgress === TemplateProgress.curves"
          :canDeform="activeTemplateProgress === TemplateProgress.modifier"
          :canTransform="activeTemplateProgress === TemplateProgress.setup"
          :canMirror="activeTemplateProgress === TemplateProgress.setup"
          :canCopy="activeTemplateProgress === TemplateProgress.setup"
          :canAline="activeTemplateProgress === TemplateProgress.setup"
          :canGroup="activeTemplateProgress === TemplateProgress.setup"
          :canColorize="false"
          :canEmbossing="activeTemplateProgress === TemplateProgress.embossing"
          :canRender="activeTemplateProgress === TemplateProgress.render"
          :canResolution="true"
          v-on:productQualityChanged="productQualityChanged"
        ></MeshEditor>
      </el-main>
    </el-container>
    <Workflow
      class="workflow"
      :workflowModel="workflowModel"
      :fullscreenLoading="fullscreenLoading"
    />
  </el-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import MeshTree from '@/components/three/MeshTree.vue';
import { MeshTreeData, MeshTreeDataItem } from '@/types/ui/MeshTreeData';
import OrientationGizmo from '@/components/three/OrientationGizmo.vue';
import MeshEditor from '@/components/three/MeshEditor.vue';
import { FileType3D, UploadCategory } from '@/types/enum/upload';
import { TemplateProgress } from '@/types/enum/workflow';
import * as LayoutUtility from '@/utils/layout';
import { Prop, Watch } from 'vue-property-decorator';
import * as TemplateService from '@/services/api/templateService';
import * as modelService from '@/services/api/modelService';
import * as THREE from 'three';
import Workflow from '@/components/workflow/Workflow.vue';
import { WorkflowModel } from '@/types/ui/WorkflowModel';
import ToggleSidebar from '@/components/element-plus/ToggleSidebar.vue';
import { DefaultUndoRedo } from '@/types/ui/HistoryList';
import { ProductQuality } from '@/types/enum/template';
import Auth from '@/services/api/auth';
import { User } from '@/types/api/User/User';

@Options({
  components: {
    MeshEditor,
    OrientationGizmo,
    MeshTree,
    Workflow,
    ToggleSidebar,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class TemplateWorkflow extends Vue implements DefaultUndoRedo {
  @Prop({ default: null }) templateId!: number | null;
  
  meshTreeData: MeshTreeData = new MeshTreeData(null, this);
  showTree = true;
  lastUpdateDB = -1;
  fullscreenLoading = false;
  meshEditor!: MeshEditor;
  modelLoading = false;
  
  selectedProductQuality = ProductQuality.low.toString();

  workflowModel: WorkflowModel = new WorkflowModel(
    'template-progress',
    TemplateProgress
  );
  TemplateProgress = TemplateProgress;

  authentication = Auth.getInstance();
  user: User | null = null;

  get activeTemplateProgress(): TemplateProgress {
    if (this.workflowModel.active < Object.keys(TemplateProgress).length) {
      LayoutUtility.refresh();
      return Object.values(TemplateProgress)[this.workflowModel.active];
    }
    return TemplateProgress.setup;
  }

  set activeTemplateProgress(value: TemplateProgress) {
    this.workflowModel.active = Object.values(TemplateProgress).indexOf(value);
  }

  unmounted(): void {
    (this.$router as any).askForChanges = false;
  }

  mounted(): void {
    this.authentication.handleAuthentication().then(() => {
      //get user information
      this.user = this.authentication.user;
    });
    
    if (this.templateId && window.location.href.endsWith('template-workflow')) {
      setTimeout(() => {
        this.$router.replace(`/template-workflow?id=${this.templateId}`);
      }, 200);
    }

    setTimeout(() => {
      if (this.$refs.meshEditor)
        this.meshEditor = this.$refs.meshEditor as MeshEditor;
    }, 100);

    this.loadFromDB();
  }

  loadFromDB(): void {
    if (this.templateId) {
      this.modelLoading = true;
      this.meshTreeData.clear();
      TemplateService.getMeshData(this.templateId, ProductQuality[this.selectedProductQuality]).then((importData) => {
        if (this.templateId) {
          if (importData && importData.length > 0) {
            const loadChildren = (
              parent: string | MeshTreeDataItem | null,
              children: modelService.MeshExportData[]
            ): void => {
              for (const data of children) {
                let treeData!: MeshTreeDataItem;
                if (data.fileType !== FileType3D.NONE) {
                  treeData = this.meshTreeData.addBase64Import(
                    data.uniqueKey,
                    data.componentId,
                    data.name,
                    data.fileType,
                    data.base64,
                    data.thumbnailType,
                    data.thumbnail,
                    parent
                      ? parent
                      : (this as any).$t(
                          `enum.upload-category.${data.category}`
                        ),
                    false,
                    data.color,
                    data.position,
                    data.rotation,
                    data.scale
                  );
                  treeData.bones = data.bones.map((boneInfo) => {
                    const bone = new THREE.Bone();
                    bone.name = boneInfo.name;
                    bone.position.copy(boneInfo.position);
                    return bone;
                  });
                } else {
                  treeData = this.meshTreeData.addGroup(
                    data.name,
                    [],
                    data.position,
                    data.rotation,
                    data.scale,
                    parent
                      ? parent
                      : (this as any).$t(
                          `enum.upload-category.${data.category}`
                        ),
                    false
                  );
                  treeData.id = data.uniqueKey;
                }
                loadChildren(
                  treeData,
                  importData.filter((item) => item.parentId === data.uniqueKey)
                );
              }
            };
            loadChildren(
              null,
              importData.filter((item) => item.parentId === null)
            );

            setTimeout(() => {
              if (this.meshEditor) {
                for (const data of importData) {
                  if (data.ffd) {
                    this.meshEditor.importFfdForMeshId(
                      data.uniqueKey,
                      data.ffd.spanCounts,
                      data.ffd.gridPositionList
                    );
                  }
                  if (data.embossing.length > 0) {
                    this.meshEditor.importEmbossingForMeshId(
                      data.uniqueKey,
                      data.embossing
                    );
                  }
                }
              }
            }, 500);
          }

          /*TemplateService.getCurveData(this.templateId).then((curveImport) => {
            setTimeout(() => {
              if (this.meshEditor) {
                this.meshEditor.importCurves(curveImport);
              }
            }, 500);
          });

          TemplateService.getCameraData(this.templateId).then(
            (cameraImport) => {
              setTimeout(() => {
                if (this.meshEditor) {
                  this.meshEditor.importCameras(cameraImport);
                }
              }, 500);
            }
          );*/
        }

        this.modelLoading = false;
      });
    }
  }

  updateFromDB(): void {
    const getTreeData = (
      treeItemList: MeshTreeDataItem[],
      componentId: number | null
    ): MeshTreeDataItem | undefined => {
      const result = treeItemList.find(
        (item) => item.componentId === componentId
      );
      if (result) return result;
      for (const item of treeItemList) {
        const itemResult = getTreeData(item.children, componentId);
        if (itemResult) return itemResult;
      }
      return undefined;
    };

    if (this.templateId) {
      TemplateService.getMeshData(this.templateId).then((importData) => {
        const selection = this.meshTreeData
          .getSelection()
          .map((selectedItem) => selectedItem.id);
        this.meshTreeData.selectItem(null);
        if (this.templateId) {
          if (importData && importData.length > 0) {
            const loadChildren = (
              parent: string | null,
              children: modelService.MeshExportData[]
            ): void => {
              for (const data of children) {
                let treeData: MeshTreeDataItem | undefined = undefined;
                if (data.fileType !== FileType3D.NONE) {
                  treeData = getTreeData(
                    this.meshTreeData.treeData,
                    data.componentId
                  );
                  //base64 assignment is not enough to update the geometry in editor
                  if (treeData && treeData.base64 !== data.base64) {
                    this.meshTreeData.removeItemTemp(treeData);
                    treeData = this.meshTreeData.addBase64Import(
                      data.uniqueKey,
                      data.componentId,
                      data.name,
                      data.fileType,
                      data.base64,
                      data.thumbnailType,
                      data.thumbnail,
                      parent
                        ? parent
                        : (this as any).$t(
                            `enum.upload-category.${data.category}`
                          ),
                      false,
                      data.color,
                      data.position,
                      data.rotation,
                      data.scale
                    );
                  } else if (treeData) {
                    treeData.base64 = data.base64;
                    treeData.position = data.position;
                    treeData.rotation = data.rotation;
                    treeData.scale = data.scale;
                    treeData.color = data.color;
                    this.meshTreeData.setParent(
                      treeData.uuid,
                      parent
                        ? parent
                        : (this as any).$t(
                            `enum.upload-category.${data.category}`
                          )
                    );
                  }
                } else {
                  treeData = this.meshTreeData.addGroup(
                    data.name,
                    [],
                    data.position,
                    data.rotation,
                    data.scale,
                    parent
                      ? parent
                      : (this as any).$t(
                          `enum.upload-category.${data.category}`
                        ),
                    false
                  );
                  treeData.id = data.uniqueKey;
                }
                if (treeData) {
                  loadChildren(
                    treeData.uuid,
                    importData.filter(
                      (item) => item.parentId === treeData?.uuid
                    )
                  );
                }
              }
            };
            loadChildren(
              null,
              importData.filter((item) => item.parentId === null)
            );
            this.meshTreeData.updateStructure();
          }
        }
        if (selection.length > 0 && selection[0]) {
          const selectedItem = this.meshTreeData.getItemById(selection[0]);
          this.meshTreeData.selectItem(selectedItem);
        }
      });
    }
  }

  //#region save
  private async updateTreeItem(
    categoryType: UploadCategory,
    item: MeshTreeDataItem,
    parent: MeshTreeDataItem,
    lastUpdateDB = -1
  ): Promise<void> {
    if (this.templateId) {
      const awaitTimeout = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));
      if (item.lastUpdateDB >= lastUpdateDB) {
        let maxTryCount = 0;
        while (!item.mesh && maxTryCount < 10) {
          maxTryCount++;
          await awaitTimeout(100);
        }
        if (item.mesh) {
          if (item.id) {
            TemplateService.updateMeshData(
              this.templateId,
              item.id,
              categoryType,
              item.name,
              item.mesh,
              item.bones,
              parent.mesh ? parent.componentId : null
            ).then((id) => {
              item.id = id;
            });
          } else {
            TemplateService.addMeshData(
              this.templateId,
              categoryType,
              item.name,
              item.mesh,
              item.bones,
              parent.mesh ? parent.componentId : null
            ).then((result) => {
              item.id = result.id;
            });
          }
          this.updateChildren(categoryType, item, lastUpdateDB);
        }
      }
      if (item.id && this.meshEditor) {
        const ffd = this.meshEditor.exportFfdForMeshId(item.id);
        if (ffd) {
          TemplateService.updateFfdData(this.templateId, item.id, ffd);
        }
        const embossingList = this.meshEditor.exportEmbossingForMeshId(item.id);
        TemplateService.updateEmbossingData(
          this.templateId,
          item.id,
          embossingList
        );
      }
    }
  }

  private updateChildren(
    categoryType: UploadCategory,
    parent: MeshTreeDataItem,
    lastUpdateDB = -1
  ): void {
    for (const child of parent.children) {
      this.updateTreeItem(categoryType, child, parent, lastUpdateDB);
    }
  }

  private async saveAll(): Promise<void> {
    await this.save();
  }

  private async saveUpdated(): Promise<void> {
    await this.save(this.lastUpdateDB);
  }

  private async save(lastUpdateDB = -1): Promise<void> {
    if (this.templateId) {
      const awaitTimeout = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));
      this.fullscreenLoading = true;
      await awaitTimeout(100);
      this.lastUpdateDB = Date.now();
      for (const category of this.meshTreeData.treeData) {
        const categoryType = Object.values(UploadCategory).find(
          (categoryType) =>
            category.name ===
            (this as any).$t(`enum.upload-category.${categoryType}`)
        );
        if (categoryType) {
          this.updateChildren(categoryType, category, lastUpdateDB);
        }
      }
      if (this.meshEditor) {
        const curves = this.meshEditor.exportCurves();
        if (curves) {
          TemplateService.updateCurveData(this.templateId, curves);
        }
        const cameras = this.meshEditor.exportCameras();
        if (cameras) {
          TemplateService.updateCameraData(this.templateId, cameras);
        }
      }
      this.fullscreenLoading = false;
      (this.$router as any).askForChanges = this.hasChanges;
    }
  }

  get hasChanges(): boolean {
    return this.lastUpdateDB < this.meshTreeData.lastUpdateDB;
  }

  @Watch('meshTreeData.lastUpdateDB', { immediate: false })
  async onMeshTreeDataDBChanged(): Promise<void> {
    (this.$router as any).askForChanges = this.hasChanges;
  }
  //#endregion save

  //#region watch
  @Watch('showTree', { immediate: true })
  onShowTreeChanged(): void {
    LayoutUtility.refresh();
  }

  @Watch('workflowModel.active', { immediate: true })
  onActiveStepChanged(): void {
    if (this.workflowModel.navigationStart && this.hasChanges)
      this.saveUpdated();
  }

  @Watch('workflowModel.finished', { immediate: true })
  onStepFinished(): void {
    if (this.workflowModel.finished) {
      if (this.hasChanges) {
        this.saveUpdated().then(() => {
          this.$router.back();
        });
      } else {
        this.$router.back();
      }
    }
  }

  @Watch('templateId', { immediate: true })
  onTemplateIdChanged(): void {
    this.meshTreeData.modelId = this.templateId;
    this.meshTreeData.historyList.modelId = this.templateId;
    this.meshTreeData.historyList.defaultUndoRedo = this;
  }

  //#endregion watch

  deleteItem(item: MeshTreeDataItem): void {
    if (this.templateId && item.id)
      TemplateService.deleteMeshData(this.templateId, item.id);
  }

  async defaultUndo(): Promise<void> {
    if (this.templateId) {
      await modelService.undo(this.templateId, 1).then(() => {
        //todo: geometry is updated in the backend with a delay. 500 milliseconds are not enough
        setTimeout(() => {
          this.updateFromDB();
        }, 500);
      });
    }
  }

  async defaultRedo(): Promise<void> {
    if (this.templateId) {
      await modelService.redo(this.templateId, 1).then(() => {
        //todo: geometry is updated in the backend with a delay. 500 milliseconds are not enough
        setTimeout(() => {
          this.updateFromDB();
        }, 500);
      });
    }
  }

  productQualityChanged(selectedProductQuality: string): void {
    this.selectedProductQuality = selectedProductQuality;
    this.loadFromDB();
  }
}
</script>

<style lang="scss" scoped>
.minimize {
  width: 0;
}

.el-button.is-link {
  color: white;
}

.el-button.is-link:hover,
.el-button.is-link:focus {
  color: white;
}

.level:not(:last-child) {
  margin-bottom: unset;
}

.level, .workflow, .el-aside {
  z-index: 1;
}
</style>
