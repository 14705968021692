<script lang="ts">
import PreviewRendererImg from "@/components/three/PreviewRendererImg.vue";
import { Options, Vue } from "vue-class-component";
import { OrderRequestData } from "@/services/api/orderService";
import { Prop } from "vue-property-decorator";
import { WorkflowModel } from "@/types/ui/WorkflowModel";
import PreviewRenderer from "@/components/three/PreviewRenderer.vue";
import { MeshTreeData, MeshTreeDataGroupItem, MeshTreeDataItem } from "@/types/ui/MeshTreeData";
import { Product } from "@/types/api/Model/Product";
import ProductParameterBalcony from "./productParameters/ProductParameterBalcony.vue";
import ProductParameterCover from "./productParameters/ProductParameterCover.vue";
import ProductParameterCuboid from "./productParameters/ProductParameterCuboid.vue";
import ProductParameterFence from "./productParameters/ProductParameterFence.vue";
import ProductParameterOrthesis from "./productParameters/ProductParameterOrthesis.vue";
import ProductParameterLinearLight from "@/components/productParameters/ProductParameterLinearLight.vue";
import Auth from "@/services/api/auth";
import { Role } from "@/types/enum/user";
import * as modelService from '@/services/api/modelService';
import { OrientationType } from "@/types/enum/order";
import { LinearLightNameType } from "@/types/enum/order";

@Options({
  components: {
    PreviewRendererImg,
    PreviewRenderer,
    ProductParameterBalcony,
    ProductParameterCover,
    ProductParameterCuboid,
    ProductParameterFence,
    ProductParameterOrthesis,
    ProductParameterLinearLight
  }
})

export default class OrderSummary extends Vue {
  @Prop() order!: OrderRequestData;
  @Prop() workflowModel!: WorkflowModel;
  @Prop() meshTreeData!: MeshTreeData;
  @Prop({ default: [] }) treeData!: MeshTreeDataItem[];
  @Prop({ default: [] }) colorList!: string[];
  @Prop({ default: [] }) embossingList!: string[];
  @Prop({ default: [] }) embossingColorList!: string[];
  @Prop() product!: Product;

  RegionalEnergyYield = 950;
  EuroPerKWh = 0.40;

  authentication = Auth.getInstance();
  Role = Role;
  savingsPerYear = 0.0;
  amortisation = 0;
  calculatingAmortisation = false;

  LinearLightNameType = LinearLightNameType;

  meshGroupedPartItems: {[key: string]: MeshTreeDataGroupItem[]} = {};

  mounted(): void {
    this.meshTreeData.getGroupedPartItems(false).then((list) => {
      this.meshGroupedPartItems = list;
    })
    this.calculateAmortisation();
  }

  async calculateAmortisation() {
    this.calculatingAmortisation = true;
    let wp = 0.0;
    for(const treeData of this.meshTreeData.treeData.filter(m => m.componentId != null)) {
      const power = await modelService.getPower(treeData.componentId!);
      if (power) {
        wp += power.value;
      }
    }
    const kWp = wp / 1000;

    let personalEnergyYield = this.RegionalEnergyYield;
    switch(this.order.orientationType) {
      case OrientationType.south: {
        personalEnergyYield *= 1; // 100 %
        break;
      }
      case OrientationType.eastOrWest: {
        personalEnergyYield *= 0.8; // 80%
        break;
      }
    }

    const kWh = kWp * personalEnergyYield;

    this.savingsPerYear = kWh * this.EuroPerKWh;
    this.amortisation = this.workflowModel.totalPrice / this.savingsPerYear;
    this.calculatingAmortisation = false;
  }
}

</script>

<template>
  <div id="order-summary">
    <h2>{{ $t('components.order-summary.title') }}</h2>

    <div v-if="!authentication.domains.some(d => d.urlSuffix == 'wjtortho')">

      <!-- Product data -->
      <h3 class="mt-3">{{ $t('components.order-summary.productDataTitle') }}</h3>
      <div class="row1">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.product')}}</label>
        <label class="col form-label mb-1">{{ order?.coverModel }}</label>
      </div>
      <div class="row1" v-if="colorList.length > 0 && !product.name.toLowerCase().includes('s-cover')">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.baseColor')}}</label>
        <label class="col form-label mb-1">{{ colorList.join(', ') }}</label>
      </div>
      <div class="row1" v-if="embossingList.length > 0">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.embossing')}}</label>
        <label class="col form-label mb-1">{{ embossingList.join(', ') }}</label>
      </div>
      <div class="row1" v-if="embossingColorList.length > 0">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.embossingColor')}}</label>
        <label class="col form-label mb-1">{{ embossingColorList.join(', ') }}</label>
      </div>

      <!-- Contact data -->
      <h3 class="mt-3">{{ $t('components.order-summary.contactDataTitle') }}</h3>
      <div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.contactPerson')}}</label>
          <label class="col form-label mb-1">{{ order?.technicianFirstname + " " + order?.technicianLastname }}</label>
        </div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.company') }}</label>
          <label class="col form-label mb-1">{{ order?.company }}</label>
        </div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.commissionNo') }}</label>
          <label class="col form-label mb-1">{{ order?.commissionNo }}</label>
        </div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.mail') }}</label>
          <label class="col form-label mb-1">{{ order?.mail }}</label>
        </div>
      </div>

      <!-- Parameter -->
      <product-parameter-cover
        v-if="product.type.name.toLowerCase() == 'covers'"
        :properties="[order]"
        :product="product"
        :readonly="true"
      />
      <product-parameter-orthesis
        v-if="product.type.name.toLowerCase() == 'orthesen'"
        :properties="[order]"
        :product="product"
        :readonly="true"
      />
      <product-parameter-cuboid
        v-if="product.type.name.toLowerCase() == 'möbel' ||
              product.type.name.toLowerCase() == 'autos' ||
              product.type.name.toLowerCase() == 'gebäude'"
        :properties="[order]"
        :readonly="true"
      />
      <product-parameter-balcony
        v-if="product.type.name.toLowerCase() == 'balkone'"
        :properties="[order]"
        :readonly="true"
      />
      <product-parameter-fence
        v-if="product.type.name.toLowerCase() == 'zäune'"
        :properties="[order]"
        :readonly="true"
      />
      <product-parameter-linear-light
        v-if="product.type.name.toLowerCase() == 'lineare beleuchtung'"
        :properties="[order]"
        :isHangingLight="product.name.toLowerCase() === LinearLightNameType.hanging.toLowerCase()"
        :isSurfaceMountedLight="product.name.toLowerCase() === LinearLightNameType.surfaceMounted.toLowerCase()"
        :readonly="true"
      />

      <!-- Part list -->
      <div v-if="(authentication?.roles?.includes(Role.Supplier) || authentication?.roles?.includes(Role.Reseller)) && !product?.name.toLowerCase().includes('cover')">
          <h3 class="mt-3">{{ $t('components.order-summary.parts') }}</h3>
          <div class="row1" v-for="part in Object.entries(meshGroupedPartItems)" :key="part[0]">
            <label class="col form-label mb-1">{{ $t('components.part-selection.' + product.type.name.toLowerCase() + '.' + part[0]) ? part[1].reduce((sum, current) => sum + current.cnt, 0) + 'x ' + $t('components.part-selection.' + product.type.name.toLowerCase() + '.' + part[0]) : part[1].length + 'x ' + part[0] }}</label>
            <div v-for="item in part[1]" :key="item.meshTreeDataItem.name">
              <label v-if="item.meshTreeDataItem.size?.width" class="col form-label text-secondary mb-1">{{ item.cnt + 'x ' + Math.ceil(item.meshTreeDataItem.size?.width) + ' mm'}}</label>
              <label v-if="item.meshTreeDataItem.size?.height" class="col form-label text-secondary mb-1">{{ Math.ceil(item.meshTreeDataItem.size?.height) + ' mm'}}</label>
              <label v-if="item.meshTreeDataItem.size?.depth" class="col form-label text-secondary mb-1">{{ Math.ceil(item.meshTreeDataItem.size?.depth) + ' mm'}}</label>
            </div>
          </div>
      </div>

      <!-- Remark -->
      <h3 class="mt-3">{{ $t('components.order-form.remarks') }}</h3>
      <div class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.remarks') }}</label>
            <label class="col form-label mb-1">{{ order?.remarks }}</label>
          </div>
        </div>
      </div>

      <!-- Price -->
      <div v-if="authentication.user !== null || !this.product.name?.toLocaleLowerCase().includes('cover')">
        <h3 class="mt-3">{{ $t('components.order-summary.priceTitle') }}</h3>
        <div class="row border-0">
          <div class="col mb-2">
            <div class="row1">
              <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.productPrice') }}</label>
              <label class="col form-label mb-1">{{ workflowModel.totalPrice.toFixed(2) }} €</label>
            </div>
            <!-- <div class="row1">
              <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.ust') }}</label>
              <label class="col form-label mb-1">{{ (workflowModel.totalPrice * 0.2).toFixed(2) }} €</label>
            </div>
            <div class="row1">
              <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.totalPrice') }}</label>
              <label class="col form-label mb-1">{{ (workflowModel.totalPrice * 1.2).toFixed(2) }} €</label>
            </div> -->
          </div>
        </div>
      </div>
      <div v-else class="mt-4">
        <h2 class="text-center">Um Preise einzusehen und Bestellungen aufzugeben ist eine Registrierung notwendig!</h2>
      </div>


      <!-- Delivery -->
      <div v-if="product.type.name.toLowerCase() == 'balkone'" class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.balcony.price-information') }}</label>
          </div>
        </div>
      </div>
      <!-- <div v-else class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.shippingCosts') }}: {{ $t('components.order-summary.shippingCostsInfo') }}</label>
          </div>
        </div>
      </div> -->

      <!-- Amortisation -->
      <h3 v-if="product.type.name.toLowerCase() == 'balkone'" class="mt-3">{{ $t('components.order-summary.amortisation') }}</h3>
      <div v-loading="calculatingAmortisation" v-if="product.type.name.toLowerCase() == 'balkone'" class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.product-parameter.balcony.orientation-type') }}</label>
            <label class="col form-label mb-1">{{ $t(`enum.orientationType.${order?.orientationType}`) }}</label>
          </div>
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.savings-per-year') }}</label>
            <label class="col form-label mb-1">{{ (savingsPerYear).toFixed(2) }} €</label>
          </div>
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.amortisation') }}</label>
            <label class="col form-label mb-1">{{ amortisation <= 30 ? (amortisation).toFixed(0) : '+30' }} {{ $t('components.order-summary.years') }}</label>
          </div>
        </div>
      </div>
    </div>

    <!--Teufel-Ansicht-->

    <div v-if="authentication.domains.some(d => d.urlSuffix == 'wjtortho')">

      <!-- Product data -->
      <h3 class="mt-3">{{ $t('components.order-summary.productDataTitle') }}</h3>
      <div class="row1">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.product')}}</label>
        <label class="col form-label mb-1">{{ order?.coverModel }}</label>
      </div>
      <div class="row1" v-if="colorList.length > 0 && !product.name.toLowerCase().includes('s-cover')">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.baseColor')}}</label>
        <label class="col form-label mb-1">{{ colorList.join(', ') }}</label>
      </div>
      <div class="row1" v-if="embossingList.length > 0">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.embossing')}}</label>
        <label class="col form-label mb-1">{{ embossingList.join(', ') }}</label>
      </div>
      <div class="row1" v-if="embossingColorList.length > 0">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.embossingColor')}}</label>
        <label class="col form-label mb-1">{{ embossingColorList.join(', ') }}</label>
      </div>

      <!-- Contact data -->
      <h3 class="mt-3">{{ $t('components.order-summary.contactDataTitle') }}</h3>
      <div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.company') }}</label>
          <label class="col form-label mb-1">{{ order?.company }}</label>
        </div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.customerNr') }}</label>
          <label class="col form-label mb-1">{{ order?.customerNr }}</label>
        </div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.orderNr') }}</label>
          <label class="col form-label mb-1">{{ order?.orderNr }}</label>
        </div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.billingNr') }}</label>
          <label class="col form-label mb-1">{{ order?.billingNr }}</label>
        </div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.contactPerson')}}</label>
          <label class="col form-label mb-1">{{ order?.technicianFirstname + " " + order?.technicianLastname }}</label>
        </div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.mail') }}</label>
          <label class="col form-label mb-1">{{ order?.mail }}</label>
        </div>
      </div>

      <!-- Parameter -->
      <product-parameter-cover
        v-if="product.type.name.toLowerCase() == 'covers'"
        :properties="[order]"
        :product="product"
        :readonly="true"
      />

      <!-- Part list -->
      <div v-if="(authentication?.roles?.includes(Role.Supplier) || authentication?.roles?.includes(Role.Reseller)) && !product?.name.toLowerCase().includes('cover')">
          <h3 class="mt-3">{{ $t('components.order-summary.parts') }}</h3>
          <div class="row1" v-for="part in Object.entries(meshGroupedPartItems)" :key="part[0]">
            <label class="col form-label mb-1">{{ $t('components.part-selection.' + product.type.name.toLowerCase() + '.' + part[0]) ? part[1].reduce((sum, current) => sum + current.cnt, 0) + 'x ' + $t('components.part-selection.' + product.type.name.toLowerCase() + '.' + part[0]) : part[1].length + 'x ' + part[0] }}</label>
            <div v-for="item in part[1]" :key="item.meshTreeDataItem.name">
              <label v-if="item.meshTreeDataItem.size?.width" class="col form-label text-secondary mb-1">{{ item.cnt + 'x ' + Math.ceil(item.meshTreeDataItem.size?.width) + ' mm'}}</label>
              <label v-if="item.meshTreeDataItem.size?.height" class="col form-label text-secondary mb-1">{{ Math.ceil(item.meshTreeDataItem.size?.height) + ' mm'}}</label>
              <label v-if="item.meshTreeDataItem.size?.depth" class="col form-label text-secondary mb-1">{{ Math.ceil(item.meshTreeDataItem.size?.depth) + ' mm'}}</label>
            </div>
          </div>
      </div>

      <!-- Remark -->
      <h3 class="mt-3">{{ $t('components.order-form.remarks') }}</h3>
      <div class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.remarks') }}</label>
            <label class="col form-label mb-1">{{ order?.remarks }}</label>
          </div>
        </div>
      </div>

      <!-- Price -->
      <div v-if="authentication.user !== null || !this.product.name?.toLocaleLowerCase().includes('cover')">
        <h3 class="mt-3">{{ $t('components.order-summary.priceTitle') }}</h3>
        <div class="row border-0">
          <div class="col mb-2">
            <div class="row1">
              <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.productPrice') }}</label>
              <label class="col form-label mb-1">{{ workflowModel.totalPrice.toFixed(2) }} €</label>
            </div>
            <!-- <div class="row1">
              <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.ust') }}</label>
              <label class="col form-label mb-1">{{ (workflowModel.totalPrice * 0.2).toFixed(2) }} €</label>
            </div>
            <div class="row1">
              <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.totalPrice') }}</label>
              <label class="col form-label mb-1">{{ (workflowModel.totalPrice * 1.2).toFixed(2) }} €</label>
            </div> -->
          </div>
        </div>
      </div>
      <div v-else class="mt-4">
        <h2 class="text-center">Um Preise einzusehen und Bestellungen aufzugeben ist eine Registrierung notwendig!</h2>
      </div>


      <!-- Delivery -->
      <div v-if="product.type.name.toLowerCase() == 'balkone'" class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.balcony.price-information') }}</label>
          </div>
        </div>
      </div>
      <!-- <div v-else class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.shippingCosts') }}: {{ $t('components.order-summary.shippingCostsInfo') }}</label>
          </div>
        </div>
      </div> -->

      <!-- Amortisation -->
      <h3 v-if="product.type.name.toLowerCase() == 'balkone'" class="mt-3">{{ $t('components.order-summary.amortisation') }}</h3>
      <div v-loading="calculatingAmortisation" v-if="product.type.name.toLowerCase() == 'balkone'" class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.product-parameter.balcony.orientation-type') }}</label>
            <label class="col form-label mb-1">{{ $t(`enum.orientationType.${order?.orientationType}`) }}</label>
          </div>
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.savings-per-year') }}</label>
            <label class="col form-label mb-1">{{ (savingsPerYear).toFixed(2) }} €</label>
          </div>
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.amortisation') }}</label>
            <label class="col form-label mb-1">{{ amortisation <= 30 ? (amortisation).toFixed(0) : '+30' }} {{ $t('components.order-summary.years') }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.h2, .h3,
.text-secondary {
  color: #0000008D !important;
}
.form-label {
  width: 20rem;
}
.row {
  border-bottom: 0.1rem solid #0000008D;
}
.row1 {
  border-bottom: 0.1rem solid #6767678d;
}
</style>
