<script setup lang="ts">
import {File as UtilityFile} from "@/types/api/Utility/File";
import ButtonCard from "@/components/ButtonCard.vue";
import { SupportedValues } from "@/types/api/Utility/SupportedValues";

interface Props {
  engravingList: SupportedValues[];
  textureList: SupportedValues[];
  selectedEngraving: number | null;
  selectedTexture: number | null;
}

const props = defineProps<Props>();
</script>

<template>
  <div id="design-selection" class="pl-5 pr-5 pt-3 pb-3 rounded-3 text-white">
    <h1>{{ $t('components.design-selection.title') }}</h1>
    <p class="form-text text-white">{{ $t('components.design-selection.info') }}</p>
    <button type="button" class="btn btn-primary mt-2">{{ $t('components.design-selection.uploadButton') }}</button>
    <div class="d-flex flex-wrap gap-3 mt-5">
      <p v-if="props.engravingList.length == 0 && props.textureList.length == 0">{{ $t('views.customize.workflow.noEmbossing') }}</p>
      <div v-else class="d-flex flex-wrap gap-3 mt-5">
        <button-card v-for="item in props.engravingList" :key="item.thumbnail?.fileId"
                     :title="$t(`views.designs.${item.thumbnail?.name}`) ?? item.thumbnail?.name"
                     :selected="props.selectedEngraving != undefined && props.selectedEngraving === item.fileId"
                     @click="$emit('engraving-selected', item.fileTextureId)">
          <img :src="item.thumbnail?.content" class="img" :alt="item.thumbnail?.name">
        </button-card>
        <button-card v-for="item in props.textureList" :key="item.thumbnail?.fileId"
                     :title="$t(`views.designs.${item.thumbnail?.name}`) ?? item.thumbnail?.name"
                     :selected="props.selectedTexture != undefined && props.selectedTexture === item.fileId"
                     @click="$emit('texture-selected', item.fileTextureId)">
          <img :src="item.thumbnail?.content" class="img" :alt="item.thumbnail?.name">
        </button-card>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
#design-selection {
  background: transparent radial-gradient(closest-side at 50% 50%, rgba(75,75,75,0.6) 0%, rgba(0,0,0,0.6) 100%) 0% 0% no-repeat padding-box;
}
.img {
  height: 9rem;
  width: 9rem;
}
</style>
