export enum UploadProgress {
  Select = 'select',
  Orientation = 'orientation',
  Pivot = 'pivot',
}

export enum FileCategory {
  THREE = 'three',
  IMAGE = 'image',
}

export enum FileType3D {
  NONE = 'none',
  FBX = 'fbx',
  STL = 'stl',
  OBJ = 'obj',
}

export enum FileTypeImage {
  NONE = 'none',
  PNG = 'png',
  JPG = 'jpg',
}

export const getFileType = (
  filename: string,
  category = FileCategory.THREE
): FileType3D => {
  const fileNameParts = filename.split('.');
  if (fileNameParts.length > 1) {
    if (
      category === FileCategory.THREE &&
      Object.values(FileType3D).includes(fileNameParts[1] as FileType3D)
    ) {
      const fileType = fileNameParts[1];
      return FileType3D[fileType.toUpperCase()];
    }
  }
  return FileType3D.NONE;
};

export const getImageFileType = (
  filename: string,
  category = FileCategory.IMAGE
): FileTypeImage => {
  const fileNameParts = filename.split('.');
  if (fileNameParts.length > 1) {
    if (
      category === FileCategory.IMAGE &&
      Object.values(FileTypeImage).includes(fileNameParts[1] as FileTypeImage)
    ) {
      const fileType = fileNameParts[1];
      return FileTypeImage[fileType.toUpperCase()];
    }
  }
  return FileTypeImage.NONE;
};

export const isValidFileType = (
  filename: string,
  category = FileCategory.THREE
): boolean => {
  const fileType = getFileType(filename, category);
  return fileType !== FileType3D.NONE;
};

export const isValidImageFileType = (
  filename: string,
  category = FileCategory.IMAGE
): boolean => {
  const fileType = getImageFileType(filename, category);
  return fileType !== FileTypeImage.NONE;
};

export enum UploadCategory {
  Product = 'product',
  Scan = 'scan',
  Curve = 'curve',
  ReferenceDummy = 'referenceDummy',
}
