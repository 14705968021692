<template>
  <el-container class="screenHeight">
    <el-header class="level">
      <span class="level-left">
        <el-page-header
          class="level-item"
          :content="$t('components.menu-sidebar.configuration.templates')"
          @back="$router.back()"
        />
      </span>
      <span class="level-right">
        <span class="level-item">
          {{ user?.firstname }}
          {{ user?.lastname }}
        </span>
      </span>
    </el-header>
    <el-container>
      <el-aside class="withFooterAndHeader" width="20rem" v-if="showMenu">
        <el-scrollbar>
          <side-menu active-menu-item="2-2"> </side-menu>
        </el-scrollbar>
      </el-aside>
      <ToggleSidebar v-model="showMenu" />
      <el-main v-loading="modelsLoading">
        <div class="media">
          <div class="media-content">
            <el-input
              v-model="searchText"
              :placeholder="$t('views.template.search')"
            />
          </div>
          <div class="media-right">
            <el-button type="primary" @click="createNew">
              {{ $t('views.template.create') }}
            </el-button>
          </div>
        </div>
        <el-space wrap style="align-items: flex-start;" class="productOverview">
          <div
            v-for="model in filterList"
            :key="model.modelId"
            class="products"
            v-on:click="displayModel(model.modelId, $event)"
          >
            <PreviewRendererImg
              :meshes="[
                {
                  thumbnail: model.product.name,
                  thumbnailUrl: convertToUrl(model),
                },
              ]"      
            />
            <h2 class="media" v-if="model.product">
              <span class="media-content">
                {{ model.product.name }}
              </span>
              <span class="media-right">
                <span id="deleteModel" @click="deleteModel(model.modelId)">
                  <font-awesome-icon icon="trash" />
                </span>
              </span>
            </h2>
          </div>
        </el-space>
      </el-main>
    </el-container>
  </el-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import * as modelService from '@/services/api/modelService';
import { Model, Model2 } from '@/types/api/Model/Model';
import { FileTypeImage } from '@/types/enum/upload';
import PreviewRenderer from '@/components/three/PreviewRenderer.vue';
import PreviewRendererImg from '@/components/three/PreviewRendererImg.vue';
import SideMenu from '@/components/workflow/SideMenu.vue';
import ToggleSidebar from '@/components/element-plus/ToggleSidebar.vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import Auth from '@/services/api/auth';
import { User } from '@/types/api/User/User';

@Options({
  components: {
    SideMenu,
    ToggleSidebar,
    PreviewRenderer,
    PreviewRendererImg
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class TemplateOverview extends Vue {
  templateList: Model2[] = [];
  FileTypeImage = FileTypeImage;
  showMenu = true;
  searchText = '';
  modelsLoading = false;
  authentication = Auth.getInstance();
  user: User | null = null;

  mounted(): void {
    this.authentication.handleAuthentication().then(() => {
      //get user information
      this.user = this.authentication.user;
      if (this.user) {
        this.modelsLoading = true;
        modelService.getModelsByUserId(this.user.userId, null).then((models) => {
          if (models) {
            const templateList = models.filter((item) => !item.basedOn);
            this.templateList = templateList;
          } else this.templateList = [];
          this.modelsLoading = false;
        });
      }
    });

    /*this.modelsLoading = true;
    modelService.getModelOverviewList().then(async (result) => {
      if (result) {
        const templateList = result.filter((item) => !item.basedOn);
        this.templateList = templateList;
      } else this.templateList = [];
      this.modelsLoading = false;
    });*/
  }

  get filterList(): Model2[] {
    if (this.searchText.length > 0)
      return this.templateList.filter(
        (item) =>
          item.product &&
          item.product.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    return this.templateList;
  }

  convertToUrl(model: Model): string | null {
    if (
      model.product &&
      model.product.thumbnail
    ) {
      return model.product.thumbnail.content;
    }
    return null;
  }

  deleteModel(modelId: number): void {
    ElMessageBox.confirm(
      (this as any).$t('confirm.delete.message'),
      (this as any).$t('confirm.delete.title'),
      {
        confirmButtonText: (this as any).$t('confirm.delete.ok'),
        cancelButtonText: (this as any).$t('confirm.delete.cancel'),
        type: 'warning',
      }
    ).then(() => {
      modelService.deleteModel(modelId).then((result) => {
        if (!result) {
          ElMessage.error((this as any).$t('error.api.delete'));
        } else {
          ElMessage.success((this as any).$t('success.api.delete'));
        }
      });
      const index = this.templateList.findIndex(
        (item) => item.modelId === modelId
      );
      if (index > -1) {
        this.templateList.splice(index, 1);
      }
    });
  }

  displayModel(modelId: number, event: PointerEvent): void {
    const path = event.composedPath();
    if (!path.find((item) => (item as any).id === 'deleteModel'))
      this.$router.push(`/template-workflow?id=${modelId}`);
  }

  createNew(): void {
    this.$router.push(`/template-workflow`);
  }
}
</script>

<style lang="scss" scoped>
.el-main {
  padding: 1rem;

  > .media {
    padding-bottom: 1rem;
  }
}

.level:not(:last-child) {
  margin-bottom: unset;
}

.el-container .el-container {
  max-height: calc(100vh - var(--el-header-height));
}

.el-card {
  width: 186px;
}

.products {
  border-radius: 6px;

  h2 {
    color: white;
    background-color: var(--color-primary);
    border-radius: 0px 0px 6px 6px;
    padding: 0.4rem 0.6rem;
  }
}

.productOverview {
  display: grid;
  grid-template-columns: repeat(auto-fit, 150px);
  grid-auto-rows: 1fr;
  justify-content: center;
  gap: 1rem 1rem;
}
</style>
