<template>
  <div :data="modelValue">
    <div class="webGlView">
      <div v-loading="loadingCircle" element-loading-background="transparent" style="z-index: 1;">
        <el-main class="fillHorizontal" id="main">
          <OrientationGizmo v-show="false" ref="orientationGizmo"></OrientationGizmo>
          <Renderer
              v-if="isActive"
              ref="troisRenderer"
              antialias
              :orbit-ctrl="{
                enableDamping: false,
                dampingFactor: 0.05,
                enablePan: true,
              }"
              :resize="true"
              :alpha="true"
              :xr="true"
              :preserveDrawingBuffer="true"
          >
            <Camera :position="renderCameraPos" />
            <Scene>
              <AmbientLight color="#808080" />
              <PointLight :position="pointLightPos" :intensity="0.55" />
            </Scene>
            <EffectComposer>
              <RenderPass />
            </EffectComposer>
          </Renderer>
        </el-main>
      </div>
      <ToggleSidebar
          v-if="canEmbossing"
          v-model="showSidebar"
          :useLeftSidebar="false"
      />
      <EmbossingSidebar
          v-if="canEmbossing && showSidebar"
          v-model="selectedEmbossing"
          :selectObject="selectObject"
          :selectionList="selectionList"
          :historyList="modelValue.historyList"
      />
    </div>
    <div class="hierarchy" v-if="canPartSelect">
      <el-scrollbar>
        <el-space>
          <PreviewRendererImg
            :meshes="modelValue.treeData"
            imgSize="5"
            :offset="1.5"
            :canRemove="true"
            :canSelect="true"
            :isSelected="true"
            :canTakeSnapshot="canTakeSnapshot"
            v-on:selectItem="selectPart"
            v-on:takeSnapshot="takeSnapshot"
          />
        </el-space>
      </el-scrollbar>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { RendererPublicInterface } from 'troisjs';
import * as THREE from 'three';
import MeshTree from '@/components/three/MeshTree.vue';
import { MeshTreeData, MeshTreeDataItem } from '@/types/ui/MeshTreeData';
import * as THREEEnum from '@/types/enum/three';
import OrientationGizmo from '@/components/three/OrientationGizmo.vue';
import { Prop, Watch, Inject } from 'vue-property-decorator';
import * as THREETransform from '@/utils/three/transform';
import * as THREEMaterial from '@/utils/three/material';
import * as THREEBoundingBox from '@/utils/three/boundingBox';
import * as THREEInit from '@/utils/three/init';
import * as THREEBone from '@/utils/three/bone';
import { EditorModeState, ProjectionCategory } from '@/types/enum/editor';
import { LayoutColor } from '@/types/enum/color';
import { ProductColors } from '@/services/api/productDbService';
import { HistoryOperationType } from '@/types/ui/HistoryList';
import { ViewCtrl } from '@/types/ui/ViewCtrl';
import { EditorMode } from '@/types/ui/EditorMode';
import { SelectionList } from '@/types/ui/SelectionList';
import TransformTools from '@/components/Tools/TransformTools.vue';
import BoneTools from '@/components/Tools/BoneTools.vue';
import * as LayoutUtility from '@/utils/layout';
import CurveTools from '@/components/Tools/CurveTools.vue';
import DeformationTools from '@/components/Tools/DeformationTools.vue';
import { v4 as uuidv4 } from 'uuid';
import {
  addWebGlContext,
  deleteWebGlContext,
  waitFor,
} from '@/utils/three/webGlContext';
import ProjectionTools from '@/components/Tools/ProjectionTools.vue';
import PreviewRendererImg from '@/components/three/PreviewRendererImg.vue';
import ToggleSidebar from '@/components/element-plus/ToggleSidebar.vue';
import EmbossingSidebar, {
  EmbossingData,
} from '@/components/Tools/EmbossingSidebar.vue';
import RenderTools from '@/components/Tools/RenderTools.vue';
import {
  EmbossingExportData,
  FFDExportData,
} from '@/services/api/templateService';
import { CurveCategory } from '@/types/api/Model/Curve/CurveCategory';
import { CameraExportData, CurveExportData } from '@/services/api/modelService';
import ProductQuality from './ProductQuality.vue';
import { ARButton } from 'three/examples/jsm/webxr/ARButton';
import { handleXRHitTest, resetHitTestSource } from '@/utils/three/hitTest';
import { createPlaneMarker } from '@/utils/three/planeMarker';

@Options({
  components: {
    RenderTools,
    EmbossingSidebar,
    ToggleSidebar,
    ProjectionTools,
    DeformationTools,
    CurveTools,
    BoneTools,
    TransformTools,
    OrientationGizmo,
    MeshTree,
    PreviewRendererImg,
    ProductQuality
  },
  emits: ['takeSnapshot', 'meshesLoaded'],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class MeshEditor extends Vue {
  //#region properties
  @Prop() modelValue!: MeshTreeData;
  @Prop({ default: true }) canTransform!: boolean;
  @Prop({ default: true }) canManualTransform!: boolean;
  @Prop({ default: true }) canTogglePivotMode!: boolean;
  @Prop({ default: true }) canMirror!: boolean;
  @Prop({ default: true }) canCopy!: boolean;
  @Prop({ default: true }) canAline!: boolean;
  @Prop({ default: true }) canGroup!: boolean;
  @Prop({ default: true }) canSelect!: boolean;
  @Prop({ default: true }) canColorize!: boolean;
  @Prop({ default: true }) canToggleBones!: boolean;
  @Prop({ default: true }) canUndo!: boolean;
  @Prop({ default: true }) canDeform!: boolean;
  @Prop({ default: true }) canCurve!: boolean;
  @Prop({ default: false }) canPartSelect!: boolean;
  @Prop({ default: false }) canEmbossing!: boolean;
  @Prop({ default: false }) canRender!: boolean;
  @Prop({ default: false }) canToggleOpacity!: boolean;
  @Prop({ default: false }) activateBones!: boolean;
  @Prop({ default: false }) activateAlineToFloor!: boolean;
  @Prop({ default: false }) activateDisplayBoundingBox!: boolean;
  @Prop({ default: true }) activateDisplayGrid!: boolean;
  @Prop({ default: EditorModeState.default })
  activeEditorMode!: EditorModeState;
  @Prop({ default: THREEEnum.ModifyType.translate })
  activeModifyType!: THREEEnum.ModifyType;
  @Prop({ default: false }) fitCameraToScene!: boolean;
  @Prop({ default: false }) resetOnPivotChanged!: boolean;
  @Prop({ default: THREEEnum.OrientationAxis.none })
  defaultOrientationAxis!: THREEEnum.OrientationAxis;
  @Prop({ default: Object.values(CurveCategory) })
  allowedCurveCategories!: CurveCategory[];
  @Prop({ default: false }) canTakeSnapshot!: boolean;
  @Prop({ default: true }) hasNavigationBar!: boolean;
  @Prop({ default: false }) canXr!: boolean;

  //loading
  isMounted = false;
  colors: string[] = ProductColors.default;
  uuid = uuidv4();
  isActive = false;
  isXrActive = false;

  xrRenderer: THREE.WebGLRenderer | null = null;

  //renderer
  troisRenderer!: RendererPublicInterface;

  // render camera
  view: string = THREEEnum.Views.custom;
  renderCameraPos: THREE.Vector3 = new THREE.Vector3(4, 4, 4);
  pointLightPos: THREE.Vector3 = new THREE.Vector3(4, 4, 4);
  lightDelta = new THREE.Vector3(0, 0, 0);
  viewCtrl!: ViewCtrl;

  // grid
  grid!: THREE.GridHelper;
  displayGrid = false;

  // editor mode
  editorMode: EditorMode = new EditorMode();

  // transform
  transformTools: TransformTools | null = null;

  //boundingBox
  displayBoundingBox = false;

  //bones
  boneTools: BoneTools | null = null;

  //deformation
  deformationTools: DeformationTools | null = null;

  //curve
  curveTools: CurveTools | null = null;

  //embossing
  projectionTools: ProjectionTools | null = null;
  showSidebar = true;
  selectedEmbossing: EmbossingData = {
    id: null,
    activeCategory: ProjectionCategory.custom,
    projection: [
      {
        projectionCategory: ProjectionCategory.custom,
        aspectRatio: 1,
        scaleFactor: 1,
        offset: { x: 0, y: 0 },
        embossingFile: null,
        color: '#ffffff',
        embossingDefinition: [],
        colorDefinition: {},
      },
    ],
  };

  //render
  renderTools: RenderTools | null = null;

  //selection
  selectionList!: SelectionList;

  // enums for use in template section
  Views = THREEEnum.Views;
  AxisType = THREEEnum.AxisType;
  OrientationAxis = THREEEnum.OrientationAxis;
  EditorMode = EditorModeState;

  // loading circle
  loadingCircle = false;

  controller = new AbortController();
  abortSignal: AbortSignal | null = null;

  arMeshList: THREE.Object3D[] = [];

  isXrInitialized = false;
  //#endregion properties

  //#region scene design
  mounted(): void {
    waitFor(() => addWebGlContext(this.uuid)).then(() => {
      this.isActive = true;
      setTimeout(() => {
        this.initEditor();
        //this.onModelValueStructureChanged();
      }, 100);
    });
    if (navigator.xr) {
      navigator.xr.isSessionSupported('immersive-ar').then((supported) => {
        this.isXrActive = supported;
      });
    }
  }

  unmounted(): void {
    if (this.troisRenderer) {
      this.troisRenderer.renderer.dispose();
      setTimeout(() => {
        this.troisRenderer.renderer.forceContextLoss();
      }, 100);
    }
    deleteWebGlContext(this.uuid);
    this.removeXrApp();
    const orbitCtrl = this.troisRenderer.three.cameraCtrl;
    if (orbitCtrl) {
      // setup camera control
      orbitCtrl.removeEventListener('change', this.changeOrbitCtrl);
      orbitCtrl.removeEventListener('start', this.startOrbitCtrl);
    }
    this.troisRenderer.renderer.domElement.removeEventListener(
      'pointerdown',
      this.onPointerDown
    );
    this.troisRenderer.renderer.domElement.removeEventListener(
      'mousemove',
      this.onPointerMove
    );
    this.troisRenderer.renderer.domElement.removeEventListener(
      'pointerup',
      this.onPointerUp
    );
  }

  setTroisRenderer(): void {
    if (this.$refs.troisRenderer) {
      this.troisRenderer = this.$refs.troisRenderer as RendererPublicInterface;
    }

    if(this.isXrActive && this.canXr) {
      this.initializeXrApp();
    }
  }

  removeXrApp() {
    this.isXrInitialized = false;
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    renderer.xr.enabled = false;
    const arButton = document.getElementById("xr-button");
    if (arButton)
      document.body.removeChild(arButton);
  }

  initializeXrApp(): void {
    this.isXrInitialized = true;
    const { devicePixelRatio, innerHeight, innerWidth } = window;
    
    // Create a new WebGL renderer and set the size + pixel ratio.
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    renderer.setSize(innerWidth, innerHeight);
    renderer.setPixelRatio(devicePixelRatio);
    
    // Enable XR functionality on the renderer.
    renderer.xr.enabled = true;

    // Add it to the DOM.
    // document.body.appendChild( renderer.domElement );

    // Create the AR button element, configure our XR session, and append it to the DOM.
    const arButton = ARButton.createButton(
      renderer,
      { 
        optionalFeatures: ['dom-overlay'],
        requiredFeatures: ["local", "hit-test"],
      },
    );
    arButton.removeAttribute('style');
    arButton.classList.add(...["btn", "md-2"]);
    arButton.id = "xr-button";
    arButton.hidden = true;

    document.body.appendChild(arButton);

    // Pass the renderer to the createScene-funtion.
    this.createXrScene(renderer);
  }

  createXrScene(renderer: THREE.WebGLRenderer): void {
    const scene = new THREE.Scene();
  
    const camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.02,
      20,
    );

    /**
    * Add some simple ambient lights to illuminate the model.
    */
    const ambientLight = new THREE.AmbientLight(0x808080, 1.0);
    scene.add(ambientLight);

    const pointLight = new THREE.PointLight(0xffffff, 0.55);
    camera.add(pointLight);
    scene.add(camera);

    /**
    * Create the plane marker to show on tracked surfaces.
    */
    const planeMarker = createPlaneMarker();
    scene.add(planeMarker);

    /**
    * Setup the controller to get input from the XR space.
    */
    const controller = renderer.xr.getController(0);
    scene.add(controller);

    const clearXrScene = () => {
      for(let i = scene.children.length - 1; i >= 0; i--) {
        const child = scene.children[i];
        if (child instanceof THREE.Group) {
          scene.remove(child);
        }
      }
    }

    const updateXrMeshList = () => {
      this.arMeshList = [];
      for(const item of this.modelValue.treeData) {
        this.arMeshList.push(item.mesh);
      }
    }

    /**
    * The onSelect function is called whenever we tap the screen
    * in XR mode.
    */
    const onSelect = () => {
      if (planeMarker.visible) {
        clearXrScene();

        const group = new THREE.Group();
        for (const item of this.arMeshList.filter(m => m != undefined)) {
          const model = item.clone(true);
          model.visible = true;
          group.add(model);
        }

        // Place the model on the spot where the marker is showing.
        group.position.setFromMatrixPosition(planeMarker.matrix);
        group.scale.set(0.001, 0.001, 0.001);

        const bbox = new THREE.Box3().setFromObject(group);
        group.position.x += group.position.x - bbox.min.x - ((bbox.max.x - bbox.min.x) / 2);
        group.position.y += group.position.y - bbox.min.y;
        group.position.z += group.position.z - bbox.min.z;
        group.visible = true;

        scene.add(group);
      }
    }

    controller.addEventListener("select", onSelect);

    /**
    * Called whenever a new hit test result is ready.
    */
    const onHitTestResultReady = (hitPoseTransformed: Float32Array) => {
      if (hitPoseTransformed) {
        planeMarker.visible = true;
        planeMarker.matrix.fromArray(hitPoseTransformed);
      }
    }

    /**
    * Called whenever the hit test is empty/unsuccesful.
    */
    const onHitTestResultEmpty = () => {
      planeMarker.visible = false;
    }

    const renderLoop = (timestamp: number, frame?: any) => {
      if (renderer.xr.isPresenting) {
        if (frame) {
          handleXRHitTest(
            renderer,
            frame,
            onHitTestResultReady,
            onHitTestResultEmpty
          );
        }
        renderer.render(scene, camera);
      }
    }
    
    renderer.xr.addEventListener("sessionend", () => { 
      renderer.setAnimationLoop(null);
      resetHitTestSource();
      clearXrScene();
    });
    renderer.xr.addEventListener("sessionstart", () => {
      renderer.setAnimationLoop(renderLoop);
      updateXrMeshList();
    });
  }

  initEditor(): void {
    LayoutUtility.refresh();
    this.setTroisRenderer();

    this.viewCtrl = new ViewCtrl(
      this.troisRenderer,
      this.$refs.orientationGizmo as OrientationGizmo,
      this.fitCameraToScene
    );
    if (this.troisRenderer) {
      this.selectionList = new SelectionList(
        this.modelValue,
        this.troisRenderer
      );
      if (this.troisRenderer.scene && this.troisRenderer.camera) {
        // setup orientation gizmo
        if (this.$refs.orientationGizmo)
          (this.$refs.orientationGizmo as OrientationGizmo).init(
            this.troisRenderer
          );

        const orbitCtrl = this.troisRenderer.three.cameraCtrl;
        if (orbitCtrl) {
          // setup camera control
          orbitCtrl.addEventListener('change', this.changeOrbitCtrl);
          orbitCtrl.addEventListener('start', this.startOrbitCtrl);
        }

        // setup grid helper
        this.grid = new THREE.GridHelper(
          10,
          50,
          LayoutColor.primary,
          0x444444
        );
        this.grid.visible = this.displayGrid;
        this.troisRenderer.scene.add(this.grid);

        this.troisRenderer.renderer.domElement.addEventListener(
          'pointerdown',
          this.onPointerDown
        );
        this.troisRenderer.renderer.domElement.addEventListener(
          'mousemove',
          this.onPointerMove
        );
        this.troisRenderer.renderer.domElement.addEventListener(
          'pointerup',
          this.onPointerUp
        );

        // setup axes helper
        // this.troisRenderer.scene.add(new THREE.AxesHelper(3));
      }

      this.isMounted = true;

      setTimeout(() => {
        if (this.$refs.transformTools)
          this.transformTools = this.$refs.transformTools as TransformTools;
        if (this.$refs.boneTools)
          this.boneTools = this.$refs.boneTools as BoneTools;
        if (this.$refs.deformationTools)
          this.deformationTools = this.$refs
            .deformationTools as DeformationTools;
        if (this.$refs.projectionTools)
          this.projectionTools = this.$refs.projectionTools as ProjectionTools;
        if (this.$refs.curveTools)
          this.curveTools = this.$refs.curveTools as CurveTools;
        if (this.$refs.renderTools)
          this.renderTools = this.$refs.renderTools as RenderTools;
      }, 100);
    }
  }

  startOrbitCtrl(): void {
    this.view = THREEEnum.Views.custom;
  }

  changeOrbitCtrl(): void {
    this.viewCtrl.updateOrientationGizmo();
    if (this.boneTools) this.boneTools.updateBones();
    const camera = this.troisRenderer.camera;
    if (camera) {
      const cameraDistance = this.viewCtrl.getCameraDistance();
      if (cameraDistance) {
        const lightPos = camera.localToWorld(
          this.lightDelta.clone().multiplyScalar(cameraDistance / 2)
        );
        this.pointLightPos.copy(lightPos);
      }
    }
  }

  getObjectById(
    id: string,
    searchList: THREE.Object3D[] | null = null
  ): THREE.Object3D | null {
    if (searchList === null) {
      const scene = this.troisRenderer.scene;
      if (scene) searchList = scene?.children;
    }
    if (searchList) {
      for (const item of searchList) {
        if (item.uuid === id) return item;
        const result = this.getObjectById(id, item.children);
        if (result) return result;
      }
    }
    return null;
  }

  getObjectByApiId(
    id: string,
    searchList: THREE.Object3D[] | null = null
  ): THREE.Object3D | null {
    if (searchList === null) {
      const scene = this.troisRenderer.scene;
      if (scene) searchList = scene?.children;
    }
    if (searchList) {
      for (const item of searchList) {
        const apiData = (item as any).apiData as MeshTreeDataItem;
        if (apiData?.uuid === id) return item;
        const result = this.getObjectByApiId(id, item.children);
        if (result) return result;
      }
    }
    return null;
  }

  @Watch('modelValue.lastUpdateStructure', { immediate: false, deep: false })
  async onModelValueStructureChanged(): Promise<void> {
    if (this.abortSignal) {
      this.controller.abort();
      this.controller = new AbortController();
    }
    const getAllSceneObjects = (
      parent: THREE.Object3D | THREE.Scene
    ): {
      item: THREE.Object3D;
      parent: THREE.Object3D | THREE.Scene;
    }[] => {
      const customMeshList = parent.children
        .filter((object) => (object as any).apiData)
        .map((item) => {
          return {
            item: item,
            parent: parent,
          };
        });
      for (const item of parent.children) {
        customMeshList.push(...getAllSceneObjects(item));
      }
      return customMeshList;
    };

    const loadTreeData = async (
      items: MeshTreeDataItem[],
      signal: AbortSignal,
      parent: MeshTreeDataItem | null = null
    ): Promise<void> => {
      try {
        const scene = this.troisRenderer.scene;
        if (scene) {
          for (const item of items) {
            if (!item.isNone) {
              item.scene = scene;
              await THREEInit.initObject3D(item, parent, false).then(
                (object) => {
                  if (signal.aborted) {
                    throw Error('AbortError');
                  }
                  if (object && !parent?.isGroup) {
                    item.scene.add(object);

                    if (!this.canSelect && items[0].uuid === object.uuid)
                      this.selectObject(object, true, true, true);
                  }
                }
              );
            } else if (item.isCamera) {
              (item.camera as any).apiData = item;
            }
            if (item.children) await loadTreeData(item.children, signal, item);
            for (const child of item.children) {
              const newParent = item.isNone ? scene : item.mesh;
              if (child.mesh) {
                const childMesh = this.getObjectById(child.mesh.uuid);
                if (
                  childMesh &&
                  childMesh.parent &&
                  childMesh.parent.uuid !== newParent.uuid
                ) {
                  const oldParent = childMesh.parent;
                  let childWorldPosition = childMesh.position;
                  if (oldParent && !(oldParent as any).isScene) {
                    childWorldPosition = oldParent.localToWorld(
                      childMesh.position
                    );
                  }
                  newParent.add(childMesh);
                  childMesh.position.copy(
                    newParent.worldToLocal(childWorldPosition)
                  );
                }
              }
            }
            if (
              item.mesh &&
              item.isSelected &&
              !this.selectionList.isSelected(item)
            )
              await this.selectTreeItem(item);
          }
        }
      } catch (e: any) {
        if (e.message === 'AbortError') {
          cleanupTreeData();
          this.loadingCircle = false;
        }
      }
    };
    const cleanupTreeData = (): void => {
      if (this.troisRenderer.scene) {
        const scene = this.troisRenderer.scene;
        const customMeshList = getAllSceneObjects(scene);
        customMeshList.forEach((object) => {
          if (
            (object.item as any).apiData &&
            !this.modelValue.exists((object.item as any).apiData.uuid)
          ) {
            if (
              this.transformTools &&
              this.selectionList
                .getSelectedObjects()
                .find((child) => child.uuid === object.item.uuid)
            ) {
              this.selectionList.deselectObject(object.item);
              scene.remove(this.transformTools.transformControl);
            }
            object.parent.remove(object.item);
            this.modelValue.historyList.remove(
              (object.item as any).apiData.uuid,
              null
            );
          }
        });
      }
    };

    await waitFor(() => this.isMounted);
    if (this.troisRenderer) {
      cleanupTreeData();
      this.loadingCircle = true;
      if (this.modelValue.treeData.length > 0) {
        this.abortSignal = this.controller.signal;
        await loadTreeData(this.modelValue.treeData, this.abortSignal).finally(() => {
          this.abortSignal = null;
          this.loadingCircle = false;
          this.$emit("meshesLoaded");
        });
      }

      if (this.modelValue.treeData.length > 0) {
        this.viewCtrl.fitControlCameraToScene();
      }
    }
  }

  @Watch('activateDisplayBoundingBox', { immediate: true })
  onActivateDisplayBoundingBoxChanged(): void {
    this.displayBoundingBox = this.activateDisplayBoundingBox;
  }

  @Watch('fitCameraToScene', { immediate: true })
  fitCameraToSceneChanged(): void {
    if (this.viewCtrl) this.viewCtrl.fitCameraToScene = this.fitCameraToScene;
  }

  @Watch('modelValue.lastUpdateVisibility', { immediate: false, deep: false })
  async onModelValueVisibilityChanged(): Promise<void> {
    const syncData = async (items: MeshTreeDataItem[]): Promise<void> => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const item of items) {
        if (item.children && item.children.length > 0)
          await syncData(item.children);
        if (item.syncData) {
          item.syncData = false;
          if (item.mesh && item.opacity)
            await THREEMaterial.setOpacity(item.mesh, item.opacity);
          await THREEMaterial.setWireframe(item.mesh, item.showWireframe);
          if (
            (item.mesh || item.camera) &&
            item.isSelected &&
            !this.selectionList.isSelected(item)
          )
            await this.selectTreeItem(item);
        }
      }
    };
    if (this.boneTools) this.boneTools.updateBones();

    if (this.troisRenderer) {
      await syncData(this.modelValue.treeData);
    }
  }

  async syncOpacity(): Promise<void> {
    const syncData = async (items: MeshTreeDataItem[]): Promise<void> => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const item of items) {
        if (item.children && item.children.length > 0)
          await syncData(item.children);
        if (item.mesh && item.opacity) {
          await THREEMaterial.setOpacity(item.mesh, item.opacity);
        }
      }
    };

    if (this.troisRenderer) {
      await syncData(this.modelValue.treeData);
    }
  }
  //#endregion scene design

  //#region selection
  mouseDownPosition!: THREE.Vector2;
  onPointerDown(event: MouseEvent): void {
    if (this.transformTools) this.transformTools.transformStart();
    this.mouseDownPosition = new THREE.Vector2(event.offsetX, event.offsetY);
    if (this.transformTools && this.editorMode.isOrientationMode)
      this.transformTools.updateStartPoint(event);
  }

  onPointerMove(event: MouseEvent): void {
    if (this.transformTools && this.editorMode.isOrientationMode) {
      this.transformTools.updateEndPoint(event);
    }
  }

  onPointerUp(event: MouseEvent): void {
    if (this.mouseDownPosition) {
      const movingDistance = this.mouseDownPosition.distanceTo(
        new THREE.Vector2(event.offsetX, event.offsetY)
      );
      if (movingDistance < 20) {
        if (this.canSelect) {
          const specialTarget = this.selectionList.getClickedObject(
            event,
            true,
            null,
            true
          );
          const target = this.selectionList.getClickedObject(
            event,
            true,
            null,
            false
          );

          // prevent selecting "Reißverschluss" part
          if (target && (target as any).apiData && (target as any).apiData.name === 'Reißverschluss') {
            return;
          }

          if (!specialTarget || target) this.selectObject(target);
        } else if (this.editorMode.isBoneMode && this.boneTools) {
          this.boneTools.updateBonePosition(event);
          const selection = this.modelValue.getSelection();
          if (selection.length > 0) {
            const targetBone = this.selectionList.getClickedObject(
              event,
              false,
              'Bone'
            );
            if (targetBone) {
              selection[0].activeBoneIndex =
                this.boneTools.boneContainer.children.indexOf(targetBone);
              this.modelValue.updateVisibility();
            }
          }
        }
      }
    }
    this.onPointerMove(event);
    if (this.transformTools && this.editorMode.isOrientationMode)
      this.transformTools.finishIntersection();
  }

  async selectMesh(meshId: string): Promise<void> {
    const mesh = this.getObjectByApiId(meshId);
    await this.selectObject(mesh);
  }

  private editorSelectionMode: EditorModeState = EditorModeState.default;
  async selectObject(
    target: THREE.Object3D | null,
    setSelectionFlag = true,
    deselectPrevious = true,
    triggerUpdate = false
  ): Promise<void> {
    if (
      this.selectionList.getSelectedObject()?.uuid === target?.uuid &&
      this.editorMode.editorMode === this.editorSelectionMode
    ) {
      return;
    }
    if (target && (target as any).apiData) {
      const sceneTarget = this.getObjectByApiId((target as any).apiData.uuid);
      if (sceneTarget) target = sceneTarget;
    }
    this.editorSelectionMode = this.editorMode.editorMode;
    if (this.transformTools)
      await this.transformTools.setTransformControlsForTarget(target);
    await this.selectionList.selectObject(
      target,
      setSelectionFlag,
      deselectPrevious,
      triggerUpdate
    );

    if (target) {
      this.updateModeOpacity(target);
      this.modelValue.updateVisibility();
      if (this.displayBoundingBox) this.updateBoundingBox([target]);
      if (this.boneTools) this.boneTools.updateBones();
    } else {
      if (
        this.editorMode.isPivotMode ||
        this.editorMode.isOrientationMode ||
        this.editorMode.isBoneMode
      ) {
        await this.syncOpacity();
      }
    }
    if (this.deformationTools) {
      this.deformationTools.setToolForSelection();
    }
    if (this.projectionTools) {
      this.projectionTools.createProjectionReferenceForSelectedObject();
    }
    if (this.curveTools) {
      this.curveTools.selectedObjectChanged();
    }
    if (this.renderTools) {
      this.renderTools.selectedObjectChanged();
    }
  }

  private updateBoundingBox(selection: THREE.Object3D[]): void {
    let center: THREE.Vector3 | null = null;
    if (this.transformTools && selection.length > 0) {
      const selectionObject = selection[0];
      selectionObject.add(this.transformTools.centerPoint);
      if (this.displayBoundingBox) {
        center = THREEBoundingBox.addBoundingBox(selection);
      } else {
        const boundingBox = THREEBoundingBox.getBoundingBox(selection);
        center = boundingBox.getCenter(new THREE.Vector3());
      }
      if (center)
        this.transformTools.centerPoint.position.copy(
          selectionObject.worldToLocal(center)
        );
      this.transformTools.centerPoint.rotation.copy(
        this.transformTools.pivot.rotation
      );
      this.viewCtrl.adaptPointerSize(this.transformTools.centerPoint);
    }
  }

  async selectTreeItem(target: MeshTreeDataItem | null): Promise<void> {
    if (target) {
      if (!this.selectionList.isSelected(target)) {
        const list = this.selectionList.getSceneObjects(null, false);
        const sceneTarget = list.find((item) => item.uuid === target.uuid);
        if (sceneTarget) await this.selectObject(sceneTarget, false);
        else if (target.mesh) await this.selectObject(target.mesh, false);
        else if (target.camera) await this.selectObject(target.camera, false);
      }
    } else {
      await this.selectObject(null, false);
    }
  }
  //#endregion selection

  //#region editor mode
  @Watch('activeEditorMode', { immediate: true })
  async onActiveEditorModeChanged(): Promise<void> {
    this.editorMode.state = this.activeEditorMode;
    if (this.selectionList) {
      const selection = this.selectionList.getSelectedObject();
      this.updateModeOpacity(selection);
    }
    if (this.transformTools) {
      this.transformTools.modifyPivot();
      this.transformTools.setOrientationDirection(
        this.defaultOrientationAxis,
        false
      );
    }
  }

  updateModeOpacity(target: THREE.Object3D | null): void {
    const opacity = this.editorMode.getModeOpacity();
    if (opacity < 100 && this.canTransform) {
      if (target) {
        THREEMaterial.setOpacity(target, opacity);
      }
    } else {
      this.syncOpacity();
    }

    if (this.transformTools && this.boneTools) {
      switch (this.editorMode.state) {
        case EditorModeState.pivot:
          THREEMaterial.setOpacity(this.transformTools.pivot, 100);
          break;
        case EditorModeState.orientation:
          THREEMaterial.setOpacity(this.transformTools.pivot, 100);
          THREEMaterial.setOpacity(this.transformTools.arrowHelper, 100);
          break;
        case EditorModeState.bone:
          THREEMaterial.setOpacity(this.boneTools.boneContainer, 100);
          break;
      }
    }
  }

  get usePivotMode(): boolean {
    if (this.transformTools) {
      return this.editorMode.usePivotMode(
        this.transformTools.orientationDirection
      );
    }
    return false;
  }
  //#endregion editor mode

  //#region tools
  @Watch('canEmbossing', { immediate: true })
  @Watch('showSidebar', { immediate: true })
  onShowSidebarChanged(): void {
    LayoutUtility.refresh();
  }

  colorizeSelected(color: string): void {
    const selection = this.selectionList.getSelectedObject();
    if (selection) {
      const oldColor = THREEMaterial.getColor(selection);
      this.colorize(selection, color);
      this.modelValue.historyList.add(
        (selection as any).apiData.uuid,
        HistoryOperationType.color,
        async () => this.colorize(selection, oldColor),
        async () => this.colorize(selection, color)
      );
    }
  }

  colorize(mesh: THREE.Object3D, color: string): void {
    THREEMaterial.setColor(mesh, color);
    this.modelValue.updateDB((mesh as any).apiData.uuid);
  }

  mirrorSelected(axis: THREEEnum.AxisType): void {
    const meshId = this.selectionList.getSelectedObjectUuid();
    if (meshId) {
      this.mirror(this.selectionList.getSelectedObjects(), axis);
      this.modelValue.historyList.add(
        meshId,
        HistoryOperationType.mirror,
        async () => this.mirror(this.selectionList.getSelectedObjects(), axis),
        async () => this.mirror(this.selectionList.getSelectedObjects(), axis)
      );
    }
  }

  mirror(meshes: THREE.Object3D[], axis: THREEEnum.AxisType): void {
    THREETransform.mirror(meshes, axis);
    for (const selection of meshes) {
      this.modelValue.updateDB((selection as any).apiData.uuid);
    }
    this.updateBoundingBox(this.selectionList.getSelectedObjects());
  }

  copySelected(axis: THREEEnum.AxisType): void {
    const selection = this.selectionList.getSelectedObjects();
    selection.forEach((object) => {
      const bounds = THREEBoundingBox.getBoundingBox([object]);
      const item = (object as any).apiData as MeshTreeDataItem;
      if (item) {
        const copy = this.modelValue.copy(item);
        if (copy) {
          THREETransform.shiftObject(copy, axis, bounds);
        }
      } else {
        const copy = THREETransform.copyObject(object);
        if (copy) {
          THREETransform.shiftObject(copy, axis, bounds);
          if (this.troisRenderer.scene) this.troisRenderer.scene.add(copy);
          this.selectObject(copy);
        }
      }
    });
  }

  toggleGrid(): void {
    this.displayGrid = !this.displayGrid;
    if (this.grid) this.grid.visible = this.displayGrid;
  }

  toggleBoundingBox(): void {
    this.displayBoundingBox = !this.displayBoundingBox;
    if (this.displayBoundingBox)
      THREEBoundingBox.addBoundingBox(this.selectionList.getSelectedObjects());
    else
      THREEBoundingBox.removeBoundingBox(
        this.selectionList.getSelectedObjects()
      );
  }

  toggleBones(): void {
    if (this.boneTools) this.boneTools.toggleBones();
  }

  alineSelectedToFloor(): void {
    const meshId = this.selectionList.getSelectedObjectUuid();
    if (meshId) {
      const oldPositions = this.selectionList
        .getSelectedObjects()
        .map((item) => {
          return {
            mesh: item,
            position: item.position.clone(),
          };
        });
      this.alineToFloor(this.selectionList.getSelectedObjects());
      this.modelValue.historyList.add(
        meshId,
        HistoryOperationType.aline,
        async () => {
          for (const item of oldPositions) {
            item.mesh.position.copy(item.position.clone());
          }
        },
        async () => this.alineToFloor(this.selectionList.getSelectedObjects())
      );
    }
  }

  alineToFloor(meshes: THREE.Object3D[]): void {
    THREETransform.alineToFloor(meshes);
    for (const selection of meshes) {
      this.modelValue.updateDB((selection as any).apiData.uuid);
    }
  }

  groupObject(): void {
    const selection = this.modelValue.getSelection();
    if (selection.length > 0) {
      const selectionItem = selection[0];
      const parentItem = this.modelValue.getParent(selectionItem);
      const group = this.modelValue.addGroup(
        'Group',
        [],
        new THREE.Vector3(0, 0, 0),
        new THREE.Euler(0, 0, 0),
        new THREE.Vector3(1, 1, 1),
        parentItem
      );
      group.children.push(selectionItem);
      if (parentItem) {
        const index = parentItem.children.indexOf(selectionItem);
        if (index > -1) {
          parentItem.children.splice(index, 1);
        }
      }
      this.modelValue.updateDB(selectionItem.uuid);
      this.modelValue.updateStructure();
    }
  }
  //#endregion tools

  //#region history
  undo(): void {
    this.modelValue.historyList.goBack();
    this.updateHelper();
  }

  redo(): void {
    this.modelValue.historyList.goForward();
    this.updateHelper();
  }

  updateHelper(): void {
    this.viewCtrl.fitControlCameraToScene();
    if (this.transformTools)
      this.viewCtrl.adaptPointerSize(this.transformTools.pivot);
    if (this.boneTools) this.boneTools.updateBones();
  }
  //#endregion history

  //#region pivot
  updateAppearance(): void {
    const pivotBoneActive = THREEBone.pivotBoneActive(this.modelValue);
    if (
      this.usePivotMode ||
      this.editorMode.isOrientationMode ||
      (this.editorMode.isBoneMode && pivotBoneActive)
    ) {
      this.updateBoundingBox(this.selectionList.getSelectedObjects());
    }
  }
  //#endregion pivot

  //#region camera
  zoom(factor: number): void {
    this.viewCtrl.zoom(factor);
  }

  changeView(value: string): void {
    this.view = value;
    this.viewCtrl.changeView(value);
  }
  //#endregion camera

  //#region customize
  selectPart(data: MeshTreeDataItem): void {
    this.modelValue.selectItem(data, true, true);
  }
  //#endregion customize

  //#region import / export
  exportFfdForMeshId(meshId: string): FFDExportData | null {
    if (this.deformationTools)
      return this.deformationTools.exportFfdForMeshId(meshId);
    return null;
  }

  importFfdForMeshId(
    meshId: string,
    spanCount: number[],
    gridPositionList: THREE.Vector3[]
  ): void {
    if (this.deformationTools)
      this.deformationTools.importFfdForMeshId(
        meshId,
        spanCount,
        gridPositionList
      );
  }

  exportEmbossingForMeshId(meshId: string): EmbossingExportData[] {
    if (this.projectionTools)
      return this.projectionTools.exportEmbossingForMeshId(meshId);
    return [];
  }

  importEmbossingForMeshId(
    meshId: string,
    embossingList: EmbossingExportData[]
  ): void {
    if (this.projectionTools)
      this.projectionTools.importEmbossingForMeshId(meshId, embossingList);
  }

  exportCurves(): CurveExportData[] {
    if (this.curveTools) return this.curveTools.exportCurves();
    return [];
  }

  importCurves(data: CurveExportData[]): void {
    if (this.curveTools) this.curveTools.importCurves(data);
  }

  exportCameras(): CameraExportData[] {
    if (this.renderTools) return this.renderTools.exportCameras();
    return [];
  }

  importCameras(data: CameraExportData[]): void {
    if (this.renderTools) this.renderTools.importCameras(data);
  }
  //#endregion import / export

  takeSnapshot(): void {
    this.$emit('takeSnapshot', this.view);
  }

  toggleOpacity(): void {
    const selectedMesh = this.modelValue.treeData.find((x) => x.isSelected);

    if (selectedMesh) {
      selectedMesh.opacity = selectedMesh.opacity === 100 ? 0 : 100;
      THREEMaterial.setOpacity(selectedMesh.mesh, selectedMesh.opacity);
    }
  }

  setOpacity(selectedMesh: MeshTreeDataItem, opacity: number): void {
    if (selectedMesh) {
      selectedMesh.opacity = opacity;
      THREEMaterial.setOpacity(selectedMesh.mesh, selectedMesh.opacity);
    }
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  min-height: 20rem;
  overflow-x: hidden;

  .el-main {
    overflow-y: hidden;
    position: relative;
  }
}

.el-aside {
  background-color: white;
}

.minimize::v-deep {
  width: 0;
  color: var(--color-sidebar);

  .expander-side {
    background-color: white;
  }
}

.active {
  color: var(--el-color-primary);
}

.displayContents {
  display: contents;
  max-height: calc(100vh - var(--el-footer-height) - var(--el-header-height));
}

.fillHorizontal {
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
}

.webGlView {
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(180deg, #F0F0F0, #D6D6D6);
  width: 100vw;
  height: 100vh;
}

.logo {
  position: absolute;
  margin-top: 2rem;
  margin-left: 2rem;
}

.level,
.level-left {
  display: flex;
}

.level:not(:last-child) {
  margin-bottom: 0;
}

.level-item:not(:last-child) {
  margin-right: 0.75rem;
  margin-bottom: 0;
}

.hierarchy {
  position: absolute;
  height: 100%;

  .el-scrollbar .el-space {
    padding: 1rem 1rem 1rem 1rem;
    margin-right: unset;
  }

  .el-scrollbar {
    --el-scrollbar-bg-color: var(--el-text-color-primary);
    --el-scrollbar-hover-bg-color: var(--el-text-color-primary);
  }

  .el-space .upload-item {
    width: 6rem;
    height: 6rem;
  }
}
</style>
