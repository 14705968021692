import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f3f457a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "level-left" }
const _hoisted_2 = { class: "level-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_page_header = _resolveComponent("el-page-header")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_MeshTree = _resolveComponent("MeshTree")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_ToggleSidebar = _resolveComponent("ToggleSidebar")!
  const _component_MeshEditor = _resolveComponent("MeshEditor")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_Workflow = _resolveComponent("Workflow")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_container, { class: "screenHeight" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, { class: "level" }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, [
            _createVNode(_component_el_page_header, {
              class: "level-item",
              content: _ctx.$t(`enum.order-progress.${_ctx.activeOrderProgress}`),
              onBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
            }, null, 8, ["content"])
          ]),
          _createElementVNode("span", _hoisted_2, [
            _withDirectives((_openBlock(), _createBlock(_component_el_button, {
              link: "",
              class: "level-item",
              onClick: _ctx.saveUpdated,
              disabled: !_ctx.hasChanges
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, { icon: "save" })
              ]),
              _: 1
            }, 8, ["onClick", "disabled"])), [
              [
                _directive_loading,
                _ctx.fullscreenLoading,
                void 0,
                {
                  fullscreen: true,
                  lock: true
                }
              ]
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          (_ctx.showTree)
            ? (_openBlock(), _createBlock(_component_el_aside, {
                key: 0,
                class: "withFooterAndHeader",
                width: "20rem"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_scrollbar, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_MeshTree, {
                        modelValue: _ctx.meshTreeData,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.meshTreeData) = $event)),
                        addUrl: `/order-upload?templateId=${_ctx.templateId}&orderId=${_ctx.orderId}`,
                        onDeleteItem: _ctx.deleteItem
                      }, null, 8, ["modelValue", "addUrl", "onDeleteItem"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ToggleSidebar, {
            modelValue: _ctx.showTree,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showTree) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_el_main, null, {
            default: _withCtx(() => [
              _createVNode(_component_MeshEditor, {
                ref: "meshEditor",
                modelValue: _ctx.meshTreeData,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.meshTreeData) = $event)),
                fitCameraToScene: true,
                activateAlineToFloor: true,
                canCurve: 
            _ctx.activeOrderProgress === _ctx.OrderProgress.crop ||
            _ctx.activeOrderProgress === _ctx.OrderProgress.zip ||
            _ctx.activeOrderProgress === _ctx.OrderProgress.spacer
          ,
                canDeform: _ctx.activeOrderProgress === _ctx.OrderProgress.adaptation,
                canTransform: _ctx.activeOrderProgress === _ctx.OrderProgress.setup,
                canMirror: _ctx.activeOrderProgress === _ctx.OrderProgress.setup,
                canCopy: _ctx.activeOrderProgress === _ctx.OrderProgress.setup,
                canAline: _ctx.activeOrderProgress === _ctx.OrderProgress.setup,
                canGroup: _ctx.activeOrderProgress === _ctx.OrderProgress.setup,
                canColorize: false,
                canEmbossing: _ctx.activeOrderProgress === _ctx.OrderProgress.embossing,
                canRender: _ctx.activeOrderProgress === _ctx.OrderProgress.render,
                allowedCurveCategories: _ctx.allowedCurveCategories
              }, null, 8, ["modelValue", "canCurve", "canDeform", "canTransform", "canMirror", "canCopy", "canAline", "canGroup", "canEmbossing", "canRender", "allowedCurveCategories"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_Workflow, {
        workflowModel: _ctx.workflowModel,
        fullscreenLoading: _ctx.fullscreenLoading
      }, null, 8, ["workflowModel", "fullscreenLoading"])
    ]),
    _: 1
  }))
}