import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d19acc5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "noImage"
}
const _hoisted_5 = {
  key: 1,
  class: "el-upload-list__item-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.meshes, (mesh) => {
    return (_openBlock(), _createElementBlock("div", {
      style: _normalizeStyle({ width: _ctx.imgSize + 'rem', height: _ctx.imgSize + 'rem' }),
      class: _normalizeClass({ selected: _ctx.canSelect && (mesh.isSelected ?? _ctx.isSelected) }),
      key: mesh.thumbnail!,
      onClick: ($event: any) => (_ctx.selectItem(mesh))
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock("img", {
          key: mesh.thumbnail!,
          src: 
          mesh.thumbnailUrl == ''
            ? require(`@/assets/images/noImage.jpg`)
            : mesh.thumbnailUrl
        ,
          alt: ""
        }, null, 8, _hoisted_3)),
        (_ctx.canTakeSnapshot)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createVNode(_component_font_awesome_icon, {
                  icon: "camera-viewfinder",
                  onClick: ($event: any) => (_ctx.takeSnapshot(mesh))
                }, null, 8, ["onClick"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.canRemove)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              (_ctx.canRemove)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "el-upload-list__item-delete",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('removeItem')))
                  }, [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "trash" })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ], 14, _hoisted_1))
  }), 128))
}