<script lang="ts">
import {Product} from "@/types/api/Model/Product";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ProductParameterCover from "@/components/productParameters/ProductParameterCover.vue";
import ProductParameterCuboid from "@/components/productParameters/ProductParameterCuboid.vue";
import { File } from "@/types/api/Utility/File";
import { Body_Attribute } from "@/types/api/Patient/BodyAttribute";
import { LinearLightNameType } from "@/types/enum/order";
import ProductParameterBalcony from "@/components/productParameters/ProductParameterBalcony.vue";
import ProductParameterFence from "@/components/productParameters/ProductParameterFence.vue";
import ProductParameterOrthesis from "@/components/productParameters/ProductParameterOrthesis.vue";
import ProductParameterLinearLight from "@/components/productParameters/ProductParameterLinearLight.vue";
import MeasurementInfoCard from "@/components/MeasurementInfoCard.vue"
import { Modal } from "bootstrap";

@Options({
  components: {
    MeasurementInfoCard,
    ProductParameterCover,
    ProductParameterCuboid,
    ProductParameterBalcony,
    ProductParameterFence,
    ProductParameterOrthesis,
    ProductParameterLinearLight
  },
  emits: ['buttonClicked', 'zipChanged', 'infoButtonClicked'],
})

export default class ProductParamter extends Vue {
  @Prop() product!: Product;
  @Prop() properties!: any[];
  @Prop({ default: false }) readonly!: boolean

  selectedBodyAttribute: Body_Attribute | null = null;

  private measurementInfoModalDialog: any = null;
  selectedContent: string | null = null;

  LinearLightNameType = LinearLightNameType;

  mounted(): void {
    // Set default values of properties
    switch(this.product.type.name.toLowerCase()) {
      case "covers":
        this.properties[0].heightWithoutDeduction = 330;
        this.properties[0].depth = 265;
        this.properties[0].calfCircumferenceWithoutDeduction = 200;
        break;
      case "zäune":
        this.properties[0].heightWithoutDeduction = 1100;
        break;
      case "orthesen":
        this.properties[0].heightWithoutDeduction = 420;
        this.properties[0].depth = 180;
        break;
    }

    const modalDialogOptions = {};
    this.measurementInfoModalDialog = new Modal('#measurement-info', modalDialogOptions);
  }

  selected(bodyAttribute: Body_Attribute): void {
    this.selectedBodyAttribute = bodyAttribute as Body_Attribute;
  }

  showMeasurementInfoModalDialog(content): void {
    this.selectedContent = content;
    this.measurementInfoModalDialog.show();
  }
}
</script>

<template>
  <div id="product-parameter" class="pl-5 pr-5 pt-3 pb-3 rounded-3 text-white">
    <h1>{{ $t('components.product-parameter.title') }}</h1>
    <div class="row ml-0 mr-0">
      <div class="col col-lg-8">
        <h2 class="row">{{ product.name }}</h2>
        <p class="row form-text text-white" id="product-details-info-text">{{ product.description }}</p>
      </div>
      <div class="col col-lg-4 d-flex justify-content-end">
        <slot></slot>
      </div>
    </div>
    <div class="row">
      <product-parameter-cover
        v-if="product.type.name.toLowerCase() == 'covers'"
        :properties="properties"
        :product="product"
        @zip-changed="(zip) => $emit('zipChanged', zip)"
        @info-button-clicked="showMeasurementInfoModalDialog"
        v-on:scanFileChanged="selected"
        :readonly="readonly"
      />
      <product-parameter-orthesis
        v-if="product.type.name.toLowerCase() == 'orthesen'"
        :properties="properties"
        :product="product"
        @zip-changed="(zip) => $emit('zipChanged', zip)"
        v-on:scanFileChanged="selected"
        :readonly="readonly"
      />
      <!--<product-parameter-cuboid
        v-if="product.type.name.toLowerCase() == 'möbel' ||
              product.type.name.toLowerCase() == 'sonstige' ||
              product.type.name.toLowerCase() == 'hütten'"
        :properties="properties"
      />
      -->
      <product-parameter-balcony
        v-if="product.type.name.toLowerCase() == 'balkone'"
        :properties="properties"
        :readonly="readonly"
      />
      <product-parameter-fence
        v-if="product.type.name.toLowerCase() == 'zäune'"
        :properties="properties"
        :readonly="readonly"
      />
      <product-parameter-linear-light
        v-if="product.type.name.toLowerCase() == 'lineare beleuchtung'"
        :properties="properties"
        :isHangingLight="product.name.toLowerCase() === LinearLightNameType.hanging.toLowerCase()"
        :isSurfaceMountedLight="product.name.toLowerCase() === LinearLightNameType.surfaceMounted.toLowerCase()"
        :readonly="readonly"
      />

      <div class="modal fade" id="measurement-info" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
    <div class="modal-content" id="info-content">
      <div class="modal-header border-bottom-0 bg-transparent" id="info-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0" id="info-body">
        <measurement-info-card>
          <div class="row">
            <div class="col-12" v-if="selectedContent == 'content1'">
              <h2>{{ $t('components.order-form.scope-top') }}</h2>
              <div class="row">
                <div class="col-md-6" id="txtcol">
                  <p class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.scope.top.info') }}</p>
                  <ul style="list-style-type: disc; padding-left: 20px;">
                    <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.scope.bullet1') }}</li>
                    <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.scope.bullet2') }}</li>
                    <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.scope.bullet3') }}</li>
                    <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.scope.bullet4') }}</li>
                    <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.scope.bullet5') }}</li>
                  </ul>
                </div>
                <div class="col-md-6" id="imgcol">
                  <img id="infoimg" class="img-fluid" src="../../assets/images/covers/scope_calve.png">
                </div>
              </div>
            </div>
            <div class="col-12" v-if="selectedContent == 'content2'">
              <h2>{{ $t('components.order-form.height') }}</h2>
              <div class="row">
                <div class="col-md-6" id="txtcol">
                  <div v-if="product.name.toLowerCase().includes('unterschenkel')">
                    <p class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.height-leg.upper.info') }}</p>
                    <ul style="list-style-type: disc; padding-left: 20px;">
                      <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.height-leg.bullet1') }}</li>
                      <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.height-leg.bullet2') }}</li>
                      <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.height-leg.bullet3') }}</li>
                      <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.height-leg.bullet4') }}</li>
                      <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.height-leg.bullet5') }}</li>
                    </ul>
                  </div>
                  <div v-else>
                    <p class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.height-leg.lower.info') }}</p>
                    <ul style="list-style-type: disc; padding-left: 20px;">
                      <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.height-leg.bullet1') }}</li>
                      <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.height-leg.bullet2') }}</li>
                      <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.height-leg.bullet3') }}</li>
                      <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.height-leg.bullet4') }}</li>
                      <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.height-leg.bullet5') }}</li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6" id="imgcol">
                  <img v-if="product.name.toLowerCase().includes('unterschenkel')" id="infoimg" class="img-fluid" src="../../assets/images/covers/height_lower_leg.png">
                  <img v-else id="infoimg" class="img-fluid" src="../../assets/images/covers/height_upper_leg.png">
                </div>
              </div>
            </div>
            <div class="col-12" v-if="selectedContent == 'content3'">
              <h2>{{ $t('components.order-form.scope-bottom') }}</h2>
              <div class="row">
                <div class="col-md-6" id="txtcol">
                  <p class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.scope.bottom.info') }}</p>
                  <ul style="list-style-type: disc; padding-left: 20px;">
                    <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.scope.bullet1') }}</li>
                    <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.scope.bullet2') }}</li>
                    <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.scope.bullet3') }}</li>
                    <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.scope.bullet4') }}</li>
                    <li class="form-text text-black">{{ $t('components.product-parameter.measurementsInfo.scope.bullet5') }}</li>
                  </ul>
                </div>
                <div class="col-md-6" id="imgcol">
                  <img id="infoimg" class="img-fluid" src="../../assets/images/covers/scope_ankle.png">
                </div>
              </div>
            </div>
          </div>
        </measurement-info-card>
      </div>
    </div>
  </div>
</div>






      <!-- Button -->
      <div class="row d-flex justify-content-center mb-5 mt-2">
        <button type="button" class="btn btn-primary" @click="$emit('buttonClicked', selectedBodyAttribute)">{{ $t('components.product-parameter.startConfigurator') }}</button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
#product-parameter {
  background: transparent radial-gradient(closest-side at 50% 50%, rgba(75, 75, 75, 0.85) 0%, rgba(0, 0, 0, 0.85) 100%) 0% 0% no-repeat padding-box;
}

#value1-range-text,
#value2-range-text,
#value1,
#value2 {
  margin-top: -0.65rem;
}

#value1,
#value2 {
  padding: 0.5rem;
}

#product-parameter-info-text {
  font-size: 1rem;
}

#measurement-info {
  box-sizing: border-box;
  height: 100%;
  width: 60%;
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

#info-content {
  background-color: transparent;
}

#info-header {
  background-color: transparent;
  z-index: 1;
}

#info-body {
  background-color: rgba(0, 0, 0, 0.85);
  margin-top: -3.6rem;
  z-index: 0;
  display: flex;
  flex-direction: column;
  height: 80%;
  overflow: scroll;
}

#infoimg {
  max-height: 330px;
  max-width: auto;
}

#txtcol {
  overflow: auto;
  flex: 1;
}

#imgcol {
  overflow: auto;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  #txtcol,
  #imgcol {
    width: 100%;
  }
  
  #infoimg {
    margin-left: 0;
  }
}
</style>
