import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a3e9ef3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row gap-4 d-flex justify-content-center mt-4"
}
const _hoisted_2 = { style: {"width":"auto","color":"red"} }
const _hoisted_3 = { class: "row gap-4 d-flex justify-content-center mt-4 pb-5" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  id: "pdf-part-list",
  target: "_blank",
  download: "part-list.pdf"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  id: "pdf-offer",
  target: "_blank",
  download: "offer.pdf"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.supplier == 'demo')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('components.embossing-footer.demoInfo')), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.workflowModel.active == 1)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: _normalizeClass(["btn btn-secondary", _ctx.workflowModel.isPrevStepPossible() ? '' : 'disabled']),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.workflowModel.changeActiveStep(-1)))
          }, _toDisplayString('< ' + _ctx.$t('components.embossing-footer.backButtonMenu')), 3))
        : _createCommentVNode("", true),
      (!_ctx.productName?.toLowerCase()?.includes('s-cover') && !(_ctx.workflowModel.active == 1))
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            class: _normalizeClass(["btn btn-secondary", _ctx.workflowModel.isPrevStepPossible() ? '' : 'disabled']),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.workflowModel.changeActiveStep(-1)))
          }, _toDisplayString('< ' + _ctx.$t('components.embossing-footer.backButton')), 3))
        : _createCommentVNode("", true),
      (!_ctx.productName?.toLowerCase()?.includes('s-cover'))
        ? _withDirectives((_openBlock(), _createBlock(_component_el_select, {
            key: 2,
            modelValue: _ctx.workflowModel.active,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.workflowModel.active) = $event)),
            class: "btn btn-secondary"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.workflowModel.Progress).slice(0, 4), (step) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: step,
                  value: Object.values(_ctx.workflowModel.Progress).indexOf(step),
                  label: _ctx.$t(`enum.${_ctx.workflowModel.localeIdentifier}.${step}`)
                }, null, 8, ["value", "label"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])), [
            [_vShow, !_ctx.workflowModel.isLastStep()]
          ])
        : _createCommentVNode("", true),
      (_ctx.productName?.toLowerCase()?.includes('s-cover'))
        ? (_openBlock(), _createElementBlock("button", {
            key: 3,
            type: "button",
            class: _normalizeClass(["btn btn-secondary", _ctx.workflowModel.isPrevStepPossible() ? '' : 'disabled']),
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleBackButton && _ctx.handleBackButton(...args)))
          }, _toDisplayString('< ' + _ctx.$t('components.embossing-footer.backButton')), 3))
        : _createCommentVNode("", true),
      (!_ctx.authentication.user?.email.toLowerCase().includes('teufel-international') && _ctx.workflowModel.isLastStep() && (_ctx.authentication.roles?.includes(_ctx.Role.Reseller) || _ctx.authentication.roles?.includes(_ctx.Role.Supplier) && !_ctx.authentication.domains.some(d => d.urlSuffix == 'wjtortho')))
        ? _withDirectives((_openBlock(), _createElementBlock("button", {
            key: 4,
            type: "button",
            class: "btn btn-secondary",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.createPartListPdf())),
            "element-loading-background": "rgba(122, 122, 122, 0)",
            disabled: _ctx.loadingPartListPdf
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('components.order-summary.downloadPartListPdfButton')), 1)
          ], 8, _hoisted_4)), [
            [_directive_loading, _ctx.loadingPartListPdf]
          ])
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("a", _hoisted_5, null, 512), [
        [_vShow, false]
      ]),
      (_ctx.workflowModel.isLastStep() && _ctx.authentication.user !== null)
        ? _withDirectives((_openBlock(), _createElementBlock("button", {
            key: 5,
            type: "button",
            class: "btn btn-secondary",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('confirmOfferPdf'))),
            "element-loading-background": "rgba(122, 122, 122, 0)",
            disabled: _ctx.loadingOfferPdf
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('components.order-summary.downloadOfferPdfButton')), 1)
          ], 8, _hoisted_6)), [
            [_directive_loading, _ctx.loadingOfferPdf]
          ])
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("a", _hoisted_7, null, 512), [
        [_vShow, false]
      ]),
      (_ctx.workflowModel.IsForm)
        ? (_openBlock(), _createElementBlock("button", {
            key: 6,
            type: "submit",
            "native-type": "submit",
            form: "order-form",
            class: _normalizeClass(["btn btn-primary", _ctx.workflowModel.isNextStepPossible() ? '' : 'disabled'])
          }, _toDisplayString(_ctx.$t('components.embossing-footer.continueButtonForm') + ' >'), 3))
        : _createCommentVNode("", true),
      (!_ctx.workflowModel.isLastStep() && !_ctx.workflowModel.IsForm)
        ? (_openBlock(), _createElementBlock("button", {
            key: 7,
            type: "button",
            class: _normalizeClass(["btn btn-primary", _ctx.workflowModel.isNextStepPossible() && _ctx.supplier != 'demo' ? '' : 'disabled btn-secondary']),
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.workflowModel.OnClickNextAction(1)))
          }, _toDisplayString(_ctx.$t('components.embossing-footer.continueButton') + ' >'), 3))
        : _createCommentVNode("", true),
      (_ctx.workflowModel.isLastStep() && (_ctx.authentication.user !== null || !_ctx.productName.toLocaleLowerCase().includes('cover')))
        ? (_openBlock(), _createElementBlock("button", {
            key: 8,
            type: "button",
            class: _normalizeClass(["btn btn-primary", _ctx.workflowModel.isNextStepPossible() && _ctx.supplier != 'demo' ? '' : 'disabled btn-secondary']),
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.workflowModel.OnClickNextAction(1)))
          }, _toDisplayString(_ctx.$t('components.order-summary.orderButton')), 3))
        : _createCommentVNode("", true)
    ])
  ], 64))
}