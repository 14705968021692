<template>
  <div
    class="upload-item-obj"
    :class="{ selected: isSelected && canSelect }"
    @click="
      () => {
        if (!canRemove) selectItem();
      }
    "
  >
    <Renderer
      v-if="isActive && !isEmpty"
      ref="troisRenderer"
      antialias
      :width="size"
      :height="size"
      :alpha="true"
    >
      <Camera />
      <Scene>
        <AmbientLight color="#808080" />
        <PointLight />
        <FbxModel
          v-for="mesh in getMeshesOfType(FileType.FBX)"
          :key="mesh.filename"
          :src="mesh.url"
        />
        <ObjModel
          v-for="mesh in getMeshesOfType(FileType.OBJ)"
          :key="mesh.filename"
          :srcType="mesh.url ? ModelSrcType.URL : ModelSrcType.BASE64"
          :src="mesh.url ? mesh.url : mesh.base64"
        />
        <StlModel
          v-for="mesh in getMeshesOfType(FileType.STL)"
          :key="mesh.filename"
          :srcType="mesh.url ? ModelSrcType.URL : ModelSrcType.BASE64"
          :src="mesh.url ? mesh.url : mesh.base64"
        />
      </Scene>
    </Renderer>
    <div v-else class="noFreeWebGlContext">
      <div>
        <font-awesome-icon icon="file" />
      </div>
    </div>
    <span v-if="canRemove" class="el-upload-list__item-actions">
      <span
        v-if="canSelect"
        class="el-upload-list__item-preview"
        @click="selectItem"
      >
        <el-icon>
          <font-awesome-icon icon="magnifying-glass" />
        </el-icon>
      </span>
      <span
        v-if="canRemove"
        class="el-upload-list__item-delete"
        @click="removeItem"
      >
        <el-icon>
          <font-awesome-icon icon="trash" />
        </el-icon>
      </span>
    </span>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { RendererPublicInterface } from 'troisjs';
import { Prop, Watch } from 'vue-property-decorator';
import * as THREECamera from '@/utils/three/camera';
import * as THREE from 'three';
import { ModelSrcType, ObjModel, StlModel } from '@/utils/three/importExport';
import { MeshImportData } from '@/types/ui/MeshTreeData';
import { FileType3D } from '@/types/enum/upload';
import { v4 as uuidv4 } from 'uuid';
import {
  addWebGlContext,
  deleteWebGlContext,
  waitFor,
} from '@/utils/three/webGlContext';

@Options({
  components: {
    ObjModel,
    StlModel,
  },
  emits: ['selectItem', 'removeItem'],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class PreviewRenderer extends Vue {
  @Prop() meshes!: MeshImportData[];
  @Prop({ default: false }) isSelected!: boolean;
  @Prop({ default: '148' }) size!: string;
  @Prop({ default: 1.2 }) offset!: number;
  @Prop({ default: true }) canSelect!: boolean;
  @Prop({ default: false }) canRemove!: boolean;
  uuid = uuidv4();
  isActive = false;

  //renderer
  troisRenderer!: RendererPublicInterface;

  FileType = FileType3D;
  ModelSrcType = ModelSrcType;

  mounted(): void {
    waitFor(() => addWebGlContext(this.uuid)).then(
      () => (this.isActive = true)
    );
  }

  unmounted(): void {
    if (this.troisRenderer) {
      this.troisRenderer.renderer.dispose();
      this.troisRenderer.renderer.forceContextLoss();
    }
    deleteWebGlContext(this.uuid);
  }

  setTroisRenderer(): void {
    if (this.$refs.troisRenderer) {
      this.troisRenderer = this.$refs.troisRenderer as RendererPublicInterface;
    }
  }

  @Watch('meshes', { immediate: true })
  onMeshesChanged(): void {
    waitFor(() => this.isActive).then(() => {
      setTimeout(() => {
        this.setTroisRenderer();
        if (this.troisRenderer && this.troisRenderer.scene) {
          THREECamera.fitCameraToSelection(
            this.troisRenderer.camera as THREE.PerspectiveCamera,
            this.getSceneObjects(),
            this.offset
          );
        }
      }, 500);
    });
  }

  getMeshesOfType(filetype: FileType3D): MeshImportData[] {
    return this.meshes.filter(
      (item) =>
        item.filename && item.filetype === filetype && (item.url || item.base64)
    );
  }

  get isEmpty(): boolean {
    return (
      this.meshes.filter((item) => item.filename && (item.url || item.base64))
        .length === 0
    );
  }

  selectItem(): void {
    if (this.canSelect) this.$emit('selectItem', this.meshes);
  }

  getSceneObjects(): THREE.Object3D[] {
    if (this.troisRenderer && this.troisRenderer.scene) {
      return this.troisRenderer.scene.children;
    }
    return [];
  }

  removeItem(): void {
    this.meshes;
    this.$emit('removeItem');
  }
}
</script>

<style lang="scss" scoped>
.upload-item-obj {
  overflow: hidden;
  background-color: var(--color-sidebar);
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  margin: 0 8px 8px 0;
  //display: inline-block;
  cursor: pointer;
}

.selected {
  border: var(--color-primary) 2px solid;
}

.noFreeWebGlContext {
  display: flex;
  width: 100%;
  height: 100%;

  > div {
    text-align: center;
    color: var(--color-primary);
    font-size: 2rem;
    vertical-align: middle;
    align-items: center;
    margin: auto;
  }
}
</style>
