<template>
  <el-footer class="level">
    <span class="level-left">
      <el-button
        type="primary"
        native-type="submit"
        @click="workflowModel.changeActiveStep(-1)"
        :disabled="!workflowModel.isPrevStepPossible()"
      >
        <font-awesome-icon icon="circle-chevron-left" />
        &nbsp;
        {{ isSmallWindowSize ? '' : $t('views.customize.workflow.prevStep') }}
      </el-button>
    </span>
    <el-steps
      :active="workflowModel.active"
      finish-status="success"
      align-center
      class="level-item"
    >
      <el-step
        v-for="step in Object.values(workflowModel.Progress)"
        :key="step"
        :title="
          isSmallWindowSize
            ? ''
            : $t(`enum.${workflowModel.localeIdentifier}.${step}`)
        "
      />
    </el-steps>
    <span class="level-right">
      <el-button
        v-if="workflowModel.IsForm"
        type="primary"
        native-type="submit"
        form="orderForm"
        :disabled="!workflowModel.isNextStepPossible()"
        v-loading.fullscreen.lock="fullscreenLoading"
      >
        {{
          isSmallWindowSize
            ? ''
            : workflowModel.isLastStep()
            ? $t('views.customize.workflow.lastStep')
            : $t('views.customize.workflow.nextStep')
        }}
        &nbsp;
        <font-awesome-icon icon="circle-chevron-right" />
      </el-button>
      <el-button
        v-else
        type="primary"
        native-type="submit"
        @click="workflowModel.OnClickNextAction(1)"
        :disabled="!workflowModel.isNextStepPossible()"
        v-loading.fullscreen.lock="fullscreenLoading"
      >
        {{
          isSmallWindowSize
            ? ''
            : workflowModel.isLastStep()
            ? $t('views.customize.workflow.lastStep')
            : $t('views.customize.workflow.nextStep')
        }}
        &nbsp;
        <font-awesome-icon icon="circle-chevron-right" />
      </el-button>
    </span>
    <span class="totalPrice" wrap>
      <span>€</span>
      <span>{{ workflowModel.totalPrice.toFixed(2) }}</span>
    </span>
  </el-footer>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WorkflowModel } from '@/types/ui/WorkflowModel';

export default class Workflow extends Vue {
  @Prop({ default: null }) workflowModel!: WorkflowModel;
  @Prop({ default: false }) fullscreenLoading!: boolean;
  @Prop({ default: 0 }) isSmallWindowSize!: boolean;
}
</script>

<style lang="scss" scoped>

.totalPrice {
  color: var(--color-primary);
  font-size: var(--font-size-xlarge);
  font-weight: var(--font-weight-bold);
  padding-left: 3rem;
  display: flex;
  span {
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .totalPrice {
    font-size: var(--font-size-large);
    padding-left: 0.2rem;
    span {
      margin-right: 0.2rem;
    }
  }
}

</style>
