<template>
  <div v-if="!readonly">
    <!-- Selects -->
    <div class="row">
      <div class="col mb-3">
        <label for="body-side" class="form-label">{{ $t('views.customize.workflow.form.bodySide') }}</label>
        <select v-model="properties[0].bodySide" class="form-select" id="body-side">
          <option 
            v-for="side in Object.values(BodySide)"
            :key="side"
            :value="side"
          >
            {{ $t(`enum.bodySide.${side}`) }}
          </option>
        </select>
      </div>
      <div class="col mb-3">
        <label for="knee-joint" class="form-label">{{ $t('views.customize.workflow.form.kneeJoint') }}</label>
        <input type="text" class="form-control" id="knee-joint" v-model="properties[0].kneeJoint">
      </div>
      <!-- <div class="col mb-3">
        <label for="foot" class="form-label">{{ $t('views.customize.workflow.form.foot') }}</label>
        <input type="text" class="form-control" id="foot" v-model="properties[0].foot">
      </div> -->
    </div>
    <div class="row">
      <div class="col mb-3" v-if="product?.name?.toLowerCase().includes('u-flex') || product?.name?.toLowerCase().includes('c-flex')">
        <label for="zip" class="form-label">{{ $t('views.customize.workflow.form.zip') }}</label> <br />
        <el-radio-group v-model="properties[0].zip">
          <el-radio-button
            v-for="zip in Object.values(ZipType)"
            :key="zip"
            :label="zip"
            id="zip"
            @change="$emit('zipChanged', zip)"
          >
            {{ $t(`enum.zipType.${zip}`) }}
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="row">
      <div class="col mb-3">
        <label for="automatic-fit" class="form-label">{{ $t('views.customize.workflow.form.automaticFit') }}</label>
        <br />
        <el-switch
          id="automatic-fit"
          v-model="automaticFit"
          @change="automaticFitChanged()"
          :disabled="product.type.name.toLowerCase() == 'covers'"
        />
      </div>
    </div>
    <!-- Progress bars -->
    <div v-if="automaticFit">
      <div class="row">
        <div class="col">
          <label for="width-range" class="form-label mb-0">{{ $t('components.order-form.height') }}</label>
          <el-slider
            v-model="properties[0].heightWithoutDeduction"
            show-input
            :precision="0"
            :min="100"
            :max="460"
            :step="1"
            :show-tooltip="false"
            id="width-range"
            @change="$emit('sizeChanged')"
            :disabled="readonly"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="scope-range" class="form-label mb-0">{{ $t('components.order-form.length') }}</label>
          <el-slider
            v-model="properties[0].depth"
            show-input
            :precision="0"
            :min="100"
            :max="300"
            :step="1"
            :show-tooltip="false"
            id="scope-range"
            @change="$emit('sizeChanged')"
            :disabled="readonly"
          />
        </div>
      </div>
      <div class="row">
        <div class="col mb-3" v-loading="bodyAttributes.length <= 0 && authenticator.user" element-loading-background="rgba(122, 122, 122, 0)">
          <label for="scan" class="form-label">{{ $t('views.customize.workflow.form.scanFiles') }}</label>
          <select v-model="selectedBodyAttribute" class="form-select" id="scan"  @change="$emit('scanFileChanged', selectedBodyAttribute)">
            <option :value="null" :label="$t(`components.product-parameter.scan.none`)"/>
            <option
              v-for="bodyAttribute in bodyAttributes"
              :key="bodyAttribute.characteristicId"
              :value="bodyAttribute"
              :label="$t(`components.product-parameter.scan.${bodyAttribute.bodyPart?.name}`)  + ' - ' + bodyAttribute.scanFile?.name"
            />
          </select>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col">
          <label for="width-range" class="form-label mb-0">{{ $t('components.order-form.scope-top') }}</label>
          <el-slider
            v-model="properties[0].depth"
            show-input
            :precision="0"
            :min="150"
            :max="450"
            :step="1"
            :show-tooltip="false"
            id="width-range"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="height-range" class="form-label mb-0">{{ $t('components.order-form.height') }}</label>
          <el-slider
            v-model="properties[0].heightWithoutDeduction"
            show-input
            :precision="0"
            :min="200"
            :max="460"
            :step="1"
            :show-tooltip="false"
            if="height-range"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="scope-range" class="form-label mb-0">{{ $t('components.order-form.scope-bottom') }}</label>
          <el-slider
            v-model="properties[0].calfCircumferenceWithoutDeduction"
            show-input
            :precision="0"
            :min="100"
            :max="300"
            :step="1"
            :show-tooltip="false"
            id="scope-range"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h3 class="mt-3">{{ $t('components.order-summary.parameterTitle') }}</h3>
    <div class="row border-0">
      <div class="col mb-2">
        <div class="row">
          <label class="col form-label text-secondary mb-1" for="body-side-readonly">{{ $t('views.customize.workflow.form.bodySide') }}</label>
          <label class="col form-label mb-1">{{ properties[0]?.bodySide }}</label>
        </div>
      </div>
      <div class="col mb-2">
        <div class="row">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.kneeJoint') }}</label>
          <label class="col form-label mb-1">{{ properties[0]?.kneeJoint }}</label>
        </div>
      </div>
      <div class="col mb-2">
        <div class="row">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.foot') }}</label>
          <label class="col form-label mb-1">{{ properties[0]?.foot }}</label>
        </div>
      </div>
      <div class="col mb-2" v-if="product?.name?.toLowerCase().includes('u-flex') || product?.name?.toLowerCase().includes('c-flex')">
        <div class="row">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.zip') }}</label>
          <label class="col form-label mb-1">{{ properties[0]?.zip }}</label>
        </div>
      </div>
      <div class="row border-0" v-if="!automaticFit">
        <div class="col mb-2">
          <div class="row">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.scope-top') }}</label>
            <label class="col form-label mb-1">{{ properties[0]?.depth }}</label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="row">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.height') }}</label>
            <label class="col form-label mb-1">{{ properties[0]?.heightWithoutDeduction }}</label>
          </div>
        </div>
        <div class="col mb-2">
          <div class="row">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.scope-bottom') }}</label>
            <label class="col form-label mb-1">{{ properties[0]?.calfCircumferenceWithoutDeduction }}</label>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="col mb-2">
          <div class="row">
            <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.scanFiles') }}</label>
            <label class="col form-label mb-1">{{ selectedBodyAttribute }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { BodySide, ZipType } from "@/types/enum/order";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import * as CustomerService from "@/services/api/customerService";
import Auth from "@/services/api/auth";
import { Customer } from "@/types/api/User/Customer";
import { Body_Attribute } from "@/types/api/Patient/BodyAttribute";
import { File } from "@/types/api/Utility/File";
import { Product } from "@/types/api/Model/Product";

@Options({
  components: {},
  emits: ['scanFileChanged', 'zipChanged'],
})

export default class ProductParamterCover extends Vue {
  @Prop() properties!: any[];
  @Prop() product!: Product;
  @Prop({ default: false }) readonly!: boolean; 

  authenticator = Auth.getInstance();
  bodyAttributes: Body_Attribute[] = [];

  BodySide = BodySide;
  ZipType = ZipType;
  selectedBodyAttribute: Body_Attribute | null = null;

  automaticFit = false;


  mounted(): void {
    /*this.properties[0].heightWithoutDeduction = 420;
    this.properties[0].depth = 180;*/

    if (this.authenticator.user) {
      this.automaticFit = true;
      CustomerService.getBodyAttributesByUserId(this.authenticator.user.userId)
        .then((bodyAttributes) => {
          this.bodyAttributes = bodyAttributes;
        })
    }
  }

  automaticFitChanged(): void {
    if (!this.automaticFit) this.selectedBodyAttribute = null; 
  }
}
</script>

<style scoped lang="scss">
.text-secondary {
  color: #0000008D !important;
}
.form-label {
  width: 20rem;
}
</style>