import * as THREE from 'three';
import { isSpecialObject, ObjectType } from '@/types/enum/three';
import { v4 as uuidv4 } from 'uuid';
import {
  FileType3D,
  getFileType,
  UploadCategory,
  FileTypeImage,
} from '@/types/enum/upload';
import { DefaultUndoRedo, HistoryList } from '@/types/ui/HistoryList';
import * as componentService from '@/services/api/componentService';
import { Color } from '../api/Model/Attributes/Color';
import { Engraving } from '../api/Model/Attributes/Engraving';
import { Print } from '../api/Model/Attributes/Print';
import { waitFor } from '@/utils/three/webGlContext';
import { Size } from '../api/Model/Attributes/Size';
import { FileTexture } from '../api/Utility/FileTexture';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export interface MeshImportData {
  filetype: FileType3D | null;
  filename: string | null;
  url: string | null;
  base64: string | null;
  thumbnail: string | null;
  thumbnailUrl: string | null;
  thumbnailFiletype: FileTypeImage | null;
}

export interface MeshTreeDataGroupItem {
  meshTreeDataItem: MeshTreeDataItem
  cnt: number
}

export class MeshTreeDataItem implements MeshImportData {
  id: string | null = null;
  componentId: number | null = null;
  name = '';
  type: ObjectType;
  geometry!: THREE.BufferGeometry;
  material!: THREE.Material | THREE.Material[];
  children: MeshTreeDataItem[] = [];
  activeChildren: MeshTreeDataItem[] = [];
  defaultPosition: THREE.Vector3;
  defaultRotation: THREE.Euler;
  defaultScale: THREE.Vector3;
  scene!: THREE.Scene;
  mesh!: THREE.Object3D;
  private isActive = true;
  isSelected = false;
  showWireframe = false;
  opacity = 100;
  syncData = false;
  readonly uuid = uuidv4();
  filename: string | null = null;
  url: string | null = null;
  filetype: FileType3D | null = null;
  thumbnailFiletype: FileTypeImage | null = null;
  thumbnail: string | null = null;
  thumbnailUrl: string | null = null;
  base64: string | null = null;
  color: string | null = null;
  embossingColor: string | null = null;
  embossing: string | null = null;
  embossingId: string | null = null;
  engravingId: number | null = null;
  embossingThumbnailUrl: string | null = null;
  embossingUrl: string | null = null;
  wtransfer: string | null = null;
  wtransferUrl: string | null = null;
  wtransferId: string | null = null;
  textureId: number | null = null;
  bones: THREE.Bone[] = [];
  activeBoneIndex = -1;
  skeleton: THREE.Skeleton | null = null;
  lastUpdateDB: number = Date.now();
  camera!: THREE.Camera;
  colors: Color | null = null;
  engraving: Engraving | null = null;
  texture: Print | null = null;
  size: Size |undefined;
  basePrice = 0;
  supportedValues: FileTexture[] |null=null
  //#region init
  constructor(
    id: string | null,
    name: string,
    componentId: number | null = null
  ) {
    this.id = id;
    this.componentId = componentId;
    this.name = name;
    this.type = ObjectType.None;
    this.defaultPosition = new THREE.Vector3(0, 0, 0);
    this.defaultRotation = new THREE.Euler(0, 0, 0);
    this.defaultScale = new THREE.Vector3(1, 1, 1);
  }

  createSkeleton(): void {
    const skeletonBones: THREE.Bone[] = this.bones.map((bone) => bone.clone());
    for (let boneIndex = 0; boneIndex < skeletonBones.length; boneIndex++) {
      const bone = skeletonBones[boneIndex];
      const originalBone = this.bones[boneIndex];
      bone.clear();
      if (boneIndex < skeletonBones.length - 1) {
        const childBone = skeletonBones[boneIndex + 1];
        childBone.position.sub(originalBone.position);
        bone.add(childBone);
      }
    }
    this.skeleton = new THREE.Skeleton(skeletonBones);
  }

  initMesh(
    geometry: THREE.BufferGeometry,
    material: THREE.Material | THREE.Material[]
  ): void {
    this.type = ObjectType.Mesh;
    this.geometry = geometry;
    this.material = material;
  }

  initImport(
    filename: string,
    url: string,
    thumbnail: string,
    thumbnailUrl: string,
    color: string | null = null
  ): void {
    this.type = ObjectType.Import;
    this.filename = filename;
    this.filetype = getFileType(filename) as FileType3D;
    this.url = url;
    this.thumbnail = thumbnail;
    this.thumbnailUrl = thumbnailUrl;
    this.color = color;
  }

  initBase64Import(
    filetype: FileType3D,
    base64: string,
    color: string | null = null
  ): void {
    this.type = ObjectType.Import;
    this.filetype = filetype;
    this.base64 = base64;
    this.color = color;
  }

  initThumbnailImport(thumbnailType: FileTypeImage, base64: string): void {
    this.thumbnailFiletype = thumbnailType;
    this.thumbnailUrl = base64;
  }

  initLine(
    geometry: THREE.BufferGeometry,
    material: THREE.Material | THREE.Material[]
  ): void {
    this.type = ObjectType.Line;
    this.geometry = geometry;
    this.material = material;
  }

  initGroup(children: MeshTreeDataItem[]): void {
    this.type = ObjectType.Group;
    this.children = children;
  }

  initCamera(camera: THREE.Camera): void {
    this.type = ObjectType.Camera;
    this.camera = camera;
  }
  //#endregion init

  //#region is
  get isNone(): boolean {
    return this.type === ObjectType.None;
  }

  get isMesh(): boolean {
    return this.type === ObjectType.Mesh;
  }

  get isLine(): boolean {
    return this.type === ObjectType.Line;
  }

  get isGroup(): boolean {
    return this.type === ObjectType.Group;
  }

  get isCamera(): boolean {
    return this.type === ObjectType.Camera;
  }

  get isImport(): boolean {
    return this.type === ObjectType.Import;
  }

  isSpecialObject(): boolean {
    return isSpecialObject(this.name);
  }
  //#endregion is

  //#region properties
  get active(): boolean {
    return this.isActive;
  }

  set active(value: boolean) {
    this.isActive = value;
    this.visible = value;
    const setChildrenVisibility = (item: MeshTreeDataItem): void => {
      for (const child of item.children) {
        child.visible = value;
        setChildrenVisibility(child);
      }
    };
    setChildrenVisibility(this);
  }

  get visible(): boolean {
    if (this.mesh) return this.mesh.visible;
    return true;
  }

  set visible(value: boolean) {
    if (this.mesh) this.mesh.visible = value;
  }

  get position(): THREE.Vector3 {
    if (this.mesh) return this.mesh.position;
    return this.defaultPosition;
  }

  set position(value: THREE.Vector3) {
    if (this.mesh) this.mesh.position.set(value.x, value.y, value.z);
    else this.defaultPosition.set(value.x, value.y, value.z);
  }

  get rotation(): THREE.Euler {
    if (this.mesh) return this.mesh.rotation;
    return this.defaultRotation;
  }

  set rotation(value: THREE.Euler) {
    if (this.mesh) this.mesh.rotation.set(value.x, value.y, value.z);
    else this.defaultRotation.set(value.x, value.y, value.z);
  }

  get scale(): THREE.Vector3 {
    if (this.mesh) return this.mesh.scale;
    return this.defaultScale;
  }

  set scale(value: THREE.Vector3) {
    if (this.mesh) this.mesh.scale.set(value.x, value.y, value.z);
    else this.defaultScale.set(value.x, value.y, value.z);
  }

  getActiveChildren(): MeshTreeDataItem[] {
    return this.children.filter((item) => item.active);
  }

  updateActiveChildren(): void {
    this.activeChildren = this.getActiveChildren();
  }
  //#endregion properties

  //#region update
  public updateDB(): void {
    this.lastUpdateDB = Date.now();
  }
  //#endregion update
}

export class MeshTreeData {
  modelId: number | null = null;
  treeData: MeshTreeDataItem[] = [];
  historyList: HistoryList;
  lastUpdateStructure: number = Date.now();
  lastUpdateVisibility: number = Date.now();
  lastUpdateDB: number = Date.now();
  thumbnail: string | null = null;
  thumbnailUrl: string | null = null;
  thumbnailFiletype: FileTypeImage | null = null;

  constructor(modelId: number | null, defaultUndoRedo: DefaultUndoRedo) {
    this.modelId = modelId;
    this.historyList = new HistoryList(modelId, defaultUndoRedo);
  }

  //#region update
  public updateStructure(): void {
    this.lastUpdateStructure = Date.now();
    this.lastUpdateDB = Date.now();
  }

  public updateVisibility(): void {
    this.lastUpdateVisibility = Date.now();
  }

  public updateDB(meshId: string): void {
    this.lastUpdateDB = Date.now();
    const treeItem = this.getItemByUuid(meshId);
    if (treeItem) treeItem.updateDB();
  }
  //#endregion update

  //#region add
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  private addItem(
    id: string | null,
    componentId: number | null,
    name: string,
    position: THREE.Vector3,
    rotation: THREE.Euler,
    scale: THREE.Vector3,
    parent: MeshTreeDataItem | string | null = null,
    isSelected = false
  ): MeshTreeDataItem {
    const item = new MeshTreeDataItem(id, name, componentId);
    item.isSelected = isSelected;
    if (typeof parent === 'string') {
      let parentItem = this.getItemByName(parent);
      if (!parentItem) {
        parentItem = new MeshTreeDataItem(null, parent);
        this.treeData.push(parentItem);
      }
      parentItem.children.push(item);
      parentItem.updateActiveChildren();
    } else if (parent) {
      parent.children.push(item);
      parent.updateActiveChildren();
    } else {
      this.treeData.push(item);
    }

    item.position = position;
    item.rotation = rotation;
    item.scale = scale;
    return item;
  }

  addCategory(
    name: string,
    parent: MeshTreeDataItem | string | null = null
  ): MeshTreeDataItem {
    const item = new MeshTreeDataItem(null, name);
    if (typeof parent === 'string') {
      let parentItem = this.getItemByName(parent);
      if (!parentItem) {
        parentItem = new MeshTreeDataItem(null, parent);
        this.treeData.push(parentItem);
      }
      parentItem.children.push(item);
      parentItem.updateActiveChildren();
    } else if (parent) {
      parent.children.push(item);
      parent.updateActiveChildren();
    } else {
      this.treeData.push(item);
    }
    return item;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  addImport(
    name: string,
    filename: string,
    url: string,
    thumbnail: string,
    thumbnailUrl: string,
    parent: MeshTreeDataItem | string | null = null,
    selectNewItem = false,
    color: string | null = null,
    position: THREE.Vector3 = new THREE.Vector3()
  ): MeshTreeDataItem {
    if (selectNewItem) this.deselectTree(this.treeData);
    const item = this.addItem(
      uuidv4(),
      null,
      name,
      position,
      new THREE.Euler(),
      new THREE.Vector3(1, 1, 1),
      parent,
      selectNewItem
    );
    item.initImport(filename, url, thumbnail, thumbnailUrl, color);
    this.updateStructure();
    if (selectNewItem) this.selectItem(item, false, true);
    return item;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  addBase64Import(
    id: string | null,
    componentId: number | null,
    name: string,
    filetype: FileType3D,
    base64: string,
    thumbnailType: FileTypeImage,
    thumbnail: string,
    parent: MeshTreeDataItem | string | null = null,
    selectNewItem = false,
    color: string | null = null,
    position: THREE.Vector3 = new THREE.Vector3(),
    rotation: THREE.Euler = new THREE.Euler(),
    scale: THREE.Vector3 = new THREE.Vector3(1, 1, 1)
  ): MeshTreeDataItem {
    if (selectNewItem) this.deselectTree(this.treeData);
    const item = this.addItem(
      id,
      componentId,
      name,
      position,
      rotation,
      scale,
      parent,
      selectNewItem
    );
    item.initBase64Import(filetype, base64, color);
    item.initThumbnailImport(thumbnailType, thumbnail);
    this.updateStructure();
    if (selectNewItem) this.selectItem(item, false, true);
    return item;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  addObject3D(
    componentId: number | null,
    name: string,
    mesh: THREE.Object3D,
    parent: MeshTreeDataItem | string | null = null,
    selectNewItem = false
  ): MeshTreeDataItem {
    if (selectNewItem) this.deselectTree(this.treeData);
    const item = this.addItem(
      uuidv4(),
      componentId,
      name,
      mesh.position,
      mesh.rotation,
      mesh.scale,
      parent,
      selectNewItem
    );
    if ((mesh as any).isMesh) {
      item.type = ObjectType.Mesh;
      item.geometry = (mesh as THREE.Mesh).geometry;
      item.material = (mesh as THREE.Mesh).material;
    } else if ((mesh as any).isLine) {
      item.type = ObjectType.Line;
      item.geometry = (mesh as THREE.Mesh).geometry;
      item.material = (mesh as THREE.Mesh).material;
    } else if ((mesh as any).isGroup) {
      item.type = ObjectType.Group;
    }
    this.updateStructure();
    if (selectNewItem) this.selectItem(item, false, true);
    return item;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  addObject(
    name: string,
    type: ObjectType,
    geometry: THREE.BufferGeometry,
    material: THREE.Material | THREE.Material[],
    position: THREE.Vector3,
    rotation: THREE.Euler,
    scale: THREE.Vector3,
    parent: MeshTreeDataItem | string | null = null,
    selectNewItem = false
  ): MeshTreeDataItem {
    if (selectNewItem) this.deselectTree(this.treeData);
    const item = this.addItem(
      uuidv4(),
      null,
      name,
      position,
      rotation,
      scale,
      parent,
      selectNewItem
    );
    if (type === ObjectType.Mesh) {
      item.initMesh(geometry, material);
    } else if (type === ObjectType.Line) {
      item.initLine(geometry, material);
    }
    this.updateStructure();
    if (selectNewItem) this.selectItem(item, false, true);
    return item;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  addCamera(
    name: string,
    camera: THREE.Camera,
    position: THREE.Vector3,
    rotation: THREE.Euler,
    parent: MeshTreeDataItem | string | null = null,
    selectNewItem = false
  ): MeshTreeDataItem {
    if (selectNewItem) this.deselectTree(this.treeData);
    const item = this.addItem(
      uuidv4(),
      null,
      name,
      position,
      rotation,
      new THREE.Vector3(1, 1, 1),
      parent,
      selectNewItem
    );
    item.initCamera(camera);
    this.updateStructure();
    if (selectNewItem) this.selectItem(item, false, true);
    return item;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  addGroup(
    name: string,
    children: MeshTreeDataItem[],
    position: THREE.Vector3,
    rotation: THREE.Euler,
    scale: THREE.Vector3,
    parent: MeshTreeDataItem | string | null = null,
    selectNewItem = false
  ): MeshTreeDataItem {
    if (selectNewItem) this.deselectTree(this.treeData);
    const item = this.addItem(
      uuidv4(),
      null,
      name,
      position,
      rotation,
      scale,
      parent,
      selectNewItem
    );
    item.initGroup(children);
    this.updateStructure();
    if (selectNewItem) this.selectItem(item, false, true);
    return item;
  }
  //#endregion add

  //#region select
  private async deselectTree(items: MeshTreeDataItem[]): Promise<void> {
    items.forEach((item) => {
      if (item.children) this.deselectTree(item.children);
      item.isSelected = false;
    });
  }

  async selectItem(
    item: MeshTreeDataItem | null,
    deselectPrevious = true,
    triggerUpdate = false
  ): Promise<void> {
    if (deselectPrevious) await this.deselectTree(this.treeData);
    if (item && item.type !== ObjectType.None) {
      item.isSelected = true;
      item.syncData = true;
      for (const child of item.children) {
        await this.selectItem(child, false);
      }
      if (triggerUpdate) this.updateVisibility();
    }
  }

  getSelection(): MeshTreeDataItem[] {
    const getSelectionList = (list: MeshTreeDataItem[]): MeshTreeDataItem[] => {
      const selection = list.filter((item) => item.isSelected);
      for (const item of list) {
        if (item.children.length > 0)
          selection.push(...getSelectionList(item.children));
      }
      return selection;
    };

    return getSelectionList(this.treeData);
  }
  //#endregion select

  //#region get
  getActiveTreeData(): MeshTreeDataItem[] {
    return this.treeData.filter((item) => item.active);
  }

  getParent(
    item: MeshTreeDataItem,
    searchParent: MeshTreeDataItem | null = null
  ): MeshTreeDataItem | null {
    const list = searchParent ? searchParent.children : this.treeData;
    for (const treeItem of list) {
      if (treeItem.uuid === item.uuid) return searchParent;
      else {
        const parent = this.getParent(item, treeItem);
        if (parent) return parent;
      }
    }
    return null;
  }

  getParentActive(
    item: MeshTreeDataItem,
    searchParent: MeshTreeDataItem | null = null
  ): MeshTreeDataItem | null {
    const list = searchParent ? searchParent.activeChildren : this.treeData;
    for (const treeItem of list) {
      if (treeItem.uuid === item.uuid) return searchParent;
      else {
        const parent = this.getParentActive(item, treeItem);
        if (parent) return parent;
      }
    }
    return null;
  }

  getCategory(
    item: MeshTreeDataItem,
    $t: (key: string) => string
  ): UploadCategory | null {
    for (const category of this.treeData) {
      const parent = this.getParent(item, category);
      if (parent) {
        const categoryType = Object.values(UploadCategory).find(
          (categoryItem) =>
            category.name === $t(`enum.upload-category.${categoryItem}`)
        );
        if (categoryType) return categoryType;
      }
    }
    return null;
  }

  exists(uuid: string, searchParent: MeshTreeDataItem | null = null): boolean {
    const list = searchParent ? searchParent.children : this.treeData;
    for (const treeItem of list) {
      if (treeItem.uuid === uuid) return true;
      else {
        const parent = this.exists(uuid, treeItem);
        if (parent) return parent;
      }
    }
    return false;
  }

  getItemById(id: string): MeshTreeDataItem | null {
    return this.getChildItemById(id);
  }

  private getChildItemById(
    id: string,
    searchParent: MeshTreeDataItem | null = null
  ): MeshTreeDataItem | null {
    const list = searchParent ? searchParent.children : this.treeData;
    for (const treeItem of list) {
      if (treeItem.id === id) return treeItem;
      else {
        const parent = this.getChildItemById(id, treeItem);
        if (parent) return parent;
      }
    }
    return null;
  }

  getItemByUuid(uuid: string): MeshTreeDataItem | null {
    return this.getChildItemByUuid(uuid);
  }

  private getChildItemByUuid(
    uuid: string,
    searchParent: MeshTreeDataItem | null = null
  ): MeshTreeDataItem | null {
    const list = searchParent ? searchParent.children : this.treeData;
    for (const treeItem of list) {
      if (treeItem.uuid === uuid) return treeItem;
      else {
        const parent = this.getChildItemByUuid(uuid, treeItem);
        if (parent) return parent;
      }
    }
    return null;
  }

  getItemByName(
    name: string,
    searchItems: MeshTreeDataItem[] | null = null
  ): MeshTreeDataItem | null {
    const list = searchItems ? searchItems : this.treeData;
    const item = list.find((item) => item.name === name);
    if (item) {
      return item;
    }
    for (const treeItem of list) {
      const result = this.getItemByName(name, treeItem.children);
      if (result) {
        return result;
      }
    }
    return null;
  }
  //#endregion get

  //#region edit
  drag(meshId: string): {
    oldParent: MeshTreeDataItem | null;
    oldIndex: number;
    newParent: MeshTreeDataItem | null;
    newIndex: number;
  } {
    let oldParent: MeshTreeDataItem | null = null;
    let newParent: MeshTreeDataItem | null = null;
    let oldIndex = -1;
    let newIndex = -1;
    const treeItem = this.getItemByUuid(meshId);
    if (treeItem) {
      oldParent = this.getParent(treeItem);
      newParent = this.getParentActive(treeItem);
      if (oldParent) {
        oldIndex = oldParent.children.findIndex(
          (child) => child.uuid === meshId
        );
      }
      if (newParent) {
        newIndex = newParent.activeChildren.findIndex(
          (child) => child.uuid === meshId
        );
      }
      if (oldParent && newParent && oldParent !== newParent) {
        if (oldIndex > -1) oldParent.children.splice(oldIndex, 1);
        if (newIndex === -1) {
          newParent.children.push(treeItem);
          newIndex = newParent.children.length - 1;
        } else newParent.children.splice(newIndex, 0, treeItem);
      } else if (newParent) {
        newParent.children.splice(newIndex, 0, treeItem);
      }
    }
    this.updateDB(meshId);
    this.updateStructure();
    return {
      oldParent: oldParent,
      oldIndex: oldIndex,
      newParent: newParent,
      newIndex: newIndex,
    };
  }

  setParent(meshId: string, newParentId: string | null, index = -1): void {
    const treeItem = this.getItemByUuid(meshId);
    if (treeItem) {
      const oldParent = this.getParent(treeItem);
      if (oldParent) {
        const index = oldParent.children.findIndex(
          (child) => child.uuid === meshId
        );
        oldParent.children.splice(index, 1);
        oldParent.updateActiveChildren();
      }
      if (newParentId) {
        let newParent = this.getItemByUuid(newParentId);
        if (!newParent) {
          newParent = this.getItemByName(newParentId);
        }
        if (newParent) {
          if (index > -1) newParent.children.splice(index, 0, treeItem);
          else newParent.children.push(treeItem);
          newParent.updateActiveChildren();
        }
      }
    }
  }

  copy(
    item: MeshTreeDataItem,
    parent: MeshTreeDataItem | null = null,
    selectCopy = true
  ): MeshTreeDataItem | undefined {
    if (item.isMesh || item.isLine || item.isImport) {
      const geometry = item.geometry.clone();
      const materialList: THREE.Material[] = [];
      if (Array.isArray(item.material)) {
        item.material.forEach((mat) => materialList.push(mat.clone()));
      }
      const material = Array.isArray(item.material)
        ? materialList
        : item.material.clone();
      return this.addObject(
        `${item.name}-copy`,
        item.isImport ? ObjectType.Mesh : item.type,
        geometry,
        material,
        item.position,
        item.rotation,
        item.scale,
        parent ? parent : this.getParent(item),
        selectCopy
      );
    } else if (item.isGroup) {
      const group = this.addGroup(
        `${item.name}-copy`,
        [],
        item.position,
        item.rotation,
        item.scale,
        parent ? parent : this.getParent(item),
        selectCopy
      );
      item.children.forEach((child) => {
        this.copy(child, group, false);
      });
      return group;
    }
  }

  async removeItemTemp(item: MeshTreeDataItem): Promise<boolean> {
    const parent = this.getParent(item);
    if (parent) {
      const index = parent.children.findIndex(
        (child) => child.uuid === item.uuid
      );
      if (index > -1) {
        parent.children.splice(index, 1);
        parent.updateActiveChildren();
        if (parent.children.length === 0 && parent.isNone) {
          this.removeItemTemp(parent);
        }
      }
    } else {
      const index = this.treeData.findIndex(
        (child) => child.uuid === item.uuid
      );
      if (index > -1) {
        this.treeData.splice(index, 1);
      }
    }
    return true;
  }

  async removeItem(item: MeshTreeDataItem): Promise<boolean> {
    this.historyList.remove(item.uuid, null);
    const parent = this.getParent(item);
    if (parent) {
      const index = parent.children.findIndex(
        (child) => child.uuid === item.uuid
      );
      if (index > -1) {
        parent.children.splice(index, 1);
        parent.updateActiveChildren();
        if (parent.children.length === 0 && parent.isNone) {
          this.removeItem(parent);
        }
        this.updateStructure();
      }
    } else {
      const index = this.treeData.findIndex(
        (child) => child.uuid === item.uuid
      );
      if (index > -1) {
        this.treeData.splice(index, 1);
        this.updateStructure();
      }
    }
    if (item.componentId) {
      return await componentService.deleteComponent(item.componentId);
    }
    return true;
  }

  clear(): void {
    this.treeData.splice(0, this.treeData.length);
    this.treeData.length = 0;
    this.updateStructure();
  }

  getMeshList(): THREE.Object3D[] {
    const result: THREE.Object3D[] = [];
    const addTreeDataToList = (list: MeshTreeDataItem[]): void => {
      for (const item of list) {
        if (item.mesh) result.push(item.mesh);
        if (item.children.length > 0) addTreeDataToList(item.children);
      }
    };
    addTreeDataToList(this.treeData);
    return result;
  }

  async getPartItemList(onlyVisible = true): Promise<MeshTreeDataGroupItem[]> {
    await waitFor(() => this.treeData.length > 0);
    const meshCountItemList: MeshTreeDataGroupItem[] = [];
    for(const item of this.treeData.filter((t) => onlyVisible ? t.mesh?.visible : t)) {
      if (meshCountItemList.some(i => i.meshTreeDataItem?.name == item.name)) {
        const value = meshCountItemList.find(i => i.meshTreeDataItem?.name == item.name);
        if (value) {
          value.cnt += 1;
        }
      } else {
        meshCountItemList.push(
          {
            meshTreeDataItem: item, 
            cnt: 1
          }
        );
      }
    }

    return meshCountItemList;
  }

  async getGroupedPartItems(onlyVisible = true): Promise<{[key: string]: MeshTreeDataGroupItem[]}> {
    await waitFor(() => this.treeData.length > 0);
    const groupedKeys = this.treeData.filter((t) => onlyVisible ? t.mesh?.visible : t).reduce((group: {[key: string]: MeshTreeDataGroupItem[]}, item) => {
      if (!group[item.name]) {
       group[item.name] = [];
      }
      const i = group[item.name].find((x) => x.meshTreeDataItem?.size?.width == item.size?.width);
      if (!i)
        group[item.name].push({cnt: 1, meshTreeDataItem: item});
      else
        i.cnt++;
      return group;
     }, {});

     return groupedKeys;
  }
  //#endregion edit
}
