<template>
  <div v-if="!readonly">
    <!-- Selects -->
    <div class="row">
      <div class="col mb-3">
        <label for="body-side" class="form-label">{{ $t('views.customize.workflow.form.bodySide') }}</label>
        <select v-model="properties[0].bodySide" class="form-select" id="body-side">
          <option 
            v-for="side in Object.values(BodySide)"
            :key="side"
            :value="side"
          >
            {{ $t(`enum.bodySide.${side}`) }}
          </option>
        </select>
      </div>

      <div class="col mb-3" v-if="product?.name?.toLowerCase().includes('oberschenkel') || product?.name?.toLowerCase().includes('c-flex')">
        <label for="knee-joint" class="form-label">{{ $t('views.customize.workflow.form.kneeJoint') }}</label>
        <select v-if="product.name?.toLowerCase().includes('s-cover')" class="form-select" id="knee-joint" v-model="properties[0].kneeJoint" required>
          <option value="">Bitte wählen</option>
          <option  v-for="option in kneeJointOptionsCoverTypeS" :value="option" v-bind:key="option">{{ option }}</option>
        </select>
        <select v-else class="form-select" id="knee-joint" v-model="properties[0].kneeJoint" required>
          <option value="">Bitte wählen</option>
          <option v-for="option in kneeJointOptionsDefault" :value="option" v-bind:key="option">{{ option }}</option>
        </select>
      </div>
    <!-- <div class="col mb-3">
        <label for="foot" class="form-label">{{ $t('views.customize.workflow.form.foot') }}</label>
        <input type="text" class="form-control" id="foot" v-model="properties[0].foot">
    </div> -->
    </div>
    <!-- <div class="row">
      <div class="col mb-3" v-if="this.showZipperOption &&  (product?.name?.toLowerCase().includes('u-flex') || product?.name?.toLowerCase().includes('c-flex'))">
        <label for="zip" class="form-label">{{ $t('views.customize.workflow.form.zip') }}</label> <br />
        <el-radio-group v-model="properties[0].zip">
          <el-radio-button
            v-for="zip in Object.values(ZipType)"
            :key="zip"
            :label="zip"
            id="zip"
            @change="$emit('zipChanged', zip)"
          >
            {{ $t(`enum.zipType.${zip}`) }}
          </el-radio-button>
        </el-radio-group>
      </div>
      <div class="col mb-3" v-show="properties[0].zip != 'no'" v-if="this.showZipperOption &&  (product?.name?.toLowerCase().includes('u-flex') || product?.name?.toLowerCase().includes('c-flex'))">
        <label for="sew-in-by-customer" class="form-label">{{ $t('views.customize.workflow.form.sewInByCustomer') }}</label>
        <br />
        <el-switch
          v-model="properties[0].sewInByCustomer"
          id="sew-in-by-customer"
          @change="$emit('zipChanged', properties[0].zip)"
        />
      </div>
      <div class="col mb-3">
      </div>
    </div> -->
    <!-- <div class="row" v-if="!product?.name?.toLowerCase().includes('s-cover')">
      <label for="cosmetic-ending-at-foot" class="form-label">{{ $t('views.customize.workflow.form.cosmeticEndingAtFoot') }}</label>
      <br />
      <el-switch
        v-model="properties[0].cosmeticEndingAtFoot"
        id="cosmetic-ending-at-foot"
      />
    </div> -->
    <!-- <div class="row" v-if="!product?.name?.toLowerCase().includes('s-cover')">
      <div class="col mb-3">
        <label for="automatic-fit" class="form-label">{{ $t('views.customize.workflow.form.automaticFit') }}</label>
        <br />
        <el-switch
          id="automatic-fit"
          v-model="automaticFit"
          @change="automaticFitChanged()"
          :disabled="product.type.name.toLowerCase() == 'covers'"
        />
      </div>
    </div> -->
    <!-- Progress bars -->
    <div v-if="automaticFit">
      <div class="row">
        <div class="col mb-3" v-loading="bodyAttributes.length <= 0 && authenticator.user" element-loading-background="rgba(122, 122, 122, 0)">
          <label for="scan" class="form-label">{{ $t('views.customize.workflow.form.scanFiles') }}</label>
          <select v-model="selectedBodyAttribute" class="form-select" id="scan"  @change="$emit('scanFileChanged', selectedBodyAttribute)">
            <option :value="null" :label="$t(`components.product-parameter.scan.none`)"/>
            <option
              v-for="bodyAttribute in bodyAttributes"
              :key="bodyAttribute.characteristicId"
              :value="bodyAttribute"
              :label="$t(`components.product-parameter.scan.${bodyAttribute.bodyPart?.name}`)  + ' - ' + bodyAttribute.scanFile?.name"
            />
          </select>
        </div>
      </div>
    </div>

    <div>
      <div class="row">
        <div class="col" v-if="!product?.name?.toLowerCase().includes('s-cover')">
          <label for="width-range" class="form-label mb-0">{{ $t('components.order-form.scope-top') }}
            <a @click="$emit('infoButtonClicked', 'content1')"><el-icon :size="15" style="position: relative; left: 2px; top: 3px;"><InfoFilled /></el-icon></a>
          </label>
          <el-slider
            v-model="properties[0].depth"
            show-input
            :precision="0"
            :min="150"
            :max="430"
            :step="1"
            :show-tooltip="false"
            id="width-range"
          />
        </div>
      </div>
      <div class="row">
        <div class="col"  v-if="!product?.name?.toLowerCase().includes('s-cover')">
          <label for="height-range" class="form-label mb-0">{{ $t('components.order-form.height') }}
            <a @click="$emit('infoButtonClicked', 'content2')"><el-icon :size="15" style="position: relative; left: 2px; top: 3px;"><InfoFilled /></el-icon></a>
          </label>
          <el-slider
            v-model="properties[0].heightWithoutDeduction"
            show-input
            :precision="0"
            :min="200"
            :max="460"
            :step="1"
            :show-tooltip="false"
            if="height-range"
          />
        </div>
      </div>
      <div class="row">
        <div class="col"  v-if="!product?.name?.toLowerCase().includes('s-cover')">
          <label for="scope-range" class="form-label mb-0">{{ $t('components.order-form.scope-bottom') }}
            <a @click="$emit('infoButtonClicked', 'content3')"><el-icon :size="15" style="position: relative; left: 2px; top: 3px;"><InfoFilled /></el-icon></a>
          </label>
          <el-slider
            v-model="properties[0].calfCircumferenceWithoutDeduction"
            show-input
            :precision="0"
            :min="100"
            :max="300"
            :step="1"
            :show-tooltip="false"
            id="scope-range"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h3 class="mt-3">{{ $t('components.order-summary.parameterTitle') }}</h3>
    <div class="row1">
      <label class="col form-label text-secondary mb-1" for="body-side-readonly">{{ $t('views.customize.workflow.form.bodySide') }}</label>
      <label class="col form-label mb-1">{{ $t(`enum.bodySide.${properties[0]?.bodySide}`) }}</label>
    </div>
    <div class="row1">
      <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.kneeJoint') }}</label>
      <label class="col form-label mb-1">{{ properties[0]?.kneeJoint }}</label>
    </div>
    <!-- <div class="row1"  v-if="!product?.name?.toLowerCase().includes('s-cover')">
      <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.foot') }}</label>
      <label class="col form-label mb-1">{{ properties[0]?.foot }}</label>
    </div> -->
    <!-- <div class="row1" v-if="!authenticator.domains.some(d => d.urlSuffix == 'wjtortho') && product?.name?.toLowerCase().includes('u-flex') || product?.name?.toLowerCase().includes('c-flex')">
      <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.zip') }}</label>
      <label class="col form-label mb-1">{{ $t(`enum.zipType.${properties[0]?.zip}`) }}</label>
    </div> -->
    <!-- <div class="row1" v-if="!authenticator.domains.some(d => d.urlSuffix == 'wjtortho') && product?.name?.toLowerCase().includes('u-flex') || product?.name?.toLowerCase().includes('c-flex')">
      <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.sewInByCustomer') }}</label>
      <label class="col form-label mb-1">{{ $t(`enum.yesNoType.${properties[0]?.sewInByCustomer}`) }}</label>
    </div> -->
    <!-- <div class="row1">
      <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.cosmeticEndingAtFoot') }}</label>
      <label class="col form-label mb-1">{{ $t(`enum.yesNoType.${properties[0]?.cosmeticEndingAtFoot}`) }}</label>
    </div> -->
    <div v-if="!automaticFit">
      <div class="row1"  v-if="!product?.name?.toLowerCase().includes('s-cover')">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.scope-top') }}</label>
        <label class="col form-label mb-1">{{ properties[0]?.depth }}</label>
      </div>
      <div class="row1"  v-if="!product?.name?.toLowerCase().includes('s-cover')">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.height') }}</label>
        <label class="col form-label mb-1">{{ properties[0]?.heightWithoutDeduction }}</label>
      </div>
      <div class="row1"  v-if="!product?.name?.toLowerCase().includes('s-cover')">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.scope-bottom') }}</label>
        <label class="col form-label mb-1">{{ properties[0]?.calfCircumferenceWithoutDeduction }}</label>
      </div>
    </div>
    <div v-else>
      <div class="row1" v-if="!product?.name?.toLowerCase().includes('s-cover')">
        <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.scanFiles') }}</label>
        <label class="col form-label mb-1">{{ selectedBodyAttribute }}</label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { BodySide, ZipType } from "@/types/enum/order";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import * as CustomerService from "@/services/api/customerService";
import Auth from "@/services/api/auth";
import { Customer } from "@/types/api/User/Customer";
import { Body_Attribute } from "@/types/api/Patient/BodyAttribute";
import { File } from "@/types/api/Utility/File";
import { Product } from "@/types/api/Model/Product";
import ProductParameter from "./ProductParameter.vue";

@Options({
  components: {},
  emits: ['scanFileChanged', 'zipChanged', 'infoButtonClicked'],
})

export default class ProductParamterCover extends Vue {
  @Prop() properties!: any[];
  @Prop() product!: Product;
  @Prop({ default: false }) readonly!: boolean; 

  authenticator = Auth.getInstance();
  bodyAttributes: Body_Attribute[] = [];

  BodySide = BodySide;
  ZipType = ZipType;
  selectedBodyAttribute: Body_Attribute | null = null;

  automaticFit = false;

  hideZipperCompanyNames: string[] = ['wjtortho'];
  showZipperOption = true;

  kneeOption = 'Andere';

  kneeJointOptionsCoverTypeS: string[] = [
      'Andere',
      'C-Leg',
      'Genium',
      'Genium X3',
      'Kenevo',
      '3R60',
      '3R80',
      '3WR95 Aqua Knie',
      'Rheo Knie',
      'Rheo Knee XC',
      'Total Knee 2100',
      'Total Knee 2000',
      'Totales Knie 1900',
      'Mauch Knie',
      'Mauch Knie Plus',
      'Gepardenknie',
      'Balance Knie OFM1',
      'Balance Knie OFM2',
      'Paso Knie'
  ]

  kneeJointOptionsDefault: string[] = [
      'Andere',
      'Intuy Knee',
      'C-Leg',
      'Genium',
      'Genium X3',
      'Kenevo',
      '3R60',
      '3R80',
      '3WR95 Aqua Knie',
      'Rheo Knie',
      'Rheo Knee XC',
      'Total Knee 2100',
      'Total Knee 2000',
      'Totales Knie 1900',
      'Mauch Knie',
      'Mauch Knie Plus',
      'Gepardenknie',
      'Balance Knie OFM1',
      'Balance Knie OFM2',
      'Paso Knie'
    ]

  mounted(): void {
    /*this.properties[0].heightWithoutDeduction = 420;
    this.properties[0].depth = 380;
    this.properties[0].calfCircumferenceWithoutDeduction = 240;*/



    if (this.authenticator.user) {
      this.automaticFit = false;
      CustomerService.getBodyAttributesByUserId(this.authenticator.user.userId)
        .then((bodyAttributes) => {
          this.bodyAttributes = bodyAttributes;
        })
    }

  }

  beforeMount() {
    if (!this.properties) {
      this.properties = [{}];
    } else if (!this.properties[0]) {
      this.properties[0] = {};
    }

    if (!this.properties[0].colorList) {
      this.properties[0].colorList = [''];
    }

    const queryParams = new URLSearchParams(window.location.search);
    const supplier = queryParams.get('supplier');
    if(supplier) {
      this.showZipperOption = !this.hideZipperCompanyNames.includes(supplier);
    }

  }

  automaticFitChanged(): void {
    if (!this.automaticFit) this.selectedBodyAttribute = null; 
  }
}
</script>

<style scoped lang="scss">
.text-secondary {
  color: #0000008D !important;
}
.form-label {
  width: 20rem;
}
.row1 {
  border-bottom: 0.1rem solid #6767678d;
}
</style>