/* eslint-disable prettier/prettier */
import {
  mutateWithVariables,
  query,
  queryWithVariables,
} from '@/services/api/api';
import { Model, Model2 } from '@/types/api/Model/Model';
import { Component } from '@/types/api/Model/Component';
import { FileType3D, FileTypeImage, UploadCategory } from '@/types/enum/upload';
import * as THREE from 'three';
import {
  BoneData,
  EmbossingExportData,
  FFDExportData,
} from '@/services/api/templateService';
import * as THREEEnum from '@/types/enum/three';
import { ProjectionCategory } from '@/types/enum/editor';
import { Vector3 } from '@/types/api/Utility/Vector3';
import * as MathAngle from '@/utils/angle';
import { CurveCategory } from '@/types/api/Model/Curve/CurveCategory';
import { CurveType } from '@/types/api/Model/Curve/CurveType';
import { Curve } from '@/types/api/Model/Curve/Curve';
import { Camera } from '@/types/api/Model/Camera';
import { ProductQuality } from '@/types/enum/template';
import { MeshTreeData, MeshTreeDataItem } from '@/types/ui/MeshTreeData';
import { Upload } from 'tus-js-client';
import * as UploadTypes from '@/types/enum/upload';
import { Color } from '@/types/api/Model/Attributes/Color';
import { Engraving } from '@/types/api/Model/Attributes/Engraving';
import { Print } from '@/types/api/Model/Attributes/Print';
import * as ComponentService from '@/services/api/componentService';
import { he } from 'element-plus/es/locale';
import { Resolution_Level } from '@/types/api/Model/ResolutionLevel';
import { Size } from '@/types/api/Model/Attributes/Size';
import { Side } from '@/types/api/Patient/Side';
import { Size as UtilitySize } from '@/types/api/Utility/Size';
import { Power } from '@/types/api/Model/Attributes/Power';
import { FileTexture } from '@/types/api/Utility/FileTexture';
import {File as UtilityFile} from '@/types/api/Utility/File';


/* eslint-disable @typescript-eslint/no-explicit-any*/

export const uploadNewProduct = async (
  modelId: number | null,
  productName: string,
  template: MeshTreeData,
  category: string,
  basePrice: number
): Promise<number | null> => {
  if (!modelId) {
    const mutationCreateProductString = `
      mutation ($product: ProductInput!) {
        createProduct(product: $product)
      }
    `;

    const data = {
        product: {
          addressedDiseases: [],
          basePrice: basePrice,
          bodyPart: null,
          defaultCharacteristics: [],
          description: '',
          name: productName,
          sampleImagePaths: [],
          supportedGridTypes: [],
          thumbnail: {
            content: template.thumbnailUrl,
            name: template.thumbnail,
            type: template.thumbnailFiletype,
            textures: [],
            thumbnailTextures: []
          },
          type: {
            name: 'Badewanne',
            attributes: [],
          },
          packages: []
        },
    };

    const createResult = await mutateWithVariables(
      mutationCreateProductString,
      data
    );

    const productId = createResult.data.createProduct;

    const getModelsResult = await queryWithVariables(
      `
          query ($productId: Long!) {
            modelsByProductId (productId: $productId) {
              modelId
            }
          }
        `,
      {
        productId: productId,
      }
    );

    modelId = getModelsResult.data.modelsByProductId[0].modelId;
  }

  if (!modelId) {
    return -1;
  }

  const uploadList = new Map<string, Component | null>();
  for (const mesh of template.treeData.filter((t) => t.filename !== null)) {
    uploadList.set(mesh.filename as string, null);
    await uploadFileAsync(mesh, modelId.toString(), uploadList, category);
  }

  //TODO: look for a better solution
  while (Array.from(uploadList.values()).some((x) => x == null)) {
    await new Promise((resolve) => setTimeout(resolve, 3000));
  }

  for (const component of uploadList.values()) {
    if (component) {
      const mesh = template.treeData.find((x) => x.uuid == component.uuid);
      if (mesh) {
        await ComponentService.changeThumbnail(
          component.componentId,
          component.uuid,
          mesh.thumbnail,
          mesh.thumbnailFiletype,
          mesh.thumbnailUrl
        );
      }
    }
  }

  return modelId;
};

export const uploadFileAsync = async (
  mesh: MeshTreeDataItem,
  modelId: string,
  uploadList: Map<string, Component | null>,
  category: string
): Promise<boolean> => {
  if (!mesh.filename || !mesh.url) {
    return false;
  }

  const file = await fetch(mesh.url).then((r) => r.blob());
  const upload = new Upload(file, {
    endpoint: process.env.VUE_APP_BACKEND_FILE_UPLOAD_URL,
    retryDelays: [0, 1000, 3000, 5000],
    headers: {
      'Access-Control-Allow-Origin': process.env.VUE_APP_REDIRECT_URL ?? '',
    },
    metadata: {
      filename: mesh.filename,
      modelId: modelId,
      uuid: mesh.uuid,
      category: category
    },
    onError: function (error) {
      console.log('Failed because: ' + error);
    },
    onProgress: function (bytesUploaded, bytesTotal) {
      const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
      console.log(bytesUploaded, bytesTotal, percentage + '%');
    },
    onSuccess: function (res) {
      const component = JSON.parse(res.getBody()) as Component;
      if (this.metadata) uploadList.set(this.metadata['filename'], component);
    },
  });

  // Check if there are any previous uploads to continue.
  upload.findPreviousUploads().then(function (previousUploads) {
    // Found previous uploads so we select the first one.
    if (previousUploads.length) {
      upload.resumeFromPreviousUpload(previousUploads[0]);
    }

    // Start the upload
    upload.start();
  });

  return true;
};

export const upload = async (
  uuid: string,
  name: string,
  mesh: string,
  filename: string,
  filetype: string,
  position: THREE.Vector3 = new THREE.Vector3(0, 0, 0),
  rotation: THREE.Euler = new THREE.Euler(0, 0, 0),
  scale: THREE.Vector3 = new THREE.Vector3(1, 1, 1),
  componentType: UploadCategory,
  resolutionLevel = 1,
  thumbnailContent: string | null = null,
  thumbnailType = '.jpg',
  boneList: THREE.Bone[] = [],
  parentId: number | null = null,
  productId: number | null = null,
  customerId: number | null = null,
  resellerId: number | null = null,
  baseModelId: number | null = null,
  modelNotice: string | null = null,
  componentId: number | null = null,
  resolutionId: number | null = null
): Promise<number> => {
  const mutationQueryString = `
        mutation (
          ${modelNotice ? `$modelNotice: String,` : ''}
          ${baseModelId ? `$baseModelId: Long,` : ''}
          ${productId ? `$productId: Long,` : ''}
          ${resellerId ? `$resellerId: Long,` : ''}
          ${customerId ? `$customerId: Long,` : ''}
          ${componentId ? `$componentId: Long,` : ''}
          $uuid: String!,
          $name: String,
          $positionX: Float!,
          $positionY: Float!,
          $positionZ: Float!,
          $rotationX: Float!,
          $rotationY: Float!,
          $rotationZ: Float!,
          $scaleX: Float!,
          $scaleY: Float!,
          $scaleZ: Float!,
          $componentType: String!,
          ${resolutionId ? `$resolutionId: Long,` : ''}
          $resolutionLevel: Int!,
          $meshBase64: String!,
          $filename: String!,
          $filetype: String!,
          ${parentId ? `parentId: Long,` : ''}
          ${thumbnailContent ? 'thumbnailContent: String!,' : ''}
          ${thumbnailContent ? 'thumbnailType: String!,' : ''}
          $boneList: [MarkingPointPositionInput!]!,
          $curveList: [CurveInput!]!
          ) {
          upload (
            input: {
              ${customerId ? `customerId: $customerId,` : ''}
              ${resellerId ? `resellerId: $resellerId,` : ''}
              ${productId ? `productId: $productId,` : ''}
              ${baseModelId ? `baseModelId: $baseModelId,` : ''}
              ${modelNotice ? `modelNotice: $modelNotice,` : ''}
              components: [
                {
                  ${componentId ? `componentId: $componentId,` : ''}
                  uuid: $uuid,
                  name: $name,
                  attributes: [],
                  position: {
                    xValue: $positionX,
                    yValue: $positionY,
                    zValue: $positionZ
                  },
                  rotation: {
                    xValue: $rotationX,
                    yValue: $rotationY,
                    zValue: $rotationZ
                  },
                  scale: {
                    xValue: $scaleX,
                    yValue: $scaleY,
                    zValue: $scaleZ
                  },
                  type: {
                    name: $componentType
                  },
                  resolutions: [{
                    ${resolutionId ? `resolutionId: $resolutionId,` : ''}
                    level: $resolutionLevel,
                    meshBase64: {
                      content: $meshBase64,
                      name: $filename,
                      type: $filetype
                    }
                  }],
                  markingPoints: {
                    positions: $boneList
                  },
                  ${
                    thumbnailContent
                      ? `thumbnail: {content: $thumbnailContent, name: "thumbnail", type: $thumbnailType},`
                      : ''
                  }
                  ${parentId ? `parent: {componentId: $parentId},` : ''},
                  curves: $curveList
                }
              ]
            }
          ) { long }
        }
      `;

  const data = {
    modelNotice: modelNotice ? modelNotice : '',
    baseModelId: baseModelId ? baseModelId : 0,
    productId: productId ? productId : 0,
    resellerId: resellerId ? resellerId : 0,
    customerId: customerId ? customerId : 0,
    componentId: componentId ? componentId : 0,
    uuid: uuid,
    name: name,
    positionX: position.x,
    positionY: position.y,
    positionZ: position.z,
    rotationX: MathAngle.toDegrees(rotation.x),
    rotationY: MathAngle.toDegrees(rotation.y),
    rotationZ: MathAngle.toDegrees(rotation.z),
    scaleX: MathAngle.toDegrees(scale.x),
    scaleY: MathAngle.toDegrees(scale.y),
    scaleZ: MathAngle.toDegrees(scale.z),
    componentType: componentType.toString(),
    resolutionId: resolutionId ? resolutionId : 0,
    resolutionLevel: resolutionLevel,
    meshBase64: mesh,
    filename: filename,
    filetype: `.${filetype}`,
    parentId: parentId,
    thumbnailContent: thumbnailContent,
    thumbnailType: thumbnailType,
    boneList: boneList.map((item) => {
      return {
        markingPoint: {
          name: item.name,
        },
        position: {
          xValue: item.position.x,
          yValue: item.position.y,
          zValue: item.position.z,
        },
      };
    }),
    curveList: [],
  };

  const result = await mutateWithVariables(mutationQueryString, data);
  return result.data.upload.long;
};

export const createFromTemplate = async (
  templateId: number
): Promise<number> => {
  const mutationQueryString = `
        mutation (
          $modelId: Long!
          ) {
          copyModel (
            input: {
              modelId: $modelId
            }
          ) { model { modelId } }
        }
      `;

  const data = {
    modelId: templateId,
  };

  const result = await mutateWithVariables(mutationQueryString, data);
  return result.data.copyModel.model.modelId;
};

const modelQueryParameter = `modelId,
    customer {
      userId, username, firstname, lastname, email
    },
    notice,
    components {
      componentId,
      uuid,
      name,
      attributes {attributeId, additionalPrice, components {componentTypeId, name}, date, visualOnly, attributeType},
      resolutions {level, meshBase64{content, name, type}, resolutionId},
      markingPoints {
        polylineId,
        positions{
          mppId,
          position { xValue, yValue, zValue },
          markingPoint { markingPointId, name }
        }
      },
      position {xValue,  yValue, zValue},
      rotation { xValue, yValue, zValue },
      scale { xValue, yValue, zValue },
      type { componentTypeId, name },
      curves {
        curveId,
        name,
        category,
        closed,
        points { xValue, yValue, zValue },
        position { xValue, yValue, zValue },
        rotation { xValue, yValue, zValue },
        scale { xValue, yValue, zValue },
        type
      },
      parent { componentId, uuid },
      thumbnail { name, type, content },
      basePrice,
      unit
    },
    basedOn { modelId },
    curves {
      curveId,
      name,
      category,
      closed,
      points { xValue, yValue, zValue },
      position { xValue, yValue, zValue },
      rotation { xValue, yValue, zValue },
      scale { xValue, yValue, zValue },
      type
    },
    cameras {
      cameraId,
      name,
      position { xValue, yValue, zValue },
      rotation { xValue, yValue, zValue }
    },
    customerAttributes { characteristicId,  date },
    product {
      productId,
      name,
      description,
      basePrice,
      type { productTypeId, name, attributes { id, key, value }},
      thumbnail {
        fileId,
        content,
        type,
        name
      },
      packages {
        packageId,
        description,
        price,
      }
    },
    reseller {
      userId, username, firstname, lastname, email
    },
    workflow {
      workflowId, finished, steps { stepId, datetime, name, notice, stepType, additionalInformation { id, key, value } }
    }`;

export interface MeshExportData {
  componentId: number | null;
  uniqueKey: string;
  name: string;
  category: UploadCategory;
  fileType: FileType3D;
  base64: string;
  thumbnailType: FileTypeImage;
  thumbnail: string;
  bones: BoneData[];
  color: string;
  position: THREE.Vector3;
  rotation: THREE.Euler;
  scale: THREE.Vector3;
  parentId: string | null;
  ffd: FFDExportData | null;
  embossing: EmbossingExportData[];
}

export interface CurveExportData {
  curveId: number | null;
  category: CurveCategory;
  name: string;
  closed: boolean;
  type: CurveType;
  meshId: string | null;
  position: THREE.Vector3;
  rotation: THREE.Euler;
  scale: THREE.Vector3;
  curvePoints: THREE.Vector3[];
}

export interface CameraExportData {
  cameraId: number | null;
  name: string;
  view: THREEEnum.Views;
  perspective: boolean;
  position: THREE.Vector3;
  rotation: THREE.Euler;
}

export const parseToMeshExportData = (component: Component, resolutionLevel: Resolution_Level): MeshExportData => {
  let embossing: EmbossingExportData[] = [];
  if (component.embossing) {
    embossing = component.embossing.map((data) => {
      return {
        projectionType: data.projectionType
          ? THREEEnum.ProjectionType[data.projectionType.toString()]
          : THREEEnum.ProjectionType.cylinder,
        position: Vector3.convertToThreeVector(data.position),
        rotation: Vector3.convertToThreeEuler(data.rotation),
        scale: Vector3.convertToThreeVector(
          data.scale,
          new THREE.Vector3(1, 1, 1)
        ),
        projection: {
          projectionCategory: (data.projectionCategory
            ? ProjectionCategory[data.projectionCategory.toString()]
            : ProjectionCategory.custom) as ProjectionCategory,
          aspectRatio: data.aspectRatio,
          scaleFactor: data.scaleFactor,
          offset: data.offset,
          embossingFile: data.embossingFile,
          color: data.color,
          embossingDefinition: data.embossingDefinition.map((url) => {
            return {
              name: '',
              url: url,
              uid: 0,
              status: 'success',
            };
          }),
          colorDefinition: data.colorDefinition,
        },
      };
    });
  }

  return {
    componentId: component.componentId,
    uniqueKey: component.uuid,
    category: component.type
      ? (component.type.name as UploadCategory)
      : UploadCategory.ReferenceDummy,
    name: component.name,
    fileType: FileType3D.OBJ,
    base64:
      component.resolutions.length > 0
        ? atob(resolutionLevel.meshBase64.content)
        : '',
    bones: component.markingPoints
      ? component.markingPoints.positions.map((point) => {
          return {
            name: point.markingPoint.name,
            position: Vector3.convertToThreeVector(point.position),
          };
        })
      : [],
    color: '#ffffff',
    thumbnailType: component.thumbnail
      ? UploadTypes.getImageFileType(component.thumbnail.type)
      : FileTypeImage.NONE,
    thumbnail: component.thumbnail ? component.thumbnail.content : '',
    position: Vector3.convertToThreeVector(component.position),
    rotation: Vector3.convertToThreeEuler(component.rotation),
    scale: Vector3.convertToThreeVector(
      component.scale,
      new THREE.Vector3(1, 1, 1)
    ),
    parentId: component.parent ? component.parent.uuid : null,
    ffd: component.ffd
      ? {
          spanCounts: component.ffd.spanCounts,
          gridPositionList: component.ffd.gridPositionList.map((position) =>
            Vector3.convertToThreeVector(position)
          ),
        }
      : null,
    embossing: embossing
  };
};

export const parseToCurveExportData = (
  curve: Curve,
  component: Component | null = null
): CurveExportData => {
  return {
    curveId: curve.curveId,
    category: curve.category,
    name: curve.name,
    closed: curve.closed,
    type: curve.type,
    meshId: component ? component.uuid : null,
    position: Vector3.convertToThreeVector(curve.position),
    rotation: Vector3.convertToThreeEuler(curve.rotation),
    scale: Vector3.convertToThreeVector(
      curve.scale,
      new THREE.Vector3(1, 1, 1)
    ),
    curvePoints: curve.points.map((point) => {
      return Vector3.convertToThreeVector(point);
    }),
  };
};

export const parseToCameraExportData = (camera: Camera): CameraExportData => {
  return {
    cameraId: camera.cameraId,
    name: camera.name,
    view: THREEEnum.Views.custom,
    perspective: true,
    position: Vector3.convertToThreeVector(camera.position),
    rotation: Vector3.convertToThreeEuler(camera.rotation),
  };
};

export const getModelOverviewList = async (): Promise<Model2[]> => {
  const result = await query(`
        query {
          modelsOverview($productId: productId) {
            modelId, 
            notice,
            product {
              productId
              name,
              description
              thumbnail {
                content,
                type,
                name
              }
            },
            basedOn {
              modelId
            }
          }
        }
      `);

  return result.data.modelsOverview;
};

export const getModelsByUserId = async (
  userId: number,
  productTypeId: number | null
): Promise<Model2[]> => {
  const getProductsResult = await queryWithVariables(
    `
        query ($userId: Long!, $productTypeId: Long) {
          modelsByUserId (userId: $userId, productTypeId: $productTypeId) {
            modelId,
            notice,
            product {
              productId
              name,
              description
              thumbnail {
                content,
                type,
                name
              },
              type {
                productTypeId,
                name
              }
            },
            basedOn {
              modelId
            }
          }
        }
      `,
    {
      userId: userId,
      productTypeId: productTypeId
    }
  );

  return getProductsResult.data.modelsByUserId;
};

export const getModelsByProductId = async (
  productId: number,
): Promise<Model2[]> => {
  const getModelsResult = await queryWithVariables(
    `
        query ($productId: Long!) {
          modelsByProductId (productId: $productId) {
            modelId,
            notice,
            product {
              productId
              name,
              description
              thumbnail {
                content,
                type,
                name
              },
              type {
                productTypeId,
                name
              }
            },
            basedOn {
              modelId
            }
          }
        }
      `,
    {
      productId: productId
    }
  );

  return getModelsResult.data.modelsByProductId;
};

export const getModelsByUrlSuffix = async (
  urlSuffix: string,
  productTypeId: number | null
): Promise<Model2[]> => {
  const getProductsResult = await queryWithVariables(
    `
        query ($urlSuffix: String!, $productTypeId: Long) {
          modelsByUrlSuffix (urlSuffix: $urlSuffix, productTypeId: $productTypeId) {
            modelId, 
            notice,
            product {
              productId
              name,
              description
              thumbnail {
                content,
                type,
                name
              },
              type {
                productTypeId,
                name
              }
            },
            basedOn {
              modelId
            }
          }
        }
      `,
    {
      urlSuffix: urlSuffix,
      productTypeId: productTypeId
    }
  );

  return getProductsResult.data.modelsByUrlSuffix;
};

export const getMinResolutionLevel = async (
  modelId: number,
  productQuality: ProductQuality = ProductQuality.low
): Promise<number> => {
  const result = await queryWithVariables(
    `
        query ($modelId: Long!) {
          resolutionLevels(modelId: $modelId)
        }
      `,
    {
      modelId: modelId,
    }
  );
  console.log(result.data.resolutionLevels);
  const levelList: number[] = [];
  for (const resolution of result.data.resolutionLevels) {
    levelList.push(resolution);
  }

  const sortedLevelList = levelList.sort((a, b) => (a < b ? -1 : 1));

  switch (productQuality) {
    case ProductQuality.original: {
      return sortedLevelList[0];
    }
    case ProductQuality.high: {
      return sortedLevelList[1];
    }
    case ProductQuality.middle: {
      return sortedLevelList[2];
    }
    case ProductQuality.low: {
      return sortedLevelList[3];
    }
    default: {
      return sortedLevelList[3];
    }
  }
};

export const getModels = async (
  minResolutionLevel: number
): Promise<Model[]> => {
  const result = await queryWithVariables(
    `
        query ($minResolutionLevel: Int) {
          models (minResolutionLevel: $minResolutionLevel) {
            ${modelQueryParameter}
          }
        }
      `,
    {
      minResolutionLevel: minResolutionLevel,
    }
  );

  return result.data.models;
};

export const getModel = async (
  id: number,
  productQuality: ProductQuality = ProductQuality.low
): Promise<Model> => {
  const minResolutionLevel = await getMinResolutionLevel(id, productQuality);
  const result = await queryWithVariables(
    `
        query ($id: Long!, $minResolutionLevel: Int) {
          models(ids: [$id], minResolutionLevel: $minResolutionLevel) {
            ${modelQueryParameter}
          }
        }
      `,
    {
      id: id,
      minResolutionLevel: minResolutionLevel,
    }
  );

  return result.data.models[0];
};

export const getModel2 = async (
  id: number,
  productQuality: ProductQuality = ProductQuality.low,
  abortController: AbortController
): Promise<Model2> => {
  let minResolutionLevel = await getMinResolutionLevel(id, productQuality);
  if (!minResolutionLevel)
    minResolutionLevel = 1;
  const result = await queryWithVariables(
    `
        query ($id: Long!, $minResolutionLevel: Int) {
          models(ids: [$id], minResolutionLevel: $minResolutionLevel) {
            ${modelQueryParameter}
          }
        }
      `,
    {
      id: id,
      minResolutionLevel: minResolutionLevel,
    },
    abortController
  );

  return result.data.models[0];
};

export const getColor = async (componentId: number): Promise<Color> => {
  const result = await queryWithVariables(
    `
      query($componentId: Long!) {
        color(componentId: $componentId) {
          attributeId, additionalPrice, components { componentTypeId, name }, date, visualOnly, attributeType, supportedValues { paletteId, name, additionalPrice, supportedColors { name, red, green, blue } }, value { name, red, green, blue }
        }
      }
    `,
    {
      componentId: componentId,
    }
  );

  let color = result.data.color;
  if (color) {
    color = { ...result.data.color };
  }
  return color;
};

export const getSize = async (componentId: number): Promise<Size> => {
  const result = await queryWithVariables(
    `
      query($componentId: Long!) {
        size(componentId: $componentId) {
          attributeId, additionalPrice, components { componentTypeId, name }, date, visualOnly, attributeType, width, height, depth
        }
      }
    `,
    {
      componentId: componentId,
    }
  );

  let size = result.data.size;
  if (size) {
    size = { ...result.data.size };
  }
  return size;
};

export const getPower = async (componentId: number): Promise<Power> => {
  const result = await queryWithVariables(
    `
      query($componentId: Long!) {
        power(componentId: $componentId) {
          attributeId, additionalPrice, components { componentTypeId, name }, date, visualOnly, attributeType, value
        }
      }
    `,
    {
      componentId: componentId,
    }
  );

  let power = result.data.power;
  if (power) {
    power = { ...result.data.power };
  }
  return power;
};

export const getEngraving = async (componentId: number): Promise<Engraving> => {
  const result = await queryWithVariables(
    `
      query($componentId: Long!) {
        engraving(componentId: $componentId) {
          attributeId, 
          additionalPrice, 
          components { 
            componentTypeId, 
            name 
          }, 
          date, 
          visualOnly, 
          attributeType, 
          supportedValues {
            fileTextureId,
            file{fileId},
           
            thumbnail { 
              fileId, 
              name, 
              type, 
              content 
            }, 
           
            palette { 
              paletteId, 
              name,
              additionalPrice,
              supportedColors { 
                name, 
                red, 
                green, 
                blue 
              } 
            }
             
          }, 
          position { 
            xValue, 
            yValue, 
            zValue 
          }, 
          rotation { 
            xValue, 
            yValue, 
            zValue 
          }, 
          offset { 
            xValue, 
            yValue, 
            zValue 
          }, 
          engravingFile { 
            fileId, 
            name, 
            type, 
            content 
          }
        }
      }
    `,
    {
      componentId: componentId,
    }
  );
  let engraving = result.data.engraving;
  if (engraving) {
    engraving = { ...result.data.engraving };
   
  }
  
  return engraving;
};

export const getEngravingFile = async (fileTextureId: number): Promise<UtilityFile> => {
  const result = await queryWithVariables(
    `
      query($fileTextureId: Long!) {
        engravingFile(fileTextureId: $fileTextureId) {
          fileId,
          name,
          content,
          textures{file{fileId}, thumbnail{fileId},fileTextureId}     
        }        
      }
    `,
    {
      fileTextureId: fileTextureId,
    }
  );
  let engravingFile = result.data.engravingFile;
  if (engravingFile) {
    engravingFile = { ...result.data.engravingFile };
  }
  
  return engravingFile;
};


export const getFile = async (fileId: number |null): Promise<UtilityFile> => {
  const result = await queryWithVariables(
    `
      query($fileId: Long!) {
        file(fileId: $fileId) {
          fileId,
          name,
          content,
        }        
      }
    `,
    {
      fileId: fileId,
    }
  );
  let file = result.data?.file
  if (file) {
    file = { ...result.data.file };
  }
  
  return file;
};

export const getTexture = async (componentId: number): Promise<Print> => {
  const result = await queryWithVariables(
    `
      query($componentId: Long!) {
        texture(componentId: $componentId) {
          attributeId, 
          additionalPrice, 
          components { 
            componentTypeId, 
            name 
          }, 
          date, 
          visualOnly, 
          attributeType, 
          supportedValues { 
            fileTextureId,
            file{fileId,name,content},
            thumbnail { 
              fileId, 
              name,              
            }, 
            palette { 
              paletteId,
              name,
              additionalPrice,
              supportedColors { 
                name, 
                red, 
                green, 
                blue 
              } 
            }
          },
          position { 
            xValue, 
            yValue, 
            zValue 
          }, 
          rotation { 
            xValue, 
            yValue, 
            zValue 
          }, 
          offset { 
            xValue, 
            yValue,
            zValue 
          },
          transparent,
          textureFile { 
            fileId, 
            name, 
            type, 
            content 
          }
        }
      }
    `,
    {
      componentId: componentId,
    }
  );

  let texture = result.data.texture;
  if (texture) {
    texture = { ...result.data.texture };
  }
  return texture;
};

export const undoSteps = async (modelId: number): Promise<number> => {
  const result = await queryWithVariables(
    `
        query ($modelId: Long!) {
          undoSteps(modelId: $modelId)
        }
      `,
    {
      modelId: modelId,
    }
  );

  return result.data.undoSteps;
};

//TODO: implement correctly
export const redoSteps = async (/*modelId: number*/): Promise<number> => {
  /*const result = await queryWithVariables(
    `
        query ($modelId: Long!) {
          redoSteps(modelId: $modelId)
        }
      `,
    {
      modelId: modelId,
    }
  );

  return result.data.undoSteps;*/
  return 0;
};

export const undo = async (
  modelId: number,
  numberOfSteps = 1
): Promise<void> => {
  await mutateWithVariables(
    `
        mutation ($modelId: Long!, $numberOfSteps: Int!) {
          undo(
            input: { 
              modelId: $modelId,
              numberOfSteps: $numberOfSteps
            }
          ) { long }
        }
      `,
    {
      modelId: modelId,
      numberOfSteps: numberOfSteps,
    }
  );
};

export const redo = async (
  modelId: number,
  numberOfSteps = 1
): Promise<void> => {
  await mutateWithVariables(
    `
        mutation ($modelId: Long!, $numberOfSteps: Int!) {
          redo(
            input: { 
              modelId: $modelId,
              numberOfSteps: $numberOfSteps
            }
          ) { long }
        }
      `,
    {
      modelId: modelId,
      numberOfSteps: numberOfSteps,
    }
  );
};

export const deleteModel = async (modelId: number): Promise<boolean> => {
  const result = await mutateWithVariables(
    `
        mutation ($modelId: Long!) {
          deleteModel(
            input: { modelId: $modelId }
          ) { boolean }
        }
      `,
    {
      modelId: modelId,
    }
  );
  return result.data.deleteModel.boolean;
};

export const automaticAdaption = async (
  productId: number,
  scanFileId: number,
  skeletonFileId: number,
  size: UtilitySize,
  marginDistance = 0.0,
  keepOutside = false,
  resolutionLevel = 16,
  side = 2
): Promise<Model2> => {
  const automaticAdaption = await mutateWithVariables(
    `
        mutation ($productId: Long!, $scanFileId: Long!, $skeletonFileId: Long!, $width: Float!, $height: Float!, $depth: Float!, $marginDistance: Float, $keepOutside: Boolean, $resolutionLevel: Int, $side: Int) {
          automaticAdaption(
            input: {
              productId: $productId,
              scanFileId: $scanFileId,
              skeletonFileId: $skeletonFileId,
              size: {
                width: $width,
                height: $height,
                depth: $depth
              },
              marginDistance: $marginDistance,
              keepOutside: $keepOutside,
              resolutionLevel: $resolutionLevel,
              side: $side
            })
          {
            model {
              ${modelQueryParameter}
            }
          }
        }
      `,
    {
      productId: productId,
      scanFileId: scanFileId,
      skeletonFileId: skeletonFileId,
      width: size.width,
      height: size.height,
      depth: size.depth,
      marginDistance: marginDistance,
      keepOutside: keepOutside,
      resolutionLevel: resolutionLevel,
      side: side
    }
  );

  return automaticAdaption.data.automaticAdaption.model;
};

export const createNewBalconyModel = async (baseModelId: number, width: number, height: number, depthLeft: number, depthRight: number, type: string, abortController: AbortController): Promise<Model2> => {
  const result = await mutateWithVariables(
    `
        mutation ($baseModelId: Long!, $width: Float!, $height: Float!, $depthLeft: Float!, $depthRight: Float!, $type: String!) {
          createNewBalconyModel(
            input: { 
              baseModelId: $baseModelId,
              width: $width,
              height: $height,
              depthLeft: $depthLeft,
              depthRight: $depthRight,
              type: $type
            }
          ) 
          { 
            model {
              ${modelQueryParameter}
            }
          }
        }
      `,
    {
      baseModelId: baseModelId,
      width: width,
      height: height,
      depthLeft: depthLeft,
      depthRight: depthRight,
      type: type
    },
    abortController
  );
  return result.data.createNewBalconyModel.model;
}

export const createNewFenceModel = async (baseModelId: number, width: number, height: number, depth: number, type: string, abortController: AbortController): Promise<Model2> => {
  const result = await mutateWithVariables(
    `
        mutation ($baseModelId: Long!, $width: Float!, $height: Float!, $depth: Float!, $type: String!) {
          createNewFenceModel(
            input: { 
              baseModelId: $baseModelId,
              width: $width,
              height: $height,
              depth: $depth,
              type: $type
            }
          ) 
          { 
            model {
              ${modelQueryParameter}
            }
          }
        }
      `,
    {
      baseModelId: baseModelId,
      width: width,
      height: height,
      depth: depth,
      type: type
    },
    abortController
  );
  return result.data.createNewFenceModel.model;
}


export const createNewSuspendedLightModel = 
  async(
    baseModelId: number,
    color: string,
    mountingProfileType: string, 
    lightCoverType: string, 
    vFour: boolean,
    iPRatingType: string,
    lightColorType: string,
    lightLength: number,
    abortController: AbortController
  ) : Promise<Model2> => {
  const result = await mutateWithVariables(
    `
        mutation 
        (
          $baseModelId: Long!,
          $color: String!,
          $mountingProfileType: String!,
          $lightCoverType: String!,
          $vFour: Boolean!,
          $iPRatingType: String!,
          $lightColorType: String!,
          $lightLength: Int!
        ) {
          createNewSuspendedLightModel(
            input: { 
              baseModelId: $baseModelId,
              color: $color,
              mountingProfileType: $mountingProfileType,
              lightCoverType: $lightCoverType,
              vFour: $vFour,
              iPRatingType: $iPRatingType,
              lightColorType: $lightColorType,
              lightLength: $lightLength
            }
          ) 
          { 
            model {
              ${modelQueryParameter}
            }
          }
        }
      `,
    {
      baseModelId: baseModelId,
      color: color,
      mountingProfileType: mountingProfileType,
      lightCoverType: lightCoverType,
      vFour: vFour,
      iPRatingType: iPRatingType,
      lightColorType: lightColorType,
      lightLength: lightLength
    },
    abortController
  );
  console.log(result.data.createNewSuspendedLightModel.model);
  return result.data.createNewSuspendedLightModel.model;
}
