<template>
  <!-- Progress bars -->
  <div v-if="!readonly">
    <div class="row mb-2">
      <div class="col">
        <label for="orientation" class="form-label mb-2">{{ $t('components.product-parameter.balcony.orientation-type') }}</label><br />
        <el-radio-group v-model="properties[0].orientationType" size="large" id="orientation">
          <el-radio-button
            v-for="orientationType in Object.values(OrientationType)"
            :key="orientationType"
            :label="orientationType"
            :disabled="readonly"
          >
            {{ $t(`enum.orientationType.${orientationType}`) }} <br />
            <img class="parameter-img" :src="require(`@/assets/images/balcony/${orientationType}.png`)"/>
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col">
        <label for="carrying-out" class="form-label mb-2">{{ $t('components.product-parameter.balcony.carrying-out') }}</label><br />
        <el-radio-group v-model="properties[0].balconyType" size="large" id="carrying-out">
          <el-radio-button
            v-for="balconyType in Object.values(BalconyType)"
            :key="balconyType"
            :label="balconyType"
            @change="$emit('sizeChanged')"
            :disabled="readonly"
          >
            {{ $t(`enum.balconyType.${balconyType}`) }} <br />
            <img class="parameter-img" :src="require(`@/assets/images/balcony/${balconyType}.png`)"/>
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col">
        <label for="paneelType" class="form-label mb-2">{{ $t('components.product-parameter.balcony.paneel-type') }}</label><br />
        <el-radio-group v-model="properties[0].paneelType" size="large" id="paneelType" @change="mountingTypeChanged">
          <el-radio-button
            v-for="paneelType in Object.values(PaneelType)"
            :key="paneelType"
            :label="paneelType"
            @change="$emit('sizeChanged')"
            :disabled="readonly"
          >
            {{ $t(`enum.paneelType.${paneelType}`) }} <br />
            <img class="parameter-img" :src="require(`@/assets/images/balcony/${paneelType}.png`)"/>
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col">
        <label for="mounting" class="form-label mb-2">{{ $t('components.product-parameter.balcony.mounting-type') }}</label><br />
        <el-radio-group v-model="properties[0].mountingType" size="large" id="mounting" @change="mountingTypeChanged">
          <el-radio-button
            v-for="mountingType in Object.values(MountingType)"
            :key="mountingType"
            :label="mountingType"
            :disabled="properties[0].paneelType == PaneelType.portrait || readonly"
            @change="$emit('sizeChanged')"
          >
            {{ $t(`enum.mountingType.${mountingType}`) }} <br />
            <img class="parameter-img" :src="require(`@/assets/images/balcony/${mountingType}.png`)"/>
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <label for="width-range" class="form-label mb-0">{{ $t('components.order-form.width') }}</label>
        <el-slider
          v-model="properties[0].calfCircumferenceWithoutDeduction"
          show-input
          :precision="0"
          :min="0"
          :max="20000"
          :step="1"
          :show-tooltip="false"
          id="width-range"
          @change="$emit('sizeChanged')"
          :disabled="readonly"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="height-range" class="form-label mb-0">{{ $t('components.order-form.height') }}</label>
        <el-slider
          v-model="properties[0].heightWithoutDeduction"
          show-input
          :precision="0"
          :min="0"
          :max="20000"
          :step="1"
          :show-tooltip="false"
          if="height-range"
          disabled
          />
      </div>
    </div>
    <div class="row" v-if="properties[0].balconyType == BalconyType.uForm">
      <div class="col">
        <el-switch
          v-model="depthConnected"
          class="mt-2"
          inline-prompt
          :active-icon="Connection"
          :inactive-icon="Close"
          @change="(activated) => { if (activated) properties[0].depthRight = properties[0].depth}"
        />
      </div>
    </div>
    <div class="row" v-if="properties[0].balconyType != BalconyType.straight">
      <div class="col">
        <label v-if="properties[0].balconyType == BalconyType.uForm" for="length-range" class="form-label mb-0">{{ $t('components.order-form.depth-left') }}</label>
        <label v-else class="form-label mb-0">{{ $t('components.order-form.depth') }}</label>
        <el-slider
          v-model="properties[0].depth"
          show-input
          :precision="0"
          :min="0"
          :max="20000"
          :step="1"
          :show-tooltip="false"
          id="length-range" 
          @change="() => { if (depthConnected) properties[0].depthRight = properties[0].depth; $emit('sizeChanged') }"
          :disabled="readonly"
        />
      </div>
    </div>
    <div class="row" v-if="properties[0].balconyType == BalconyType.uForm">
      <div class="col">
        <label for="length-range" class="form-label mb-0">{{ $t('components.order-form.depth-right') }}</label>
        <el-slider
          v-if="depthConnected"
          v-model="properties[0].depth"
          show-input
          :precision="0"
          :min="0"
          :max="20000"
          :step="1"
          :show-tooltip="false"
          id="length-range" 
          :disabled="true"
        />
        <el-slider
          v-else
          v-model="properties[0].depthRight"
          show-input
          :precision="0"
          :min="0"
          :max="20000"
          :step="1"
          :show-tooltip="false"
          id="length-range" 
          @change="$emit('sizeChanged')"
          :disabled="readonly"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <h3 class="mt-3">{{ $t('components.order-summary.parameterTitle') }}</h3>
    <div class="row1">
      <label class="col form-label text-secondary mb-1">{{ $t('components.product-parameter.balcony.carrying-out') }}</label>
      <label class="col form-label mb-1">{{ $t(`enum.balconyType.${properties[0].balconyType}`) }}</label>
    </div>
    <div class="row1">
      <label class="col form-label text-secondary mb-1">{{ $t('components.product-parameter.balcony.mounting-type') }}</label>
      <label class="col form-label mb-1">{{ $t(`enum.mountingType.${properties[0].mountingType}`)  }}</label>
    </div>
    <div class="row1">
      <label class="col form-label text-secondary mb-1">{{ $t('components.product-parameter.balcony.paneel-type') }}</label>
      <label class="col form-label mb-1">{{ $t(`enum.paneelType.${properties[0].paneelType}`)  }}</label>
    </div>
    <div class="row1">
      <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.width') }}</label>
      <label class="col form-label mb-1">{{ properties[0].calfCircumferenceWithoutDeduction }}</label>
    </div>
    <div class="row1">
      <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.height') }}</label>
      <label class="col form-label mb-1">{{ properties[0].heightWithoutDeduction }}</label>
    </div>
    <div class="row1">
      <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.depth') }}</label>
      <label class="col form-label mb-1">{{ properties[0].depth }}</label>
    </div>
  </div>
</template>

<script lang="ts">
import { BalconyType, MountingType, PaneelType, OrientationType } from "@/types/enum/order";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Check, Close, Connection } from '@element-plus/icons-vue';
import { shallowRef } from "vue";

@Options({
  components: {},
  emits: ['sizeChanged']
})

export default class ProductParamterBalcony extends Vue {
  @Prop() properties!: any[];
  @Prop({ default: false }) readonly!: boolean;

  BalconyType = BalconyType;
  MountingType = MountingType;
  PaneelType = PaneelType;
  OrientationType = OrientationType;

  depthConnected = false;

   Check = shallowRef(Check);
  Close = shallowRef(Close);
  Connection = shallowRef(Connection);

  mountingTypeChanged() :void {
    if (this.properties[0].paneelType == PaneelType.portrait) {
      this.properties[0].mountingType = MountingType.front;
    }

    if(this.properties[0].mountingType == MountingType.front && 
        this.properties[0].paneelType == PaneelType.landscape) {
      this.properties[0].heightWithoutDeduction = 1015;
    } else if(this.properties[0].mountingType == MountingType.bottom &&
        this.properties[0].paneelType == PaneelType.landscape) {
      this.properties[0].heightWithoutDeduction = 1020;
    } else if (this.properties[0].mountingType == MountingType.front && 
        this.properties[0].paneelType == PaneelType.portrait) {
      this.properties[0].heightWithoutDeduction = 1645;
    }
  }
}
</script>

<style scoped lang="scss">
.text-secondary {
  color: #0000008D !important;
}
.form-label {
  width: 20rem;
}

.parameter-img {
  padding-top: 12px;
  width: 100px;
  height: 100px;
}

.row1 {
  border-bottom: 0.1rem solid #0000008D;
}
</style>