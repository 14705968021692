import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b1e585dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "level-left" }
const _hoisted_2 = { class: "level-right" }
const _hoisted_3 = { class: "level-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_page_header = _resolveComponent("el-page-header")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_MeshLoader = _resolveComponent("MeshLoader")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_Workflow = _resolveComponent("Workflow")!

  return (_openBlock(), _createBlock(_component_el_container, { class: "screenHeight" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, { class: "level" }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, [
            _createVNode(_component_el_page_header, {
              class: "level-item",
              content: _ctx.$t(`enum.upload-progress.${_ctx.activeUploadProgress}`),
              onBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
            }, null, 8, ["content"])
          ]),
          _createElementVNode("span", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.user?.firstname) + " " + _toDisplayString(_ctx.user?.lastname), 1)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_main, null, {
            default: _withCtx(() => [
              _createVNode(_component_MeshLoader, {
                uploadProgress: _ctx.activeUploadProgress,
                "onUpdate:uploadProgress": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeUploadProgress) = $event)),
                ref: "meshLoader",
                canSelect: true,
                canTakeSnapshot: true,
                canToggleOpacity: true
              }, null, 8, ["uploadProgress"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_Workflow, {
        class: "workflow",
        workflowModel: _ctx.workflowModel,
        fullscreenLoading: _ctx.fullscreenLoading
      }, null, 8, ["workflowModel", "fullscreenLoading"])
    ]),
    _: 1
  }))
}