/* eslint-disable @typescript-eslint/no-explicit-any*/
export const fileContentToBase64 = (
  file: File,
  callback: (encodeString: string) => void
): void => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    callback(reader.result as string);
  };
};
