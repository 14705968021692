<script setup lang="ts">
import { RGB } from "@/types/api/Utility/RGB";
import ButtonCard from "@/components/ButtonCard.vue";

interface Props {
  selectedMeshEmbossingColor: RGB;
  selectedMeshColor: RGB;
  selectedMeshTextureColor: RGB;
  meshEmbossingColors: RGB[];
  meshColors: RGB[];
  meshTextureColors: RGB[];
}

const props = defineProps<Props>();
const emit = defineEmits(['mesh-color-selected', 'mesh-texture-color-selected', 'mesh-embossing-color-selected']);
</script>

<template>
  <div v-if="props.meshColors.length > 0 || props.meshTextureColors.length > 0" id="color-selection" class="pl-5 pr-5 pt-3 pb-3 rounded-3 text-white">
    <h1>{{ $t('components.color-selection.color-title') }}</h1>
    <div class="d-flex flex-wrap gap-3">
      <div v-if="props.meshColors.length > 0" class="d-flex flex-wrap gap-3">
        <button-card v-for="color in props.meshColors" :key="color.name"
                     :title="$t(`views.colors.${color.name}`) ?? color.name"
                     :selected="props.selectedMeshColor != undefined && props.selectedMeshColor.name === color.name"
                     @click="$emit('mesh-color-selected', color)">
          <div class="rounded-circle" :style="'background: rgb(' + color.red + ',' + color.green + ',' + color.blue + ')'"></div>
        </button-card>
      </div>
      <div v-else-if="props.meshTextureColors.length > 0" class="d-flex flex-wrap gap-3">
        <button-card v-for="color in props.meshTextureColors" :key="color.name"
                     :title="$t(`views.colors.${color.name}`) ?? color.name"
                     :selected="props.selectedMeshTextureColor != undefined && props.selectedMeshTextureColor.name === color.name"
                     @click="$emit('mesh-texture-color-selected', color)">
          <div class="rounded-circle" :style="'background: rgb(' + color.red + ',' + color.green + ',' + color.blue + ')'"></div>
        </button-card>
      </div>
    </div>
  </div>
  <div v-if="props.meshEmbossingColors.length > 0" id="color-embossing-selection" class="pl-5 pr-5 pt-3 pb-3 rounded-3 text-white">
    <h1>{{ $t('components.color-selection.color-embossing-title') }}</h1>
    <div class="d-flex flex-wrap gap-3">
      <div class="d-flex flex-wrap gap-3">
        <button-card v-for="color in props.meshEmbossingColors" :key="color.name"
                     :title="$t(`views.colors.${color.name}`) ?? color.name"
                     :selected="props.selectedMeshEmbossingColor != undefined && props.selectedMeshEmbossingColor.name === color.name"
                     @click="$emit('mesh-embossing-color-selected', color)">
          <div class="rounded-circle" :style="'background: rgb(' + color.red + ',' + color.green + ',' + color.blue + ')'"></div>
        </button-card>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
#color-selection,
#color-embossing-selection {
  background: transparent radial-gradient(closest-side at 50% 50%, rgba(75,75,75,0.6) 0%, rgba(0,0,0,0.6) 100%) 0% 0% no-repeat padding-box;
}
.img {
  height: 9rem;
  width: 9rem;
}

.rounded-circle {
  height: 5.625rem !important;
  width: 5.625rem !important;
  border: 2px solid #CBCBCB;
}
</style>
