<script lang="ts">
import ProductQuality from "./three/ProductQuality.vue";
import { WorkflowModel } from "@/types/ui/WorkflowModel";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import * as OrderService from '@/services/api/orderService';
import Auth from "@/services/api/auth";
import { Role } from "@/types/enum/user";

@Options({
  components: {
    ProductQuality
  },
  emits: ['continue-clicked', 'back-clicked', 'confirmOfferPdf'],
})

export default class NavigationBar extends Vue {
  @Prop() workflowModel!: WorkflowModel;
  @Prop({ default: '' }) supplier!: string;
  @Prop({ default: -1 }) selectedModelId!: number;
  @Prop({ default: null }) offerData!: OrderService.OrderRequestData;
  @Prop({ default: null}) screenshotSrc!: string[];
  @Prop({ default: false }) loadingOfferPdf!: boolean;
  @Prop() productName!: string;

  authentication = Auth.getInstance();

  loadingPartListPdf = false;

  Role = Role;

  async createPartListPdf(): Promise<void> {
    this.loadingPartListPdf = true;
    const downloadPdfBase64 = await OrderService.createPdfPartList(this.selectedModelId!, this.authentication.primaryDomain?.domainId ?? 2)
    const pdfPartListHTMLElement = (document.getElementById('pdf-part-list') as HTMLAnchorElement);
    if (pdfPartListHTMLElement) {
      pdfPartListHTMLElement.href = downloadPdfBase64.toString();
      pdfPartListHTMLElement.click();
    }
    this.loadingPartListPdf = false;
  }
  handleBackButton(){
    if (this.workflowModel.active ===3){
      this.workflowModel.changeActiveStep(-3);
    }else{
      this.workflowModel.changeActiveStep(-1);
    }
  }

}

</script>

<template>
  <div v-if="supplier == 'demo'" class="row gap-4 d-flex justify-content-center mt-4">
    <span style="width: auto; color: red">{{ $t('components.embossing-footer.demoInfo') }}</span>
  </div>
  <div class="row gap-4 d-flex justify-content-center mt-4 pb-5">
    <button v-if="workflowModel.active == 1" type="button" class="btn btn-secondary" :class="workflowModel.isPrevStepPossible() ? '' : 'disabled'"
            @click="workflowModel.changeActiveStep(-1)">
      {{ '< ' + $t('components.embossing-footer.backButtonMenu') }}
    </button>
    <button v-if="!productName?.toLowerCase()?.includes('s-cover') && !(workflowModel.active == 1)" type="button" class="btn btn-secondary" :class="workflowModel.isPrevStepPossible() ? '' : 'disabled'"
            @click="workflowModel.changeActiveStep(-1)">
      {{ '< ' + $t('components.embossing-footer.backButton') }}
    </button>
    <el-select v-if="!productName?.toLowerCase()?.includes('s-cover')" v-model="workflowModel.active" class="btn btn-secondary" v-show="!workflowModel.isLastStep()">
      <el-option
        v-for="step in Object.values(workflowModel.Progress).slice(0, 4)"
        :key="step"
        :value="Object.values(workflowModel.Progress).indexOf(step)"
        :label="$t(`enum.${workflowModel.localeIdentifier}.${step}`)"
      />
    </el-select>
    
    <button v-if="productName?.toLowerCase()?.includes('s-cover')" type="button" class="btn btn-secondary" :class="workflowModel.isPrevStepPossible() ? '' : 'disabled'"
            @click="handleBackButton">
      {{ '< ' + $t('components.embossing-footer.backButton') }}
    </button>
    <button v-if="!authentication.user?.email.toLowerCase().includes('teufel-international') && workflowModel.isLastStep() && (authentication.roles?.includes(Role.Reseller) || authentication.roles?.includes(Role.Supplier) && !authentication.domains.some(d => d.urlSuffix == 'wjtortho'))" 
      type="button"
      class="btn btn-secondary"
      @click="createPartListPdf()"
      v-loading="loadingPartListPdf"
      element-loading-background="rgba(122, 122, 122, 0)"
      :disabled="loadingPartListPdf"
    >
      {{ $t('components.order-summary.downloadPartListPdfButton') }}
    </button>
    <a v-show="false" id="pdf-part-list" target="_blank" download="part-list.pdf"></a>

    <button v-if="workflowModel.isLastStep() && authentication.user !== null"
      type="button"
      class="btn btn-secondary"   
      @click="$emit('confirmOfferPdf')"
      v-loading="loadingOfferPdf"
      element-loading-background="rgba(122, 122, 122, 0)"
      :disabled="loadingOfferPdf"
    >
      {{ $t('components.order-summary.downloadOfferPdfButton') }}
    </button>
    <a v-show="false" id="pdf-offer" target="_blank" download="offer.pdf"></a>

    <button v-if="workflowModel.IsForm" 
            type="submit"
            native-type="submit"
            form="order-form"
            class="btn btn-primary"
            :class="workflowModel.isNextStepPossible() ? '' : 'disabled'">
      {{ 
         $t('components.embossing-footer.continueButtonForm') + ' >'
      }}
    </button>

    <button v-if="!workflowModel.isLastStep() && !workflowModel.IsForm"
            type="button"
            class="btn btn-primary"
            :class="workflowModel.isNextStepPossible() && supplier != 'demo' ? '' : 'disabled btn-secondary'"
            @click="workflowModel.OnClickNextAction(1)">
      {{ 
       $t('components.embossing-footer.continueButton') + ' >'
      }}    
    </button>
    
    <button v-if="workflowModel.isLastStep() && (authentication.user !== null || !productName.toLocaleLowerCase().includes('cover'))"
            type="button"
            class="btn btn-primary"
            :class="workflowModel.isNextStepPossible() && supplier != 'demo' ? '' : 'disabled btn-secondary'"
            @click="workflowModel.OnClickNextAction(1)">
      {{ 
        $t('components.order-summary.orderButton')
      }}    
    </button>
  </div>

</template>

<style scoped lang="scss">

.el-input, .el-select {
  --el-bg-color-overlay: rgba(173, 173, 173);
  --el-text-color-regular: white;
  --el-fill-color-blank: rgba(0, 0, 0, 0);

  --el-border-color: none;
  --el-border-color-hover: none;
  --el-text-color-regular: white;

  --el-select-input-focus-border-color: none !important;

  width: 12.2rem !important;
}

.el-option {
  width: 7.3rem;
}

</style>
