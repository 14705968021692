export enum CoverModel {
  uFlexCover = 'uFlexCover',
  xCover = 'xCover',
  cFlexCover = 'cFlexCover',
}

export enum ProthesisType {
  thigh = 'thigh',
  lowerLeg = 'lowerLeg',
}

export enum BodySide {
  right = 'Right',
  left = 'Left',
}

export enum ZipType {
  yes = 'yes',
  no = 'no'
}

export enum BalconyType {
  straight = 'straight',
  lLeft = 'lLeft',
  lRight = 'lRight',
  uForm = 'uForm'
}

export enum MountingType {
  bottom = 'bottom',
  front = 'front'
}

export enum PaneelType {
  landscape = 'landscape',
  portrait = 'portrait'
}
export enum OrientationType {
  south = 'south',
  eastOrWest = 'eastOrWest'
}

export enum FenceType {
  straight = 'straight',
  lLeft = 'lLeft',
  lRight = 'lRight',
  uForm = 'uForm',
  special = 'special'
}

/**
 * Used for identification in product parameter modal.
 */
export enum LinearLightNameType {
  buildIn = 'Einbau',
  hanging = 'Abgehängt',
  surfaceMounted = 'Aufbau',
}

export enum ProfileColor {
  white = 'white',
  black = 'black'
}

export enum LightType {
  concretePaving = 'concretePaving',
  masonry = 'masonry',
  drywall = 'drywall',
  thermalInsulation = 'thermalInsulation',
  metalFacade = 'metalFacade'
}

export enum ConcreteType {
  castInPlaceConcrete = 'castInPlaceConcrete',
  prefabricatedCeiling = 'prefabricatedCeiling',
}

export enum InsulationType {
  vwsProfile = 'vwsProfile',
  vwsMoldedPart = 'vwsMoldedPart',
}

export enum ConcreteFinishingType {
  smoothed = 'smoothed',
  exposed = 'exposed',
  plaster = "plaster"
}

export enum TransformerType {
  buildIn = 'buildIn',
  external = 'external'
}

export enum TransformerConfigurationType {
  onOff = 'onOff',
  dali = 'dali',
  triacDimming = 'triacDimming',
  daliSwitch = 'daliSwitch'
}

export enum TransformerIntensityType {
  fifteen = 'fifteen',
  thirty = 'thirty',
  sixty = 'sixty',
  onehundred = 'onehundred',
  onehundredFifty = 'onehundredFifty'
}

export enum MountingProfileType {
  lltwentyfive = "lltwentyfive",
  llthirtyfiveF = "llthirtyfiveF",
  lltwentyfivefifteen = "lltwentyfivefifteen"
}

export enum LightCoverType {
  versionOne = "versionOne",
  versionThree = "versionThree",
  versonFive = "versonFive"
}

export enum IPRatingType {
  iPtwenty = "iPtwenty",
  iPfiftyfour = "iPfiftyfour"
}

export enum LightColorType {
  rgb = "rgb",
  threethousandK = "threethousandK",
  fourthousandK = "fourthousandK"
}

export enum LightIntensityType {
  six = "six",
  ten = "ten",
  fifteen = "fifteen",
  nineteen = "nineteen",
  twentytwo = "twentytwo"
}