<template>
  <div
    v-if="editorMode.isBoneMode"
    class="level-item action"
    v-on:click="toggleIncludeChildren"
    :class="{ active: includeChildren }"
  >
    <font-awesome-icon icon="object-group" />
  </div>
  <div
    v-if="editorMode.isBoneMode"
    class="level-item action"
    v-on:click="setBoneTool(BoneTool.transform)"
    :class="{ active: boneTool === BoneTool.transform }"
  >
    <font-awesome-icon icon="arrows-up-down-left-right" />
  </div>
  <div
    v-if="editorMode.isBoneMode"
    class="level-item action"
    v-on:click="setBoneTool(BoneTool.intersect)"
    :class="{ active: boneTool === BoneTool.intersect }"
  >
    <font-awesome-icon icon="location-crosshairs" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { EditorMode } from '@/types/ui/EditorMode';
import * as THREE from 'three';
import { BoneTool } from '@/types/enum/editor';
import * as THREEEnum from '@/types/enum/three';
import * as THREEService from '@/utils/three/initDefault';
import * as THREEMaterial from '@/utils/three/material';
import { LayoutColor } from '@/types/enum/color';
import * as THREEBone from '@/utils/three/bone';
import * as THREEBoundingBox from '@/utils/three/boundingBox';
import {
  TransformExtension,
  TransformValueType,
} from '@/components/Tools/TransformTools.vue';
import { MeshTreeData } from '@/types/ui/MeshTreeData';
import { RendererPublicInterface } from 'troisjs';
import { SelectionList } from '@/types/ui/SelectionList';
import { ViewCtrl } from '@/types/ui/ViewCtrl';
import TransformTools from '@/components/Tools/TransformTools.vue';
import { HistoryList, HistoryOperationType } from '@/types/ui/HistoryList';

@Options({
  components: {},
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class BoneTools extends Vue implements TransformExtension {
  //#region properties
  @Prop() modelValue!: MeshTreeData;
  @Prop() troisRenderer!: RendererPublicInterface;
  @Prop() editorMode!: EditorMode;
  @Prop() selectionList!: SelectionList;
  @Prop() viewCtrl!: ViewCtrl;
  @Prop() transformTools!: TransformTools;
  @Prop({ default: false }) activateBones!: boolean;
  @Prop() historyList!: HistoryList;

  //bones
  displayBones = false;
  skeletonHelper!: THREE.SkeletonHelper;
  boneContainer!: THREE.Group;
  boneTool: BoneTool = BoneTool.intersect;
  includeChildren = false;

  // enums for use in template section
  BoneTool = BoneTool;
  //#endregion properties

  //#region init
  mounted(): void {
    this.initContainer();
  }

  initContainer(): void {
    this.boneContainer = new THREE.Group();
    this.boneContainer.name = THREEEnum.SpecialObjectName.Skeleton;
  }

  @Watch('transformTools', { immediate: true })
  transformToolChanged(): void {
    if (this.transformTools) {
      this.transformTools.addExtension(this);
    }
  }

  @Watch('activateBones')
  async onActivateBonesChanged(): Promise<void> {
    this.displayBones = this.activateBones;
    if (this.boneContainer && this.boneContainer.children.length === 0)
      this.updateBones();
  }
  //#endregion init

  updateBones(): void {
    if (!this.boneContainer) this.initContainer();

    if (this.skeletonHelper) {
      this.skeletonHelper.parent?.remove(this.skeletonHelper);
    }
    if (this.boneContainer) {
      this.boneContainer.clear();
    }

    const scene = this.troisRenderer.scene;
    if (this.displayBones && scene) {
      const selection = this.modelValue.getSelection();
      if (selection.length > 0) {
        selection[0].createSkeleton();
        const skeleton = selection[0].skeleton;
        if (skeleton && skeleton.bones.length > 0) {
          skeleton.boneTextureSize = 50;
          this.skeletonHelper = new THREE.SkeletonHelper(skeleton.bones[0]);
          this.skeletonHelper.name = THREEEnum.SpecialObjectName.Skeleton;
          scene.add(this.skeletonHelper);

          if (this.boneContainer) {
            const mesh = this.selectionList.getSelectedObject();
            if (mesh && !mesh.children.includes(this.boneContainer)) {
              this.selectionList.getSelectedObject()?.add(this.boneContainer);
            }
            this.boneContainer.clear();
            if (this.editorMode.isBoneMode) {
              for (
                let boneIndex = 0;
                boneIndex < selection[0].bones.length;
                boneIndex++
              ) {
                const bone = selection[0].bones[boneIndex];
                const boneObject = THREEService.initSphere(
                  THREEEnum.SpecialObjectName.Bone,
                  'gray',
                  bone.position
                );
                this.boneContainer.add(boneObject);
                this.viewCtrl.adaptSize(boneObject, 0.3);

                if (
                  this.editorMode.isBoneMode &&
                  boneIndex === selection[0].activeBoneIndex
                ) {
                  THREEMaterial.setColor(boneObject, LayoutColor.primary);
                  if (this.boneTool === BoneTool.transform) {
                    this.transformTools.transformControl.mode = 'translate';
                    this.transformTools.transformControl.attach(boneObject);
                    if (this.troisRenderer && this.troisRenderer.scene)
                      this.troisRenderer.scene.add(
                        this.transformTools.transformControl
                      );
                  } else {
                    if (this.troisRenderer && this.troisRenderer.scene)
                      this.troisRenderer.scene.remove(
                        this.transformTools.transformControl
                      );
                  }
                }
              }
            }
            this.boneContainer.add(skeleton.bones[0]);
          }
        }
      }
    }
  }

  getTransformTarget(): THREE.Group | THREE.Object3D | undefined {
    if (this.editorMode.isBoneMode) {
      const bone = this.getActiveContainerBone();
      if (bone) return bone;
    }
  }

  getActiveBonePosition(): THREE.Vector3 | null {
    if (this.boneContainer)
      return THREEBone.getActiveBonePosition(
        this.modelValue,
        this.boneContainer
      );
    return null;
  }

  getActiveContainerBone(): THREE.Object3D | null {
    if (this.boneContainer)
      return THREEBone.getActiveContainerBone(
        this.modelValue,
        this.boneContainer
      );
    return null;
  }

  getActiveBoneIndex(): number {
    return THREEBone.getActiveBoneIndex(this.modelValue);
  }

  setActiveBoneIndex(index: number): void {
    THREEBone.setActiveBoneIndex(this.modelValue, index);
  }

  getContainerBonePosition(index: number): THREE.Vector3 | null {
    if (this.boneContainer)
      return THREEBone.getContainerBonePosition(index, this.boneContainer);
    return null;
  }

  toggleBones(): void {
    this.displayBones = !this.displayBones;
    this.updateBones();
  }

  toggleIncludeChildren(): void {
    this.includeChildren = !this.includeChildren;
  }

  setDisplayBones(value: boolean): void {
    this.displayBones = value;
  }

  setBoneTool(value: BoneTool): void {
    this.boneTool = value;
    this.updateBones();
  }

  updateBonePosition(event: MouseEvent): void {
    if (this.boneTool === BoneTool.intersect) {
      const point = this.selectionList.checkIntersection(event, true);
      const containerBone = this.getActiveContainerBone();
      const boneIndex = this.getActiveBoneIndex();
      if (containerBone && boneIndex > -1 && point) {
        const pivotBoneActive = THREEBone.pivotBoneActive(this.modelValue);
        const oldBonePosition = !pivotBoneActive
          ? containerBone.position.clone()
          : point.clone().multiplyScalar(-1);
        this.undoRedoBonePosition(boneIndex, point);
        const meshId = this.selectionList.getSelectedObjectUuid();
        if (meshId) {
          this.historyList.add(
            meshId,
            HistoryOperationType.bonePosition,
            async () =>
              this.undoRedoBonePosition(boneIndex, oldBonePosition.clone()),
            async () => this.undoRedoBonePosition(boneIndex, point.clone())
          );
        }
      }
    }
  }

  undoRedoBonePosition(boneIndex: number, point: THREE.Vector3): void {
    const oldBoneIndex = this.getActiveBoneIndex();
    this.setActiveBoneIndex(boneIndex);
    const bonePosition = this.getContainerBonePosition(boneIndex);
    if (bonePosition) {
      bonePosition.copy(point);
      this.transformTools.transformChanged(false);
      this.selectionList.getSelectedObject()?.position.set(0, 0, 0);
      this.viewCtrl.fitControlCameraToScene();
    }
    this.setActiveBoneIndex(oldBoneIndex);
  }

  bonesToModify: THREE.Bone[] = [];
  applyChangesBeforeUpdate(): void {
    const mesh = this.selectionList.getSelectedObject();
    if (!mesh) return;

    const pivotBoneActive = THREEBone.pivotBoneActive(this.modelValue);
    const selection = this.modelValue.getSelection();
    this.bonesToModify = [];

    if (this.editorMode.isBoneMode) {
      const activeBone = THREEBone.getActiveBone(this.modelValue);
      const activeBonePosition = this.getActiveBonePosition();
      if (pivotBoneActive && activeBonePosition) {
        this.transformTools.pivot.position.copy(activeBonePosition.clone());
      }

      if (activeBone) {
        this.bonesToModify = [activeBone];
        if (
          (this.includeChildren && !pivotBoneActive) ||
          (!this.includeChildren && pivotBoneActive)
        )
          this.bonesToModify = THREEBone.getActiveChildBones(this.modelValue);

        if (selection.length > 0) {
          const initBonePositions = selection[0].bones.map((bone) =>
            bone.position.clone()
          );

          if (
            THREEBoundingBox.allOutsideBoundingBox([mesh], initBonePositions)
          ) {
            if (!pivotBoneActive) {
              this.bonesToModify = selection[0].bones;
            } else this.bonesToModify = [activeBone];
          }
        }
      }
    }
  }

  applyChangesAfterUpdate(
    saveHistory: boolean,
    newValue: TransformValueType,
    oldValue: TransformValueType
  ): void {
    const mesh = this.selectionList.getSelectedObject();
    if (!mesh) return;

    const pivotBoneActive = THREEBone.pivotBoneActive(this.modelValue);

    if (this.editorMode.isBoneMode) {
      const currentPosition = this.getActiveBonePosition();
      const activeBonePosition = pivotBoneActive
        ? currentPosition?.negate()
        : currentPosition;
      const activeBone = THREEBone.getActiveBone(this.modelValue);
      if (activeBonePosition && activeBone) {
        const difference = activeBonePosition.clone().sub(activeBone.position);
        for (const child of this.bonesToModify) {
          child.position.add(difference);
        }
        if (pivotBoneActive) activeBone.position.set(0, 0, 0);

        this.updateBones();
      }
    }

    this.updateBones();
    if (saveHistory && this.editorMode.isBoneMode) {
      const boneIndex = this.getActiveBoneIndex();
      if (boneIndex > -1) {
        const pivotBoneActive = THREEBone.pivotBoneActive(this.modelValue);
        const newBonePosition = new THREE.Vector3(
          newValue.x,
          newValue.y,
          newValue.z
        );
        const oldBonePosition = !pivotBoneActive
          ? new THREE.Vector3(oldValue.x, oldValue.y, oldValue.z)
          : newBonePosition.clone().multiplyScalar(-1);
        const meshId = (mesh as any).apiData.uuid;
        this.historyList.add(
          meshId,
          HistoryOperationType.bonePosition,
          async () =>
            this.undoRedoBonePosition(boneIndex, oldBonePosition.clone()),
          async () =>
            this.undoRedoBonePosition(boneIndex, newBonePosition.clone())
        );
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.active {
  color: var(--el-color-primary);
}
</style>
