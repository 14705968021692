<template>
  <div v-if="!isEditor" id="footer" class="row align-items-center">
    <!-- Company info -->
    <p class="col mb-0 ms-5 text-start">{{ $t('components.custom-footer.companyInfo') }}</p>


    <!-- Privacy/copyright links -->
    <div class="col me-5 text-end">
      <a class="link-underline link-underline-opacity-0 text-white" :href="privacyUri">{{ $t('components.custom-footer.privacy') }}</a> | <a class="link-underline link-underline-opacity-0 text-white" :href="copyrightUri">{{ $t('components.custom-footer.copyright') }}</a>

    </div>
    <p class="ms-5 text-start">({{ version }})</p>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { version } from "../../package.json";

export default class CustomFooter extends Vue {
  @Prop({ default: false }) isEditor!: boolean;

  privacyUri = "";
  copyrightUri = "";
  version = version;
}
</script>


<style scoped lang="scss">
#footer {
  color: white;
  height: auto;
  background: var(--color-footer);
  font-weight: normal;
}
</style>
