<script setup lang="ts">
interface Props {
  title: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
}

const props = defineProps<Props>();
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">{{ props.title }}</h5>
      <div class="d-flex justify-content-center m-2">
        <slot></slot>
      </div>
      <div v-if="props.secondaryButtonText" class="row m-2">
        <button type="button" class="btn btn-secondary" @click="$emit('secondaryButtonClicked')">{{
            props.secondaryButtonText
          }}</button>
      </div>
      <div class="row m-2 mb-0">
        <button type="button" class="btn btn-primary" @click="$emit('primaryButtonClicked')">{{ props.primaryButtonText }}</button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
